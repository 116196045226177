import React from "react"
import { useSearchParams } from "react-router-dom"
import { routes } from "router"
import Form from "components/clients/ActionForm"

// Redux
import OffcanvasWindow from "modules/offcanvas/OffcanvasWindow"

export default function ActionWindow({ type }) {
  const [searchParams] = useSearchParams()

  return (
    <OffcanvasWindow
      container={document.getElementById("clients")}
      loadingName="clients.client"
      exitRedirect={routes.guideClientsPath({}, searchParams)}
    >
      {({ setChanged, closeHandler }) => <Form closeHandler={closeHandler} onChanged={setChanged} type={type} />}
    </OffcanvasWindow>
  )
}
