import React, { memo } from "react"
import { Nav, NavItem, NavLink } from "reactstrap"
import { NavLink as ReactNavLink } from "react-router-dom"
import { routes } from "router"

import { useTranslation } from "react-i18next"

function Navigation() {
  const { t } = useTranslation()

  return (
    <Nav className="mx-n15 mt-40 mb-30">
      <NavItem>
        <NavLink tag={ReactNavLink} to={routes.clientBookingsPath()} end>
          {t("bookings.title_current")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={ReactNavLink} to={routes.clientBookingsPastTripsPath()} end>
          {t("bookings.title_past")}
        </NavLink>
      </NavItem>
    </Nav>
  )
}

export default memo(Navigation)
