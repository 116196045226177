import React, { memo } from "react"
import { TabPane } from "reactstrap"
import DateTabs from "components/weather/DateTabs"
import Chart from "components/weather/Chart"

import moment from "moment"

import { useSelector } from "react-redux"
import { dateHourlyForecastSelector, forecastDateSelector } from "store/weather"

function UVIndex({ className, params, onChangeFilters }) {
  const forecastDate = useSelector(forecastDateSelector)
  const hourlyForecast = useSelector(dateHourlyForecastSelector())
  if (!hourlyForecast) return null

  const currentTime = moment().format("HH:[00]")
  const currentTimePlusOne = moment().add(1, "hours").format("HH:[00]")
  const currentTimeData = hourlyForecast[currentTime] || hourlyForecast[currentTimePlusOne]

  const today = moment()
  const dateMoment = moment(forecastDate || today)
  const isToday = today.isSame(dateMoment, "day")
  const isTomorrow = today.clone().add(1, "day").isSame(dateMoment, "day")
  const dateFormatted = dateMoment.format(`${(isToday && "[Today], ") || (isTomorrow && "[Tomorrow], ") || ""}MMM DD, YYYY`)

  const classes = ["bg-white rounded px-40 py-30 position-relative overflow-hidden"]
  if (className) classes.push(className)

  return (
    <TabPane tabId="uv_index" className={classes.join(" ")}>
      <div className="vstack gap-30">
        <DateTabs params={params} onChangeFilters={onChangeFilters} />
        <div className="mt-10">
          <p className="text-dark text-opacity-50 mb-1">{dateFormatted}</p>
          <p className="fs-3 m-0">
            {currentTimeData ? `${currentTimeData.relativeHumidity} - ${currentTimeData.uvDescription.toLowerCase()}` : "—"}
          </p>
        </div>
        <Chart type="bar" hourlyData={hourlyForecast} color="240, 149, 64" yAxisKey="value.uvIndex" />
      </div>
    </TabPane>
  )
}

export default memo(UVIndex)
