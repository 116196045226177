import React, { memo, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import { Button } from "reactstrap"

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement } from "chart.js"
import { Line } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"

import { useTranslation } from "react-i18next"
import { convertToMetric } from "helpers/string"

import { useSelector } from "react-redux"
import { dateHourlyForecastSelector } from "store/weather"

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ChartDataLabels)

const chartData = ({ labels, data }, isConvertToMetric) => ({
  labels,
  datasets: [
    {
      data,
      borderWidth: 2,
      borderColor: "#0052B4",
      backgroundColor: "white",
      pointRadius: 6,
      hoverRadius: 8,
      tension: 0.4,
      datalabels: {
        labels: {
          speed: {
            align: "end",
            clasmp: true,
            offset: 30,
            color: "#141414",
            font: {
              weight: 500,
              size: 22
            },
            textAlign: "end",
            formatter: ({ value }) => convertToMetric(value.windSpeed, "mile-per-hour", true, 1, isConvertToMetric)
          },
          direction: {
            align: "end",
            anchor: "start",
            offset: 15,
            color: "#B7B7B7",
            font: {
              weight: 500,
              size: 16
            },
            formatter: ({ value }) => value.windDirectionCardinal
          }
        }
      }
    }
  ]
})

export const chartOptions = {
  devicePixelRatio: 2,
  responsive: true,
  maintainAspectRatio: false,
  layout: { padding: { left: 50, right: 50, top: 70 } },
  plugins: {
    legend: false,
    tooltip: { enabled: false }
  },
  interaction: { mode: "index", intersect: false },
  parsing: { yAxisKey: "value.windSpeed" },
  scales: {
    x: {
      grid: { display: false, drawBorder: false },
      ticks: {
        autoSkip: false,
        maxRotation: 0,
        color: "#B7B7B7",
        font: { size: 15 }
      }
    },
    yAxes: { display: false }
  }
}

function Wind({ className, onNextDays }) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const isConvertToMetric = searchParams.get("units") === "metric"

  const hourlyForecast = useSelector(dateHourlyForecastSelector())

  const rawData = useMemo(
    () =>
      Object.entries(hourlyForecast || {})?.reduce(
        (data, [hour, value]) => {
          data.data.push({ x: hour, value })
          return data
        },
        { labels: [], data: [], backgroundColor: [], borderColor: [] }
      ),
    [hourlyForecast]
  )

  const data = useMemo(() => chartData(rawData, isConvertToMetric), [rawData, isConvertToMetric])

  if (!hourlyForecast) return null
  const hoursCount = Object.keys(hourlyForecast).length

  const classes = ["bg-white rounded overflow-x-scroll"]
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")}>
      <div className="hstack justify-content-between px-25 pt-25 sticky-start z-0">
        <h3>{t("weather.wind")}</h3>
        <Button value="wind" onClick={onNextDays} color="primary-second" className="py-2 px-20">
          {t("weather.next_days")}
        </Button>
      </div>
      <div className="px-25 pb-25">
        <div style={{ height: 240, minWidth: hoursCount * 120 }} className="w-100">
          <Line data={data} options={chartOptions} plugins={[ChartDataLabels]} />
        </div>
      </div>
    </div>
  )
}
export default memo(Wind)
