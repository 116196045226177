import React, { useEffect } from "react"
import { useMatch } from "react-router-dom"
import { routes } from "router"
import OffcanvasWindow from "modules/offcanvas/OffcanvasWindow"
import Form from "components/boats/Form"

// Redux
import { useDispatch } from "react-redux"
import { cleanBoat, getGuideBoat } from "store/boats"

export default function BoatWindow() {
  const dispatch = useDispatch()
  const isPersisted = useMatch(routes.guideEditBoatPath())
  const boatId = isPersisted?.params?.id

  const isTripWizard = useMatch(routes.guideTripWizardStep3AddNewBoatPath())
  const exitRedirect = !!isTripWizard ? routes.guideTripWizardStep3Path(isTripWizard.params) : routes.guideBoatsPath()

  useEffect(() => {
    if (boatId) dispatch(getGuideBoat(boatId))
    return () => dispatch(cleanBoat())
  }, [boatId, dispatch])

  return (
    <OffcanvasWindow container={document.getElementById("boats")} loadingName="boats.boat" exitRedirect={exitRedirect}>
      {({ setChanged, closeHandler }) => <Form closeHandler={closeHandler} onChanged={setChanged} />}
    </OffcanvasWindow>
  )
}
