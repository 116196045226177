import React, { useCallback, useEffect, useMemo, useState } from "react"
import { routes } from "router"
import { useNavigate } from "react-router-dom"
import { Button, Label } from "reactstrap"
import ActionButtons from "components/wizards/ActionButtons"
import Icon from "components/common/Icon"
import Spinner from "components/common/Spinner"
import GooglePlacesInput from "modules/google/GooglePlacesInput"
import CurrentLocationButton from "components/map/CurrentLocationButton"
import ZoomButtons from "components/map/ZoomButtons"
import Input from "components/form/Input"

import { serialize } from "object-to-formdata"
import { useWizardSteps } from "hooks/wizard"
import { isEqual, debounce } from "lodash"
import { getLocalStorageBrowserLocation } from "helpers/location"
import { useTranslation } from "react-i18next"
import useGeocoder from "modules/google/hooks/useGeocoder"
import useForm from "hooks/useForm"

import { useDispatch, useSelector } from "react-redux"
import { tripLatLngSelector, updateTrip } from "store/trips"
import { modelSelector } from "store/selectors"

import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api"

import { API_OPTIONS, DEFAULT_ZOOM, MAP_OPTIONS, MARKER_ICON } from "constants/map"

const Step4Form = () => {
  const { isLoaded } = useJsApiLoader(API_OPTIONS)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const updateStep = useWizardSteps({ name: "trips.trip" })
  const geocoder = useGeocoder(null)

  const trip = useSelector(modelSelector("trip"))
  const tripLatLng = useSelector(tripLatLngSelector)

  const [form, changeHandler, submitHandler, submitCallback, , formIsChanged] = useForm(trip, ["address", "additional_directions"])

  const browserLocation = useMemo(() => getLocalStorageBrowserLocation(), [])
  const [map, setMap] = useState(null)
  const [mapCenter, setMapCenter] = useState(map?.center)
  const [centerLatLng, setCenterLatLng] = useState(tripLatLng || browserLocation)
  const zoom = map?.zoom || DEFAULT_ZOOM

  const mapCenterLatLng = useMemo(() => mapCenter && { lat: mapCenter.lat(), lng: mapCenter.lng() }, [mapCenter])

  const isChanged = useMemo(() => !isEqual(tripLatLng, mapCenterLatLng) || formIsChanged, [tripLatLng, mapCenterLatLng, formIsChanged])

  // eslint-disable-next-line
  const debounceCoordinatesToAddress = useCallback(
    debounce(
      (location) => geocoder({ location }, (place) => changeHandler({ target: { name: "address", value: place.formatted_address } })),
      200
    ),
    []
  )
  const centerChangedHandler = useCallback(() => {
    if (map?.center) debounceCoordinatesToAddress(map?.center)
    setMapCenter(map?.center)
  }, [map]) // eslint-disable-line

  const placeChangedHandler = useCallback(({ target: { coordinates } }) => {
    if (coordinates) debounceCoordinatesToAddress(coordinates)
    setCenterLatLng(coordinates)
  }, []) // eslint-disable-line

  const resetLocation = () => debounceCoordinatesToAddress(trip.address) || setCenterLatLng({ ...tripLatLng })

  const onLoad = useCallback((map) => setMap(map) || setMapCenter(map?.center), [])
  const onUnmount = useCallback(() => setMap(null), [])

  submitCallback(({ nativeEvent }) => {
    const data = updateStep({ latitude: mapCenterLatLng.lat, longitude: mapCenterLatLng.lng, ...form })
    const formData = serialize({ trip: data }, { indices: true })
    const nextPath = nativeEvent?.submitter?.value
    dispatch(updateTrip(trip.id, formData)).then(() => navigate(nextPath))
  })

  useEffect(() => {
    return onUnmount
  }, [onUnmount])

  return (
    <form onSubmit={submitHandler}>
      <div className="fs-6 mb-10">{form.address}</div>
      {isLoaded ? (
        <GoogleMap
          mapContainerClassName="rounded"
          mapContainerStyle={{ height: 330 }}
          options={{ ...MAP_OPTIONS, draggable: false }}
          center={centerLatLng}
          zoom={zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onCenterChanged={centerChangedHandler}
        >
          {mapCenterLatLng && (
            <Marker position={mapCenterLatLng} icon={{ ...MARKER_ICON, scaledSize: new window.google.maps.Size(30, 30) }} />
          )}
          <div className="vstack position-absolute p-10 w-100 h-100 gap-10 pointer-events-none">
            <div className="hstack gap-10">
              <div className="pointer-events-auto position-relative shadow flex-fill">
                <div className="px-2 position-absolute top-50 start-0 translate-middle-y pointer-events-none">
                  <Icon iconName="Search" size={18} className="text-gray-light" />
                </div>
                <GooglePlacesInput
                  placeholder="Search Location"
                  bsSize="sm"
                  className="py-2 ps-30"
                  onChange={placeChangedHandler}
                  dropDownMenuClassName="mt-n1"
                  resetIfChanged
                />
              </div>
              <div className="pointer-events-auto hstack gap-2 ms-auto">
                <CurrentLocationButton />
                {isChanged && tripLatLng && (
                  <Button color="white" onClick={resetLocation} className="btn-sm p-2 fs-7 shadow">
                    <Icon iconName="Update" size={16} />
                  </Button>
                )}
              </div>
            </div>
            <ZoomButtons />
          </div>
        </GoogleMap>
      ) : (
        <div className="hstack bg-white p-50 rounded" style={{ height: 330 }}>
          <Spinner className="m-auto" />
        </div>
      )}
      <div className="mt-20">
        <Label for="additional_directions" className="fs-6">
          {t("trip.labels.additional_directions")}
        </Label>
        <Input
          id="additional_directions"
          type="textarea"
          name="additional_directions"
          placeholder={t("trip.labels.additional_directions")}
          value={form.additional_directions || ""}
          onChange={changeHandler}
          className="fs-6"
          rows={5}
          withError
        />
      </div>
      <ActionButtons
        isChanged={isChanged}
        action={updateTrip}
        name="trips.trip"
        className="mt-50"
        showSaveAndExit={trip.wizard_completed}
        exitPath={routes.guideTripsPath()}
        nextText="Save & Next"
        saveText="Save & Exit"
      />
    </form>
  )
}

export default Step4Form
