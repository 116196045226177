import React from "react"
import { Link, Outlet, useMatches } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import { routes } from "router"
import withDefaultErrorBoundary from "modules/errors/HOCs/withDefaultErrorBoundary"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"
import Logo from "components/common/Logo"

import signInImage from "assets/images/devise/sign_in.jpg"
import signUpImage from "assets/images/devise/sign_up.jpg"
import resetPasswordImage from "assets/images/devise/reset_password.jpg"
import errorImage from "assets/images/devise/404.jpg"

function DeviseLayout({ children }) {
  const matches = useMatches()
  const currentMatch = matches.at(-1)
  const IMAGES = {
    [routes.signInPath()]: signInImage,
    [routes.signUpPath()]: signUpImage,
    [routes.signUpRequestSentPath()]: signUpImage,
    [routes.forgotPasswordPath()]: resetPasswordImage,
    [routes.resetPasswordRequestSentPath()]: resetPasswordImage,
    [routes.resetPasswordPath()]: resetPasswordImage,
    [routes.resetPasswordSuccessPath()]: resetPasswordImage
  }
  const image = IMAGES[currentMatch.pathname] || errorImage

  return (
    <Container>
      <Row className="min-vh-100">
        <Col xs={12} md={6} lg={5}>
          <div className="vstack h-100">
            <div className="sticky-top pt-25 pb-2 bg-white hstack">
              <Link
                className="d-block rounded-1 text-dark bg-gray-light-hover bg-opacity-25-hover p-2 m-n2"
                to={routes.rootPath()}
                title="Home"
              >
                <Logo className="" alt="Logo" height={44} wide />
              </Link>
            </div>
            <div className="py-30 pe-md-60 my-auto">
              <DefaultErrorBoundary>
                <Outlet />
                {children}
              </DefaultErrorBoundary>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6} lg={7} className="d-none d-md-block">
          {image ? (
            <div className="position-fixed top-0 vh-100" style={{ marginRight: "calc((100% - 100vw) / 2)" }}>
              <img src={image} alt="" className="min-h-100 w-100 object-fit-cover" />
            </div>
          ) : null}
        </Col>
      </Row>
    </Container>
  )
}

export default withDefaultErrorBoundary(DeviseLayout)
