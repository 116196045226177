import React, { memo, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import { Button } from "reactstrap"

import { Chart as ChartJS, TimeScale, PointElement, LineElement } from "chart.js"
import { Line } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"
import "chartjs-adapter-date-fns"

import { useTranslation } from "react-i18next"
import { convertToMetric } from "helpers/string"

import { useSelector } from "react-redux"
import { dateHourlyTideForecastSelector } from "store/weather"

import { TIDE_TYPES } from "constants/weather"

ChartJS.register(TimeScale, PointElement, LineElement, ChartDataLabels)

const chartData = ({ labels, data }, isConvertToMetric) => ({
  labels,
  datasets: [
    {
      data,
      borderWidth: 4,
      borderColor: "#6F9BFF",
      backgroundColor: "white",
      pointRadius: 6,
      hoverRadius: 8,
      tension: 0.4,
      datalabels: {
        labels: {
          speed: {
            align: "end",
            clasmp: true,
            offset: 30,
            color: "#141414",
            font: {
              weight: 500,
              size: 20
            },
            textAlign: "end",
            formatter: ({ value }) => convertToMetric(value.tideHeight, "foot", true, 1, isConvertToMetric)
          },
          direction: {
            align: "end",
            anchor: "start",
            offset: 20,
            color: "#B7B7B7",
            font: {
              weight: 400,
              size: 15
            },
            formatter: ({ value }) => TIDE_TYPES[value.tideType]
          }
        }
      }
    }
  ]
})

export const chartOptions = {
  devicePixelRatio: 2,
  responsive: true,
  maintainAspectRatio: false,
  layout: { padding: { left: 50, right: 50, top: 70 } },
  plugins: {
    legend: false,
    tooltip: { enabled: false }
  },
  interaction: { mode: "index", intersect: false },
  parsing: { yAxisKey: "value.tideHeight" },
  scales: {
    x: {
      type: "time",
      grid: { display: false, drawBorder: false },
      ticks: {
        autoSkip: false,
        maxRotation: 0,
        color: "#B7B7B7",
        font: { size: 15 },
        source: "data"
      },
      time: {
        displayFormats: {
          hour: "HH:mm"
        },
        parser: "HH:mm"
      }
    },
    yAxes: { display: false }
  }
}

function Tide({ className, onNextDays, onlyBody = false }) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const isConvertToMetric = searchParams.get("units") === "metric"

  const hourlyTideForecast = useSelector(dateHourlyTideForecastSelector())

  const rawData = useMemo(
    () =>
      Object.entries(hourlyTideForecast || {})
        .sort(([time1], [time2]) => (time1 < time2 ? -1 : 1))
        .reduce(
          (data, [hour, value], index, arr) => {
            if (!index) data.data.push({ x: "00:00", value: {} })
            data.data.push({ x: hour, value })
            if (index === arr.length - 1) data.data.push({ x: "23:59", value: {} })
            return data
          },
          { labels: [], data: [], backgroundColor: [], borderColor: [] }
        ),
    [hourlyTideForecast]
  )

  const data = useMemo(() => chartData(rawData, isConvertToMetric), [rawData, isConvertToMetric])

  if (!Object.keys(hourlyTideForecast || {}).length) return null
  const hoursCount = Object.keys(hourlyTideForecast).length

  const classes = []
  if (!onlyBody) classes.push("bg-white rounded p-25 overflow-hidden")
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")}>
      {!onlyBody && (
        <div className="hstack justify-content-between px-25 pt-25 sticky-start z-0">
          <h3>{t("weather.tide")}</h3>
          <Button value="tides" onClick={onNextDays} color="primary-second" className="py-2 px-20">
            {t("weather.next_days")}
          </Button>
        </div>
      )}
      <div style={{ height: 320, minWidth: hoursCount * 120 }} className="w-100">
        <Line data={data} options={chartOptions} plugins={[ChartDataLabels]} />
      </div>
    </div>
  )
}
export default memo(Tide)
