import React, { useRef } from "react"
import { mediaBlockRenderer } from "helpers/editor"
import { Editor, RichUtils, EditorState, AtomicBlockUtils } from "draft-js"
import "draft-js/dist/Draft.css"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { resetUnviewedMessagesCount } from "store/conversations"

export default function MessageEditor({ editorState, setEditorState, className, media, setMedia, placeholder = "" }) {
  const { t } = useTranslation()
  const prevEditorStateRef = useRef(EditorState.createEmpty());
  const editor = useRef(null)
  const dispatch = useDispatch()

  const focusEditor = () => {
    editor.current.focus()
  }

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)

    if (!newState) return
    setEditorState(newState)
  }

  const insertImage = (url) => {
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity("image", "IMMUTABLE", { src: url })
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const newEditorState = EditorState.set(
      editorState,
      {
        currentContent: contentStateWithEntity
      },
      "create-entity"
    )

    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ")
  }

  const handlePastedFiles = (files) => {
    if (media) return
    const imageUrl = URL.createObjectURL(files[0])
    setMedia(files[0])
    setEditorState(insertImage(imageUrl))
  }

  const handleChangeEditor =  (state) => {
    const previousContent = prevEditorStateRef.current.getCurrentContent();
    const previousText = previousContent.getPlainText();
    const newContent = state.getCurrentContent();
    const newText = newContent.getPlainText();

    if (previousText.length === 0 && newText.length > 0) {
      dispatch(resetUnviewedMessagesCount());
    }

    setEditorState(state);
    prevEditorStateRef.current = state;
  }

  return (
    <div className={className} onClick={focusEditor}>
      <Editor
        blockRendererFn={mediaBlockRenderer}
        ref={editor}
        editorState={editorState}
        onChange={handleChangeEditor}
        handleKeyCommand={handleKeyCommand}
        placeholder={placeholder || t("messenger.placeholder")}
        handlePastedFiles={handlePastedFiles}
      />
    </div>
  )
}
