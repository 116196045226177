import React, { memo } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { routes } from "router"
import Icon from "components/common/Icon"

const JournalNote = ({ journalNote, date, className, showOnHover = false }) => {
  const [searchParams] = useSearchParams()

  const classes = ["text-dark"]
  if (showOnHover) classes.push("show-on-hover-target")
  if (className) classes.push(className)

  searchParams.set("journal_date", date)

  return (
    <Link
      className={classes.join(" ")}
      to={
        journalNote?.id
          ? routes.guideDashboardJournalEditPath({ id: journalNote?.id }, searchParams)
          : routes.guideDashboardJournalNewPath({}, searchParams)
      }
    >
      {journalNote?.id ? <Icon iconName="EditNote" /> : <Icon iconName="AddNote" />}
    </Link>
  )
}
export default memo(JournalNote)
