import React from "react"
import { Link, useSearchParams } from "react-router-dom"
import { routes } from "router"
import Icon from "components/common/Icon"

import moment from "moment"
import { sumBy } from "lodash"
import { numberToCurrency } from "helpers/string"

import { CATEGORY_ICONS } from "constants/accounting"

function LinkWrapperToEditExpence({ record, children, className, color = "primary-second" }) {
  const [searchParams] = useSearchParams()
  if (record.class_name === "Expense") {
    return (
      <Link
        className={["text-dark text-decoration-none bg-opacity-0 bg-opacity-5-hover", `bg-${color}`, className].join(" ")}
        to={routes.guideAccountingEditExpancePath({ id: record.id }, searchParams)}
      >
        {children}
      </Link>
    )
  }
  return <div className={className}>{children}</div>
}

const getCategoryIcon = (slug) => {
  const icon = CATEGORY_ICONS[slug]
  return icon ?? "Income"
}

export default function DayDetails({ date, items = [] }) {
  const today = moment().startOf("day")
  const isToday = moment(date).diff(today, "days") === 0
  const dayLongFormat = isToday ? "[Today]" : "DD MMM"
  const headFormat = dayLongFormat

  const totalAmount = sumBy(items, "amount")

  return (
    <div className="bg-white rounded overflow-hidden">
      <div className="hstack justify-content-between gap-10 px-15 py-10 border-bottom border-gray-lightest">
        {date && (
          <p className={["fs-6 fw-medium m-0", isToday ? "text-primary-second" : ""].join(" ")}>{moment(date).format(headFormat)}</p>
        )}
        <p className="fs-6 fw-medium m-0">{numberToCurrency(totalAmount)}</p>
      </div>
      {items.length > 0 && (
        <div className="vstack">
          {items.map((record, index) => {
            const color = record.stripe ? "stripe" : "success"
            return (
              <LinkWrapperToEditExpence record={record} key={index} className="hstack gap-2 align-items-center fs-7 py-10 px-15">
                <div
                  className={[
                    "rounded-circle p-2 bg-opacity-5",
                    record.class_name === "Income"
                      ? record.amount >= 0
                        ? "bg-primary text-primary"
                        : "bg-danger text-danger"
                      : "bg-primary-second text-primary-second"
                  ].join(" ")}
                >
                  <Icon iconName={getCategoryIcon(record.slug)} size={30} />
                </div>
                <div>
                  <div className="fst-italic">{record.displayed_name}</div>
                  <div className="small text-dark text-opacity-50">{record.displayed_for}</div>
                </div>
                <div
                  className={`d-inline-block py-0 px-2 rounded fs-7 fw-semibold lh-base text-${color} bg-${color} bg-opacity-25 text-center ms-auto me-2`}
                >
                  {record.stripe ? "Card" : "Cash"}
                </div>
                <div className={record.amount < 0 ? "text-danger" : ""}>{numberToCurrency(record.amount)}</div>
              </LinkWrapperToEditExpence>
            )
          })}
        </div>
      )}
    </div>
  )
}
