import React from "react"
import Spinner from "components/common/Spinner"
import DynamicTag from "components/DynamicTag"

import { useSelector } from "react-redux"
import { containerLoadingSelector } from "./selectors"

export default function LoadingContainer({
  name = "default",
  tag = null,
  spinnerProps: { className: spinnerClassName, position = "absolute", ...spinnerRest } = {},
  className = "",
  children,
  ...rest
}) {
  const loading = useSelector(containerLoadingSelector(name, false))

  const classes = []
  if (!className.match(/position-/g)?.length) classes.push("position-relative")
  if (className) classes.push(className)

  const spinnerClasses = ["bg-white bg-opacity-10 backdrop-blur-3 w-100 h-100 transition z-2"]
  if (loading) spinnerClasses.push("opacity-100")
  else spinnerClasses.push("pointer-events-none opacity-0")
  if (spinnerClassName) spinnerClasses.push(spinnerClassName)

  return (
    <DynamicTag className={classes.join(" ")} {...rest} tag={tag}>
      <div className="z-1 position-relative">{children}</div>
      <Spinner className={spinnerClasses.join(" ")} absolute={position === "absolute"} fixed={position === "fixed"} {...spinnerRest} />
    </DynamicTag>
  )
}
