import React from "react"
import { useNavigate } from "react-router-dom"
import { routes } from "router"
import { DropdownToggle } from "reactstrap"
import { Dropdown, DropdownItem } from "components/common/Dropdown"
import Avatar from "components/common/Avatar"
import Icon from "components/common/Icon"
import Payment from "./form/Payment"

import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"
import { createConversation } from "store/conversations"

import { TYPE_OPTIONS_ENUM } from "components/bookings/constants"

const Preview = () => {
  const { t } = useTranslation()

  const { booking } = useSelector((store) => store.bookings)
  const { client, trip } = booking
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const contactHandler = (type) => {
    if (type === "message")
      dispatch(createConversation({ participant_id: client.id })).then((conversation) =>
        navigate(routes.guideMessagesPath({}, { conversation_id: conversation.id }))
      )
    else if (type === "email") navigate(routes.guideClientEmailActionPath({}, { "user_ids[]": [client.id] }))
    else if (type === "text") navigate(routes.guideClientTextActionPath({}, { "user_ids[]": [client.id] }))
  }

  if (!booking.id) return null
  return (
    <div className="vstack gap-20">
      <div className="bg-white rounded p-20">
        <div className="hstack align-items-center gap-20">
          <Avatar user={booking.client} size={57} />
          <div>
            <p className="fs-5 fw-medium lh-sm m-0">{trip?.title}</p>
            <p className="fs-5 lh-sm m-0 opacity-75">
              {booking.booked_datetime} @ {booking.booked_time}
            </p>
          </div>

          <div className="hstack gap-10 my-n1 ms-auto">
            <Dropdown
              className="my-n1"
              toggleButton={
                <DropdownToggle color="primary" className="fs-7">
                  {t("global.contact")}
                </DropdownToggle>
              }
            >
              {Object.entries(TYPE_OPTIONS_ENUM).map(([type, label], index) => (
                <DropdownItem key={index} onClick={() => contactHandler(type)}>
                  {label}
                </DropdownItem>
              ))}
            </Dropdown>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-3 mt-20">
          <h5 className="g-col-2">{t("booking.labels.details")}</h5>
          <p className="text-dark text-opacity-50 m-0">
            {client.first_name} {client.last_name}
          </p>
          <p className="text-dark text-opacity-50 m-0">
            {t("booking.labels.adult_count", { count: `${booking.adult_count} ` })},{" "}
            {t("booking.labels.child_count_12", { count: `${booking.child_count} ` })}
          </p>
          {client.phone_number ? (
            <a
              href={client.phone_number && `tel:${client.phone_number}`}
              className="text-dark text-primary-second-hover text-opacity-50 text-opacity-100-hover m-0"
            >
              {client.phone_number}
            </a>
          ) : (
            <p className="text-dark text-opacity-50 m-0">—</p>
          )}
          <a href={`mailto:${client.email}`} className="text-dark text-primary-second-hover text-opacity-50 text-opacity-100-hover m-0">
            {client.email}
          </a>
        </div>
      </div>

      {booking.accessibility_enabled && (
        <div className="hstack gap-10 bg-primary-second bg-opacity-5 rounded p-20 text-primary-second">
          <Icon iconName="Wheelchair" size={25} />
          <div>
            <span className="fw-semibold">{t("booking.labels.accessibility")}: </span>
            {booking.accessibility_notes || "—"}
          </div>
        </div>
      )}
      <Payment />
    </div>
  )
}

export default Preview
