import React, { memo } from "react"
import { useSearchParams } from "react-router-dom"

import moment from "moment"
import { useTranslation } from "react-i18next"
import { convertToMetric, numberFormat } from "helpers/string"

import { useSelector } from "react-redux"
import { dateForecastObjSelector, dateHourlyForecastSelector } from "store/weather"

function Details({ className }) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const isConvertToMetric = searchParams.get("units") === "metric"

  const currentForecastDaily = useSelector(dateForecastObjSelector("daily"))
  const hourlyForecast = useSelector(dateHourlyForecastSelector())
  if (!hourlyForecast) return null

  const currentTime = moment().format("HH:[00]")
  const currentTimePlusOne = moment().add(1, "hours").format("HH:[00]")
  const currentTimeData = hourlyForecast[currentTime] || hourlyForecast[currentTimePlusOne]

  const { calendarDayTemperatureMin, calendarDayTemperatureMax } = currentForecastDaily || {}

  if (!currentTimeData) return null

  const classes = ["bg-white rounded p-25"]
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")}>
      <div className="hstack justify-content-between">
        <h3>{t("weather.details")}</h3>
      </div>
      <div className="grid grid-cols-4 gap-30 justify-content-between mt-30 text-nowrap">
        <div className="vstack text-center">
          <p className="fs-3 fw-medium m-0">
            {convertToMetric(currentTimeData.temperatureFeelsLike, "fahrenheit", true, 0, isConvertToMetric)}
          </p>
          <p className="fs-6 lh-1 text-dark text-opacity-50 m-0">{t("weather.real_feel")}</p>
        </div>
        <div className="vstack text-center">
          <p className="fs-3 fw-medium m-0">
            {convertToMetric(calendarDayTemperatureMax, "fahrenheit", true, 0, isConvertToMetric)}
            <span className="text-dark text-opacity-50">
              {convertToMetric(calendarDayTemperatureMin, "fahrenheit", true, 0, isConvertToMetric)}
            </span>
          </p>
          <p className="fs-6 lh-1 text-dark text-opacity-50 m-0">{t("weather.high_low")}</p>
        </div>
        <div className="vstack text-center">
          <p className="fs-3 fw-medium m-0">{numberFormat(currentTimeData.relativeHumidity, { style: "unit", unit: "percent" })}</p>
          <p className="fs-6 lh-1 text-dark text-opacity-50 m-0">{t("weather.humidity")}</p>
        </div>
        <div className="vstack text-center">
          <p className="fs-3 fw-medium m-0">
            {convertToMetric(currentTimeData.pressureMeanSeaLevel, "inches-of-mercury", true, 0, isConvertToMetric)}
          </p>
          <p className="fs-6 lh-1 text-dark text-opacity-50 m-0">{t("weather.pressure")}</p>
        </div>
        <div className="vstack text-center">
          <p className="fs-3 fw-medium m-0">{convertToMetric(currentTimeData.qpf, "inch", true, 1, isConvertToMetric)}</p>
          <p className="fs-6 lh-1 text-dark text-opacity-50 m-0">{t("weather.precipitation")}</p>
        </div>
        <div className="vstack text-center">
          <p className="fs-3 fw-medium m-0">
            {convertToMetric(currentTimeData.windSpeed, "mile-per-hour", true, 1, isConvertToMetric)}{" "}
            <span className="text-dark text-opacity-50">{currentTimeData.windDirectionCardinal}</span>
          </p>
          <p className="fs-6 lh-1 text-dark text-opacity-50 m-0">{t("weather.wind")}</p>
        </div>
        <div className="vstack text-center">
          <p className="fs-3 fw-medium m-0">{convertToMetric(currentTimeData.visibility, "mile", true, 1, isConvertToMetric)}</p>
          <p className="fs-6 lh-1 text-dark text-opacity-50 m-0">{t("weather.visibility")}</p>
        </div>
        <div className="vstack text-center">
          <p className="fs-3 fw-medium m-0">
            {currentTimeData.uvIndex}
            <small> {currentTimeData.uvDescription.toLowerCase()}</small>
          </p>
          <p className="fs-6 lh-1 text-dark text-opacity-50 m-0">{t("weather.uv_index")}</p>
        </div>
      </div>
    </div>
  )
}

export default memo(Details)
