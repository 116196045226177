import React, { memo } from "react"
import { Button } from "reactstrap"

import { useTranslation } from "react-i18next"
import { convertToMetric } from "helpers/string"

import { useSelector } from "react-redux"
import { dateHourlyForecastSelector } from "store/weather"

function WindTable({ className, onNextDays, onlyBody = false, small = false, maxHeight }) {
  const { t } = useTranslation()

  const hourlyForecast = useSelector(dateHourlyForecastSelector())

  if (!hourlyForecast) return <div className="text-dark text-opacity-50 fs-6">No data</div>
  const hours = Object.keys(hourlyForecast)

  const classes = ["vstack overflow-y-scroll"]
  if (!onlyBody) classes.push("bg-white rounded p-25 pt-0")
  if (className) classes.push(className)
  if (small) classes.push("fs-7")
  else classes.push("fs-5")

  return (
    <div className={classes.join(" ")} style={{ maxHeight, minHeight: "100%" }}>
      {!onlyBody && (
        <div className="hstack justify-content-between bg-white bg-opacity-75 backdrop-blur-12 mx-n25 mb-20 p-25 pb-10 sticky-top z-0">
          <h3>{t("weather.precipitation")}</h3>
          <Button value="precipitation" onClick={onNextDays} color="primary-second" className="py-2 px-20">
            {t("weather.next_days")}
          </Button>
        </div>
      )}
      <div className="vstack gap-15 text-nowrap overflow-hidden flex-shrink-0">
        {hours.map((hour) => (
          <div className={`grid grid-cols-12 gap-${small ? "10" : "15"} align-items-center`} key={hour}>
            <p className="g-col-2 fw-medium lh-1 m-0">{hour}</p>
            <div className="g-col-8 my-auto grid grid-cols-2 gap-1">
              <span className="ms-auto">{convertToMetric(hourlyForecast[hour].windSpeed, "mile-per-hour", true, 1)} | </span>
              <span className="text-dark text-opacity-50">
                {convertToMetric(hourlyForecast[hour].windSpeed, "mile-per-hour", true, 1, false)}
              </span>
            </div>
            <p className="g-col-2 fw-medium lh-1 m-0">{hourlyForecast[hour].windDirectionCardinal}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default memo(WindTable)
