import React, { memo, useEffect, useMemo } from "react"
import { Button, ButtonGroup, DropdownToggle } from "reactstrap"
import { Dropdown, DropdownItem } from "components/common/Dropdown"
import Icon from "components/common/Icon"

import { find } from "lodash"

import { useSelector } from "react-redux"
import { firstForecastSelector } from "store/weather"

import {
  TYPE_OPTIONS,
  RANGE_OPTIONS,
  DEFAULT_RANGE,
  DEFAULT_TYPE,
  UNITS_OPTIONS,
  DEFAULT_UNITS,
  SHOW_UNITS_FOR_TYPES
} from "constants/weather"
import { metadataSelector } from "store/selectors"

function Header({ params, onChangeFilters }) {
  const headerHeight = useSelector((state) => state.window.headerHeight)
  const firstForecast = useSelector(firstForecastSelector)
  const metadata = useSelector(metadataSelector("weather"))
  const selectedTrip = useMemo(
    () => find(metadata.trip_locations, ["address", firstForecast?.location]) || {},
    [metadata.trip_locations, firstForecast.location]
  )

  const hasTripLocations = !!metadata.trip_locations.length
  const isShowConverter = SHOW_UNITS_FOR_TYPES[params.type] || !params.type

  useEffect(() => {
    if (!params.range) onChangeFilters({ target: { value: DEFAULT_RANGE, name: "range" } })
    if (!params.units) onChangeFilters({ target: { value: DEFAULT_UNITS, name: "units" } })
    if (params.range === "7_days") !params.type && onChangeFilters({ target: { value: DEFAULT_TYPE, name: "type" } })
    else {
      onChangeFilters({ target: { value: null, name: "type" } })
      onChangeFilters({ target: { value: null, name: "date" } })
    }
    // eslint-disable-next-line
  }, [params.range, params.type])

  return (
    <div className="sticky-top mt-n30 mb-30" style={{ top: headerHeight }}>
      <div className="full-window-block bg-white py-10 py-sm-20 shadow">
        <div className="hstack gap-2 justify-content-between align-items-center w-100">
          <Dropdown
            className="my-n1"
            toggleButton={
              <DropdownToggle color="ghost" className="hstack gap-2 rounded-1 m-0 p-1">
                <h1 className="h3 fw-medium lh-1">{RANGE_OPTIONS[params.range]}</h1>
                <Icon iconName="SidebarToggle" size={20} />
              </DropdownToggle>
            }
          >
            {Object.entries(RANGE_OPTIONS).map(([range, label], index) => (
              <DropdownItem key={index} name="range" value={range} onClick={onChangeFilters} active={params.range === range}>
                {label}
              </DropdownItem>
            ))}
          </Dropdown>

          <div className="hstack gap-2">
            {params.range === "7_days" && (
              <Dropdown
                className="my-n1"
                toggleButton={
                  <DropdownToggle color="ghost" className="hstack gap-2 rounded-1 m-0 p-1">
                    <span className="h4 fw-medium lh-1">{TYPE_OPTIONS[params.type]}</span>
                    <Icon iconName="SidebarToggle" size={14} />
                  </DropdownToggle>
                }
              >
                {Object.entries(TYPE_OPTIONS).map(([type, label], index) => (
                  <DropdownItem key={index} name="type" value={type} onClick={onChangeFilters} active={params.type === type}>
                    {label}
                  </DropdownItem>
                ))}
              </Dropdown>
            )}
            {isShowConverter && (
              <ButtonGroup>
                {UNITS_OPTIONS.map((units) => (
                  <Button
                    name="units"
                    key={units}
                    value={units}
                    onClick={onChangeFilters}
                    color={params.units === units ? "light" : "gray-lightest"}
                    className={[
                      "fw-normal py-1 px-10",
                      params.units === units ? "text-primary-second pointer-events-none" : "text-gray-light"
                    ].join(" ")}
                  >
                    {SHOW_UNITS_FOR_TYPES[params.type || "temperature"]?.at(units === "metric" ? 0 : 1)}
                  </Button>
                ))}
              </ButtonGroup>
            )}
          </div>

          {hasTripLocations && (
            <Dropdown
              className="my-n1"
              toggleButton={
                <DropdownToggle color="ghost" className="hstack gap-2 rounded-1 m-0 p-1" style={{ maxWidth: 300 }}>
                  <span className="h4 fw-medium lh-1 text-truncate">{selectedTrip.address}</span>
                  <Icon iconName="SidebarToggle" size={14} className="flex-shrink-0" />
                </DropdownToggle>
              }
            >
              {metadata.trip_locations.map((location) => (
                <DropdownItem
                  key={location.id}
                  name="trip_id"
                  value={location.id}
                  onClick={onChangeFilters}
                  active={selectedTrip.address === location.address}
                >
                  {location.address}
                </DropdownItem>
              ))}
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(Header)
