import React, { memo } from "react"
import { TabPane } from "reactstrap"
import DateTabs from "components/weather/DateTabs"
import Chart from "components/weather/Chart"

import moment from "moment"
import { convertToMetric, numberFormatToMetric } from "helpers/string"

import { useSelector } from "react-redux"
import { dateHourlyForecastSelector, dateHourlyFormattedForecastSelector, forecastDateSelector } from "store/weather"

function RealFeel({ className, params, onChangeFilters }) {
  const isConvertToMetric = params.units === "metric"

  const forecastDate = useSelector(forecastDateSelector)
  const hourlyForecast = useSelector(dateHourlyForecastSelector())
  const data = useSelector(dateHourlyFormattedForecastSelector("temperatureFeelsLike", "fahrenheit", 0, isConvertToMetric))

  if (!hourlyForecast) return null

  const currentTime = moment().format("HH:[00]")
  const currentTimePlusOne = moment().add(1, "hours").format("HH:[00]")
  const currentTimeData = hourlyForecast[currentTime] || hourlyForecast[currentTimePlusOne]

  const today = moment()
  const dateMoment = moment(forecastDate || today)
  const isToday = today.isSame(dateMoment, "day")
  const isTomorrow = today.clone().add(1, "day").isSame(dateMoment, "day")
  const dateFormatted = dateMoment.format(`${(isToday && "[Today], ") || (isTomorrow && "[Tomorrow], ") || ""}MMM DD, YYYY`)

  const classes = ["bg-white rounded px-40 py-30 position-relative overflow-hidden"]
  if (className) classes.push(className)

  return (
    <TabPane tabId="real_feel" className={classes.join(" ")}>
      <div className="vstack gap-30">
        <DateTabs params={params} onChangeFilters={onChangeFilters} />
        <div className="mt-10">
          <p className="text-dark text-opacity-50 mb-1">{dateFormatted}</p>
          <p className="fs-3 m-0">{convertToMetric(currentTimeData?.temperatureFeelsLike, "fahrenheit", true, 0, isConvertToMetric)}</p>
        </div>
        <Chart
          hourlyData={data}
          color="122, 174, 251"
          fill
          yAxisKey="value"
          yFormatOptions={(value) => numberFormatToMetric(value, "fahrenheit", 0, isConvertToMetric)}
        />
      </div>
    </TabPane>
  )
}

export default memo(RealFeel)
