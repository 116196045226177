import React, { memo } from "react"
import { TabPane } from "reactstrap"
import DateTabs from "components/weather/DateTabs"
import SunWidget from "components/weather/widgets/Sun"

import moment from "moment"

import { forecastDateSelector } from "store/weather"
import { useSelector } from "react-redux"

function Sun({ className, params, onChangeFilters }) {
  const forecastDate = useSelector(forecastDateSelector)

  const today = moment()
  const dateMoment = moment(forecastDate || today)
  const isToday = today.isSame(dateMoment, "day")
  const isTomorrow = today.clone().add(1, "day").isSame(dateMoment, "day")
  const dateFormatted = dateMoment.format(`${(isToday && "[Today], ") || (isTomorrow && "[Tomorrow], ") || ""}MMM DD, YYYY`)

  const classes = ["bg-white rounded px-40 py-30 position-relative overflow-hidden"]
  if (className) classes.push(className)

  return (
    <TabPane tabId="sun" className={classes.join(" ")}>
      <div className="vstack gap-30">
        <DateTabs params={params} onChangeFilters={onChangeFilters} />
        <div className="mt-10">
          <p className="text-dark text-opacity-50 mb-1">{dateFormatted}</p>
        </div>
        <SunWidget className="" maxWidth={1000} onlyBody />
      </div>
    </TabPane>
  )
}

export default memo(Sun)
