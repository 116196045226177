import React from "react"

import { useTranslation } from "react-i18next"
import moment from "moment"

import { useSelector } from "react-redux"
import { metadataSelector } from "store/selectors"

export default function Bookings({ params }) {
  const { t } = useTranslation()
  const isCurrentMonth = params.overview_range === "current_month"

  const metadata = useSelector(metadataSelector("accounting"))
  const { bookings_stats, booking_client_stats } = metadata
  const { all_client_count, new_client_count, recurring_client_count } = booking_client_stats[params.overview_range] || {}

  const new_client_percent = (+new_client_count * 100) / all_client_count || 0
  const recurring_client_percent = (+recurring_client_count * 100) / all_client_count || 0

  const totalForDates = {
    current_month: moment().format("MMMM YYYY"),
    last_month: moment().subtract(1, "months").format("MMMM YYYY"),
    last_30_days: `${moment().subtract(30, "days").format("MMM DD, YYYY")} — ${moment().format("MMM DD, YYYY")}`,
    total_by_scope: `${moment(params.date_gteq).format("MMM DD, YYYY")} — ${moment(params.date_lteq).format("MMM DD, YYYY")}`
  }
  const dates = totalForDates[params.overview_range]

  // Function to determine the message based on revenue change
  const getRevenueChangeMessage = (monthly_diff) => {
    const numericValue = parseFloat(monthly_diff);
    const action = numericValue >= 0 ? 'Increase' : 'Decrease';
    return t(`accounting.values.value_change_from_last_month`, { action, value: monthly_diff });
  }

  return (
    <div className="bg-white rounded p-15 h-100 vstack">
      <h3 className="h4 fw-normal mb-10">{t(`accounting.labels.booking`)}</h3>
      <p className="fs-7 mb-0">{t(`accounting.values.bookings`, { dates })}</p>
      <p className="fw-bold mb-0">{bookings_stats[params.overview_range]}</p>
      {isCurrentMonth && (
        <p className="fs-7 text-dark text-opacity-50 mb-0">
          {getRevenueChangeMessage(bookings_stats.monthly_diff)}
        </p>
      )}
      <div className="vstack gap-1 justify-content-between mt-25" style={{ minHeight: 150 }}>
        <div style={{ flexGrow: new_client_percent, minHeight: 2 }} className="d-flex gap-10">
          <div className="bg-primary bg-opacity-50 rounded-1 h-100 pe-25" />
          <div className="d-flex gap-1 align-items-start mb-auto h-0">
            <div className="fs-5 fw-bold lh-1">{new_client_count}</div>
            <div className="fs-7 text-dark text-opacity-50 lh-sm">
              <small>{t(`accounting.labels.new_client`)}</small>
            </div>
          </div>
        </div>
        <div style={{ flexGrow: recurring_client_percent, minHeight: 2 }} className="d-flex gap-10">
          <div className="bg-primary rounded-1 h-100 pe-25" />
          <div className="d-flex gap-1 align-items-end mt-auto h-0">
            <div className="fs-5 fw-bold lh-1">{recurring_client_count}</div>
            <div className="fs-7 text-dark text-opacity-50 lh-sm">
              <small>{t(`accounting.labels.recurring_client`)}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
