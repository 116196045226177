import React, { memo, useMemo } from "react"
import { TabPane } from "reactstrap"
import DateTabs from "components/weather/DateTabs"
import Chart from "components/weather/Chart"

import moment from "moment"
import { convertToMetric, numberFormatToMetric } from "helpers/string"
import { mapValues } from "lodash"

import { useSelector } from "react-redux"
import { dateHourlyForecastSelector, forecastDateSelector } from "store/weather"

function Wind({ className, params, onChangeFilters }) {
  const isConvertToMetric = params.units === "metric"

  const forecastDate = useSelector(forecastDateSelector)
  const hourlyForecast = useSelector(dateHourlyForecastSelector())
  const data = useMemo(
    () =>
      mapValues(hourlyForecast, (hourData) => ({
        windSpeed: convertToMetric(hourData.windSpeed, "mile-per-hour", false, 1, isConvertToMetric),
        windDirectionCardinal: hourData.windDirectionCardinal
      })),
    [hourlyForecast, isConvertToMetric]
  )

  if (!hourlyForecast) return null

  const currentTime = moment().format("HH:[00]")
  const currentTimePlusOne = moment().add(1, "hours").format("HH:[00]")
  const currentTimeData = hourlyForecast[currentTime] || hourlyForecast[currentTimePlusOne]

  const today = moment()
  const dateMoment = moment(forecastDate || today)
  const isToday = today.isSame(dateMoment, "day")
  const isTomorrow = today.clone().add(1, "day").isSame(dateMoment, "day")
  const dateFormatted = dateMoment.format(`${(isToday && "[Today], ") || (isTomorrow && "[Tomorrow], ") || ""}MMM DD, YYYY`)

  const classes = ["bg-white rounded px-40 py-30 position-relative overflow-hidden"]
  if (className) classes.push(className)

  const format = (value, raw) => {
    let label = numberFormatToMetric(value, "mile-per-hour", 1, isConvertToMetric)
    if (raw) label += ` <span class="text-dark text-opacity-50">${raw.value.windDirectionCardinal}</span>`
    return label
  }

  return (
    <TabPane tabId="wind" className={classes.join(" ")}>
      <div className="vstack gap-30">
        <DateTabs params={params} onChangeFilters={onChangeFilters} />
        <div className="mt-10">
          <p className="text-dark text-opacity-50 mb-1">{dateFormatted}</p>
          <p className="fs-3 m-0">{convertToMetric(currentTimeData?.windSpeed, "mile-per-hour", true, 1, isConvertToMetric)}</p>
        </div>
        <Chart hourlyData={data} color="122, 174, 251" yAxisKey="value.windSpeed" yFormatOptions={format} />
      </div>
    </TabPane>
  )
}

export default memo(Wind)
