import React from "react"
// import { Button } from "reactstrap"
// import Icon from "components/common/Icon"

const ErrorBoundaryFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div className="alert alert-danger">
      <div className="hstack justify-content-between gap-10">
        <h1 className="h6 lh-sm text-danger">{error.message}</h1>
        {/* <Button color="danger" className="hstack gap-2 small fs-7 text-white p-1 pe-3" onClick={resetErrorBoundary}>
          <Icon iconName="Refresh" size={18} />
          Retry
        </Button> */}
      </div>
      <p className="fs-7 lh-sm mt-10 mb-0">{error.stack}</p>
    </div>
  )
}

export default ErrorBoundaryFallback
