import React, { useCallback, useState } from "react"
import ZoomButtons from "components/map/ZoomButtons"
import Spinner from "components/common/Spinner"
import Icon from "components/common/Icon"
import { Label } from "reactstrap"

import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api"
import { useTranslation } from "react-i18next"

import { useSelector } from "react-redux"
import { tripLatLngSelector } from "store/trips"
import { modelSelector } from "store/selectors"

import { API_OPTIONS, DEFAULT_ZOOM, MAP_OPTIONS, MARKER_ICON } from "constants/map"

export default function Location({ height = 330 }) {
  const { t } = useTranslation()
  const { isLoaded } = useJsApiLoader(API_OPTIONS)
  const trip = useSelector(modelSelector("trip"))
  const tripLatLng = useSelector(tripLatLngSelector)
  const [map, setMap] = useState(null)
  const zoom = map?.zoom || DEFAULT_ZOOM

  const onLoad = useCallback((map) => setMap(map), [])
  const onUnmount = useCallback(() => setMap(null), [])

  return isLoaded ? (
    <>
      <div>
        <Label className="mb-1 lh-1">{t("trip.labels.directions")}:</Label>
        <p>{trip.additional_directions}</p>
      </div>
      <GoogleMap
        mapContainerClassName="rounded"
        mapContainerStyle={{ height }}
        options={{ ...MAP_OPTIONS, draggable: false }}
        center={tripLatLng}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {tripLatLng && <Marker position={tripLatLng} icon={{ ...MARKER_ICON, scaledSize: new window.google.maps.Size(30, 30) }} />}
        <div className="vstack position-absolute p-10 w-100 h-100 gap-10 pointer-events-none">
          <div className="position-relative shadow">
            <div className="px-2 position-absolute top-50 end-0 translate-middle-y">
              <Icon iconName="Location" size={27} className="text-dark" />
            </div>
            <div className="bg-white py-2 ps-10 pe-40 rounded-1">{trip.address}</div>
          </div>
          <ZoomButtons />
        </div>
      </GoogleMap>
    </>
  ) : (
    <div className="hstack bg-white p-50 rounded" style={{ height: 330 }}>
      <Spinner className="m-auto" />
    </div>
  )
}
