import React, { memo } from "react"
import { useSearchParams } from "react-router-dom"
import { Button } from "reactstrap"

import moment from "moment"
import { convertToMetric } from "helpers/string"
import { weatherImage } from "helpers/weather"
import { useTranslation } from "react-i18next"

import { useSelector } from "react-redux"
import { dateHourlyForecastSelector } from "store/weather"

function Temperature({ className, onNextDays }) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const isConvertToMetric = searchParams.get("units") === "metric"

  const hourlyForecast = useSelector(dateHourlyForecastSelector())
  if (!hourlyForecast) return null

  const currentTime = moment().format("HH:[00]")
  const currentTimePlusOne = moment().add(1, "hours").format("HH:[00]")
  const currentTimeData = hourlyForecast[currentTime] || hourlyForecast[currentTimePlusOne]
  const hours = Object.keys(hourlyForecast)

  if (!currentTimeData) return null

  const classes = ["bg-white rounded py-50 px-40 position-relative overflow-x-scroll"]
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")}>
      <div className="position-absolute end-0 top-0 p-20 z-1">
        <Button value="temperature" onClick={onNextDays} color="primary-second" className="py-2 px-20">
          {t("weather.next_days")}
        </Button>
      </div>
      <div className="hstack justify-content-center align-items-end w-100 sticky-start z-0">
        <img src={weatherImage(currentTimeData.iconCode)} alt="" height="150" className="mx-n50" />
        <div>
          <h1 className="fw-normal lh-1">
            <big>{convertToMetric(currentTimeData.temperature, "fahrenheit", true, 0, isConvertToMetric)}</big>
          </h1>
          <p className="fs-3 lh-1 m-0">{currentTimeData.wxPhraseLong}</p>
        </div>
      </div>
      <div className="mt-80">
        <div className="hstack gap-20">
          {hours.map((hour) => (
            <div className="vstack align-items-center fs-3 lh-1" key={hour}>
              <p className="m-3 text-dark text-opacity-75">{hour}</p>
              <img src={weatherImage(hourlyForecast[hour].iconCode)} alt="" height="40" className="mx-n2" />
              <p className="m-0">{convertToMetric(hourlyForecast[hour].temperature, "fahrenheit", true, 0, isConvertToMetric)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default memo(Temperature)
