import React, { memo, useCallback, useEffect } from "react"
import { Button, FormText, Label } from "reactstrap"
import { Offcanvas } from "react-bootstrap"
import Input from "components/form/Input"
import Loading from "modules/loader-watchers/Loading"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"
import withDefaultErrorBoundary from "modules/errors/HOCs/withDefaultErrorBoundary"

import { useTranslation } from "react-i18next"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"
import useForm from "hooks/useForm"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanTextGenerator, textGenerate } from "store/text-generate"
import { hideModal, resetModal } from "modules/modals/reducer"
import { modalSelector } from "modules/modals/selectors"

const PROMPT_SIZE = 25

function TextGeneratorWindow({ name, onSubmit, top: externalTop, width = 450, containerId }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const confirmModal = useConfirmModal()
  const headerHeight = useSelector((state) => state.window.headerHeight)
  const textGeneratorOffcanvas = useSelector(modalSelector("textGeneratorOffcanvas"))
  const [form, changeHandler, submitHandler, submitCallback, reset, isChanged] = useForm({
    prompt: "",
    content: ""
  })
  const isCanGenerateParagraph = form.prompt?.length >= PROMPT_SIZE

  const calculateTop = useCallback(() => {
    if (externalTop instanceof Function) return externalTop(headerHeight)
    else if (Number.isFinite(externalTop)) return externalTop
    else return headerHeight
  }, [externalTop, headerHeight])

  const top = calculateTop()

  const closeHandler = isChanged
    ? confirmModal(
        {
          title: "Are you sure you want to discard all changes?",
          color: "danger",
          submitText: t("global.discard"),
          cancelText: t("global.cancel")
        },
        () => dispatch(hideModal("textGeneratorOffcanvas"))
      )
    : () => dispatch(hideModal("textGeneratorOffcanvas"))

  const cleanContent = (paragraphText) => paragraphText?.replace(/(\r?\n|\r)+/g, '\n')

  const submitModalHandler = () => onSubmit?.({ target: { name, value: form.content } }) || dispatch(hideModal("textGeneratorOffcanvas"))

  submitCallback(() => {
    if (!isCanGenerateParagraph) return
    dispatch(textGenerate(form)).then((payload) =>
      changeHandler({ target: { name: "content", value: cleanContent(payload?.paragraph_text) || "" } })
    )
  })

  useEffect(() => {
    return () => dispatch(resetModal("textGeneratorOffcanvas")) && dispatch(cleanTextGenerator())
  }, []) //eslint-disable-line

  return (
    <Offcanvas
      placement="end"
      scroll
      show={textGeneratorOffcanvas.isOpen}
      onHide={closeHandler}
      onExited={reset}
      container={containerId ? document.getElementById(containerId) : document.body}
      className="border-0 shadow"
      backdropClassName="bg-transparent"
      style={{
        top,
        width
      }}
    >
      <Loading name="textGenerate" tag={Offcanvas.Body} className="vstack gap-20 bg-light p-20 h-100 overflow-y-scroll">
        <DefaultErrorBoundary>
          <form onSubmit={submitHandler} className="vstack gap-20 bg-white rounded p-20">
            <div>
              <div className="hstack gap-10 justify-content-between mb-2">
                <Label for="prompt" className="fs-6 lh-1">
                  <div>{t("text_generator.prompt")}</div>
                  <FormText className="fs-7 fw-normal">
                    <small>{t("text_generator.generate_hint")}</small>
                  </FormText>
                </Label>
                <Button color="primary-second" className="px-15 py-1 fs-7 fw-medium flex-shrink-0" disabled={!isCanGenerateParagraph}>
                  {t("text_generator.generate")}
                </Button>
              </div>
              <Input id="prompt" type="text" name="prompt" value={form.prompt || ""} onChange={changeHandler} className="fs-6" withError />
            </div>

            <div>
              <Label for="content" className="fs-6">
                {t("text_generator.text")}
              </Label>
              <Input
                id="content"
                type="textarea"
                name="content"
                rows={10}
                value={form.content || ""}
                onChange={changeHandler}
                className="fs-6"
                withError
              />
            </div>
          </form>
          <div className="hstack gap-10 justify-content-end bg-white rounded p-20 mt-auto">
            <Button color="light" className="fs-7" onClick={closeHandler}>
              {t("global.cancel")}
            </Button>
            <Button color="primary" className="fs-7" onClick={submitModalHandler} disabled={!isChanged || !form.content}>
              {t("global.submit")}
            </Button>
          </div>
        </DefaultErrorBoundary>
      </Loading>
    </Offcanvas>
  )
}

export default withDefaultErrorBoundary(memo(TextGeneratorWindow))
