import React, { memo } from "react"
import { Button } from "reactstrap"
import Icon from "./Icon"

function SortButton({ sort, activeArrowColor = "dark", arrowColor = "dark", text, ...rest }) {
  return (
    <Button {...rest} className={rest.className || "hstack gap-2 lh-1"}>
      {text}
      <span className="hstack d-inline-flex">
        <Icon iconName="SortArrow" className={sort === "desc" ? `text-${activeArrowColor}` : `text-${arrowColor} text-opacity-50`} />
        <Icon
          iconName="SortArrow"
          className={[sort === "asc" ? `text-${activeArrowColor}` : `text-${arrowColor} text-opacity-50`, "rotate-180"].join(" ")}
        />
      </span>
    </Button>
  )
}

export default memo(SortButton)
