import React from "react"
import { Button, Col, Label, Row } from "reactstrap"
import { useNavigate } from "react-router-dom"
import Icon from "components/common/Icon"
import Input from "components/form/Input"
import ActionButtons from "./ActionButtons"
import GooglePlacesInput from "modules/google/GooglePlacesInput"
import TextGeneratorWindow from "components/common/TextGeneratorWindow"
import InputError from "components/form/InputError"
import Avatar from "components/common/Avatar"

import useForm, { useNestedFields, cleanNestedAttributes } from "hooks/useForm"
import { useWizardSteps } from "hooks/wizard"
import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { convertGoogleAddressComponentsToObj } from "modules/google/helpers"
import { generateCountryOptions } from "components/bookings/helpers"
import { compressFile } from "helpers/compress"

import { useDispatch, useSelector } from "react-redux"
import { guideSelector, isGuideCompletedSelector, updateGuide, updateUser } from "store/user"
import { showModal } from "modules/modals/reducer"
import { errorFieldSelector } from "modules/errors/selectors"

const coogleAddressConfig = {
  street_address: { elements: ["street_number", "route"], divider: " " },
  state: { elements: ["administrative_area_level_1"] },
  city: { elements: ["locality"] },
  country: { elements: ["country"] },
  zip_code: { elements: ["postal_code", "postal_code_suffix"], divider: "-" }
}

const Step2Form = ({ withQrCode = false }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const updateStep = useWizardSteps({ name: "user.guide" })
  const guide = useSelector(guideSelector)
  const wizardCompleted = useSelector(isGuideCompletedSelector)
  const user = useSelector((store) => store.user)
  const { first_name, last_name } = user
  const displayNameError = useSelector(errorFieldSelector("display_name"))

  const [form, changeHandler, submitHandler, submitCallback, reset, isChanged] = useForm(
    {
      ...guide,
      user: {
        first_name,
        last_name
      }
    },
    [
      "display_name",
      "email",
      "phone_number",
      "company_name",
      "website",
      "description",
      "street_address",
      "address_line_2",
      "state",
      "country",
      "city",
      "zip_code",
      "expertises",
      "user",
      "facebook_url",
      "instagram_url",
      "twitter_url"
    ]
  )
  const [, , addExpertiseHandler, removeExpertiseHandler] = useNestedFields(form, "expertises", [], changeHandler)

  const handleImageChange = async (e) => {
    const avatar = await compressFile(e.target.files[0])
    const formData = serialize({ avatar })
    if (avatar) dispatch(updateUser(formData))
  }

  const placeChangedHandler = ({ target }) => {
    const { address_components } = target?.place || {}
    const obj = convertGoogleAddressComponentsToObj(address_components, coogleAddressConfig)
    Object.keys(obj).forEach((name) => changeHandler({ target: { name, value: obj[name] } }))
  }

  const downloadQR = () => {
    fetch(guide.qr_code, {
      method: "GET",
      headers: {}
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", "qr_code.png")
          document.body.appendChild(link)
          link.click()
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const updateStepIfIsntCompleted = (form) => (wizardCompleted ? form : updateStep(form))

  submitCallback(({ nativeEvent }) => {
    const expertises_attributes = cleanNestedAttributes(form.expertises, ["name"])
    const guideAttributes = { ...updateStepIfIsntCompleted(form), expertises_attributes, user_attributes: form.user }
    delete guideAttributes.user

    const nextPath = nativeEvent?.submitter?.value
    const formData = serialize({ guide: guideAttributes }, { indices: true })
    dispatch(updateGuide(guide.id, formData)).then(() => nextPath && navigate(nextPath))
  })

  return (
    <>
      <form onSubmit={submitHandler}>
        <div className="vstack gap-15 bg-white rounded p-20">
          <h2 className="h4">Your Details</h2>
          <Row className="gap-y-15 gx-20">
            {wizardCompleted && (
              <Col xs={12} sm={3} md={2} className="align-self-center hstack">
                <div className="link-target mw-100">
                  <Avatar user={user} size={116} />
                  <div className="hstack position-absolute top-0 start-0 w-100 h-100 z-2">
                    <Button tag="label" htmlFor="avatarPhoto" color="ghost" size="icon" className="ms-auto mt-auto">
                      <Icon iconName="Edit" className="d-block text-dark" />
                    </Button>
                    <input
                      type="file"
                      id="avatarPhoto"
                      name="avatarPhoto"
                      className="d-none"
                      accept="image/png, image/jpeg"
                      onChange={handleImageChange}
                    />
                  </div>
                </div>
              </Col>
            )}
            <Col xs={12} {...(wizardCompleted && { sm: 9, md: 10 })}>
              <Row className="gap-y-15 gx-10">
                {!wizardCompleted && (
                  <>
                    <Col xs={12} sm={6}>
                      <Label for="first_name" className="fs-6">
                        {t("sign_up.first_name")}*
                      </Label>
                      <Input
                        id="first_name"
                        type="text"
                        name="user.first_name"
                        placeholder={t("sign_up.first_name")}
                        value={form.user.first_name || ""}
                        onChange={changeHandler}
                        className="fs-6"
                        withError
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <Label for="last_name" className="fs-6">
                        {t("sign_up.last_name")}*
                      </Label>
                      <Input
                        id="last_name"
                        type="text"
                        name="user.last_name"
                        placeholder={t("sign_up.last_name")}
                        value={form.user.last_name || ""}
                        onChange={changeHandler}
                        className="fs-6"
                        withError
                      />
                    </Col>
                  </>
                )}
                {wizardCompleted && (
                  <>
                    <Col xs={12} sm={8}>
                      <Label for="first_name" className="fs-6">
                        {t("sign_up.first_name")}*
                      </Label>
                      <Input
                        id="first_name"
                        type="text"
                        name="user.first_name"
                        placeholder={t("sign_up.first_name")}
                        value={form.user.first_name || ""}
                        onChange={changeHandler}
                        className="fs-6"
                        withError
                      />
                      <Label for="last_name" className="fs-6 mt-3">
                        {t("sign_up.last_name")}*
                      </Label>
                      <Input
                        id="last_name"
                        type="text"
                        name="user.last_name"
                        placeholder={t("sign_up.last_name")}
                        value={form.user.last_name || ""}
                        onChange={changeHandler}
                        className="fs-6"
                        withError
                      />
                    </Col>
                    <Col xs={12} sm={4} className="text-center">
                      <img src={guide.qr_code} className="w-100" />
                      <Button color="primary-second" outline onClick={downloadQR}>
                        Download
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
          <div>
            <Label for="display_name" className="fs-6">
              {t("guide.display_name")}*
            </Label>
            <Input
              id="display_name"
              type="text"
              name="display_name"
              placeholder={t("guide.display_name")}
              value={form.display_name || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
            {!displayNameError && <InputError field="slug" />}
          </div>
          <div>
            <Label for="email" className="fs-6">
              {t("guide.email")}*
            </Label>
            <Input
              id="email"
              type="email"
              name="email"
              placeholder={t("guide.email")}
              value={form.email || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <div>
            <Label for="phone_number" className="fs-6">
              {t("guide.phone_number")}*
            </Label>
            <Input
              id="phone_number"
              type="tel"
              name="phone_number"
              placeholder={t("guide.phone_number")}
              value={form.phone_number || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <div>
            <Label for="company_name" className="fs-6">
              {t("guide.company_name")}*
            </Label>
            <Input
              id="company_name"
              type="text"
              name="company_name"
              placeholder={t("guide.company_name")}
              value={form.company_name || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <div>
            <Label for="website" className="fs-6">
              {t("guide.website")}
            </Label>
            <Input
              id="website"
              type="url"
              name="website"
              placeholder={t("guide.website")}
              value={form.website || ""}
              onChange={changeHandler}
              className="fs-6"
            />
          </div>
        </div>
        <div className="vstack gap-15 bg-white rounded p-20 mt-20">
          <h2 className="h4">Guide Details</h2>
          <h3 className="h5">Address</h3>
          <div>
            <Label for="street_address" className="fs-6">
              {t("guide.address.street_address")}
            </Label>
            <GooglePlacesInput
              placeholder={t("guide.address.street_address")}
              className="fs-6"
              defaultValue={form.street_address || ""}
              onChange={placeChangedHandler}
              wrapperProps={{ className: "h-100 flex-fill" }}
              useDefaultValue
            />
          </div>
          <div>
            <Label for="address_line_2" className="fs-6">
              {t("guide.address.address_line_2")}
            </Label>
            <Input
              id="address_line_2"
              type="text"
              name="address_line_2"
              placeholder={t("guide.address.address_line_2")}
              value={form.address_line_2 || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <div>
            <Label for="city" className="fs-6">
              {t("guide.address.city")}
            </Label>
            <Input
              id="city"
              type="text"
              name="city"
              placeholder={t("guide.address.city")}
              value={form.city || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <div>
            <Label for="state" className="fs-6">
              {t("guide.address.state")}
            </Label>
            <Input
              id="state"
              type="text"
              name="state"
              placeholder={t("guide.address.state")}
              value={form.state || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <Row className="gap-y-15 gx-10">
            <Col xs={12} sm={6}>
              <Label for="country" className="fs-6">
                {t("guide.address.country")}
              </Label>
              <Input
                id="country"
                type="select"
                name="country"
                placeholder={t("guide.address.country")}
                value={form.country || ""}
                onChange={changeHandler}
                className={["fs-6", form.country ? "" : "text-gray-light"].join(" ")}
                withError
              >
                {generateCountryOptions()}
              </Input>
            </Col>
            <Col xs={12} sm={6}>
              <Label for="zip_code" className="fs-6">
                {t("guide.address.zip_code")}
              </Label>
              <Input
                id="zip_code"
                type="text"
                name="zip_code"
                placeholder={t("guide.address.zip_code")}
                value={form.zip_code}
                onChange={changeHandler}
                className="fs-6"
                withError
              />
            </Col>
          </Row>
          <h3 className="h5 mt-2">About</h3>
          <div>
            <div className="hstack gap-10 justify-content-between mb-2">
              <Label for="description" className="fs-6 m-0">
                {t("guide.description")}*
              </Label>
              <Button
                color="primary-second"
                className="px-15 py-1 fs-7 fw-medium flex-shrink-0"
                onClick={() => dispatch(showModal("textGeneratorOffcanvas"))}
              >
                {t("text_generator.generate")}
              </Button>
            </div>
            <Input
              id="description"
              type="textarea"
              rows={10}
              name="description"
              placeholder={t("guide.description")}
              value={form.description || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <div>
            <h3 className="h5 mt-2">{t("guide.expertises.title")}</h3>
            <p className="fs-6 text-dark text-opacity-50 mb-2">{t("guide.expertises.subtitle")}</p>
            <div className="vstack gap-2">
              {form.expertises?.map(
                (expertise, index) =>
                  (expertise.id || expertise._id) &&
                  !expertise._destroy && (
                    <div key={expertise.id || expertise._id} className="hstack gap-2">
                      <Input
                        id="expertise"
                        type="text"
                        name={`expertises[${index}]name`}
                        placeholder={t("guide.expertises.title")}
                        value={expertise.name || ""}
                        onChange={changeHandler}
                        className="fs-6"
                      />
                      <Button
                        color="link link-dark"
                        className="p-1 m-n1 border-0 bg-dark-hover bg-opacity-25-hover"
                        onClick={() => removeExpertiseHandler(expertise)}
                      >
                        <Icon iconName="Remove" className="d-block rounded-circle border border-dark" />
                      </Button>
                    </div>
                  )
              )}
              <Button
                color="link link-dark text-decoration-none"
                className="p-1 pe-3 m-n1 border-0 bg-dark-hover bg-opacity-25-hover me-auto d-flex"
                onClick={addExpertiseHandler}
              >
                <Icon iconName="Add" className="d-block rounded-circle border border-dark" />
                <span className="fs-7 ms-2 my-auto">Add</span>
              </Button>
            </div>
          </div>
          <h3 className="h5 mt-2">Social Networks</h3>
          <div>
            <Label for="facebook_url" className="fs-6">
              {t("guide.facebook_url")}
            </Label>
            <Input
              id="facebook_url"
              type="url"
              rows={10}
              name="facebook_url"
              placeholder={t("guide.facebook_url")}
              value={form.facebook_url || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <div>
            <Label for="instagram_url" className="fs-6">
              {t("guide.instagram_url")}
            </Label>
            <Input
              id="instagram_url"
              type="url"
              rows={10}
              name="instagram_url"
              placeholder={t("guide.instagram_url")}
              value={form.instagram_url || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <div>
            <Label for="twitter_url" className="fs-6">
              {t("guide.twitter_url")}
            </Label>
            <Input
              id="twitter_url"
              type="url"
              rows={10}
              name="twitter_url"
              placeholder={t("guide.twitter_url")}
              value={form.twitter_url || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
        </div>
        <ActionButtons isChanged={isChanged} onReset={reset} />
      </form>
      <TextGeneratorWindow name="description" onSubmit={changeHandler} top={0} />
    </>
  )
}

export default Step2Form
