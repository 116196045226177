import React, { memo, forwardRef } from "react"
import { Button } from "reactstrap"
import Datepicker from "./Datepicker"
import Icon from "components/common/Icon"

import { DATE_FORMAT } from "modules/datepicker/constants"

function DateSwitcher({ value, type = "date", format: externalFormat = DATE_FORMAT, onChange, className, ...rest }, ref) {
  const classes = ["hstack align-items-stretch"]
  if (className) classes.push(className)

  return (
    <Datepicker
      {...rest}
      wrapperTag="div"
      wrapperProps={{ className: classes.join(" ") }}
      tag={PickerButton}
      decrementTag={DecrementButton}
      incrementTag={IncrementButton}
      type={type}
      name={rest.name || type}
      value={value}
      onChange={onChange}
      format={externalFormat}
      display={{ ...rest.display, buttons: { today: true } }}
      ref={ref}
    />
  )
}

const PickerButton = memo(
  forwardRef(({ date, displayValue, valid, children, ...rest }, ref) => (
    <Button {...rest} className="btn-ghost px-2 py-2 rounded-1" innerRef={ref}>
      <span className={["h3 lh-1 d-block mb-n1", valid ? "" : "opacity-50"].join(" ")}>{displayValue}</span>
    </Button>
  ))
)

const DecrementButton = memo(
  forwardRef((props, ref) => (
    <Button className="btn-ghost text-dark px-2 py-2 rounded-1" {...props} innerRef={ref} type="button">
      <Icon iconName="SidebarToggle" className="rotate-90" size={18} block />
    </Button>
  ))
)

const IncrementButton = memo(
  forwardRef((props, ref) => (
    <Button className="btn-ghost text-dark px-2 py-2 rounded-1" {...props} innerRef={ref} type="button">
      <Icon iconName="SidebarToggle" className="rotate-270" size={18} block />
    </Button>
  ))
)

export default memo(forwardRef(DateSwitcher))
