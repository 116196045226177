import React from "react"
import { Button } from "reactstrap"
import Icon from "./Icon"

export default function ModalCloseButton({ toggle, className = "" }) {
  return (
    <Button color="ghost" size="icon" className={`close position-absolute end-0 top-0 m-3 p-0 ${className}`} onClick={toggle} type="button">
      <Icon iconName="CloseFill" width={22} height={22} />
    </Button>
  )
}
