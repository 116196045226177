import React from "react"
import Icon from "components/common/Icon"

import { useSelector } from "react-redux"
import { errorFieldSelector } from "modules/errors/selectors"

export default function InputError({ field, className, wrapperClassName, children }) {
  const errorField = useSelector(errorFieldSelector(field))
  const errorMessage = (errorField instanceof Array ? errorField?.join(", ") : errorField) || ""

  const baseClasses = ["invalid-feedback d-flex w-auto bg-danger bg-opacity-25 rounded-1 py-1 px-2 fs-7 lh-1"]
  if (className) baseClasses.push(className)

  const wrapperClasses = ["d-flex"]
  if (wrapperClassName) wrapperClasses.push(wrapperClassName)

  return errorMessage ? (
    <div className={wrapperClasses.join(" ")}>
      <div className={baseClasses.join(" ")}>
        <Icon iconName="Warning" className="flex-shrink-0" />
        <small className="ms-2 lh-sm">
          {errorMessage} {children}
        </small>
      </div>
    </div>
  ) : null
}
