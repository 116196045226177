import React, { memo } from "react"
import { Button } from "reactstrap"
import Logo from "components/common/Logo"
import PreviewMedia from "components/common/PreviewMedia"

// Redux
import { useSelector } from "react-redux"

import facebookIcon from "assets/icons/new/facebook.svg"
import instagramIcon from "assets/icons/new/instagram.svg"
import twitterIcon from "assets/icons/new/twitter.svg"

function TemplatePreview({ form, template, isEdit }) {
  const { metadata } = useSelector((state) => state.templates)

  const withPhoto = ["small_gallery", "large_gallery"].includes(template.category)
  const centeredText = ["small_gallery", "large_gallery"].includes(template.category) ? "text-center mx-auto" : "text-start ms-0 me-auto"
  const isEmail = form.template_type === "email"
  const isFirstBasic = template.category === "first_basic"
  const socialMedia = isEdit ? template.social_media : metadata.social_media
  const hasPhoto = form.photos?.length > 0
  const isSportsman = useSelector((state) => state.user.is_sportsman)

  return (
    <div
      className={["bg-light shadow-lg overflow-hidden rounded ms-auto me-auto", !isEmail ? "sms-text fs-5" : "", centeredText].join(" ")}
      style={{ maxWidth: 535 }}
    >
      <div className="bg-white rounded p-35">
        {isEmail &&
          (isSportsman ? (
            <Logo className="d-block mx-auto mb-25" alt="Logo" width={35} height={35} wide />
          ) : (
            <Logo className="d-block mx-auto mb-25" alt="Logo" width={35} height={35} is_sportswoman wide />
          ))}
        {!isFirstBasic && form.heading && (
          <h3 className={`fw-medium ${centeredText}`} style={{ maxWidth: 390 }}>
            {form.heading}
          </h3>
        )}
        <p className="fs-5 my-20 text-prewrap">
          Hello [Client Name]
          {form.paragraph_text && (
            <>
              <br />
              <br />
              {form.paragraph_text}
            </>
          )}
        </p>

        {withPhoto && hasPhoto && isEmail && (
          <div className="vstack gap-30 mx-n35 mt-40">
            {form.photos?.map((photo) => (
              <div key={photo.id || photo._id} className="w-100" style={{ flexBasis: 200, flexGrow: 1 }}>
                <PreviewMedia type={photo.mediaType} preview={photo.file_preview || photo.url} ratio="4x3" rounded={false} />
              </div>
            ))}
          </div>
        )}

        {!isFirstBasic && isEmail && (form.button_text || form.button_url) && (
          <div className="my-35">
            <Button color="dark" className="fs-5 w-auto py-10 rounded-1" onClick={() => console.log()}>
              {form.button_text || "Browse Trips"}
            </Button>
          </div>
        )}

        <div className="fs-5 lh-base mt-35">
          {!isEmail && form.button_url && (
            <>
              <Button color="link" className="link w-auto" block type="button">
                {form.button_url}
              </Button>
              <br />
            </>
          )}
          {form.signature && <div>{form.signature}</div>}
          <div className="fw-medium fst-italic">{"[Guide profile name]"}</div>
        </div>
      </div>
      {socialMedia && isEmail && (
        <div className="hstack justify-content-center gap-20 p-10">
          {socialMedia.facebook_url && <img src={facebookIcon} alt={""} />}
          {socialMedia.instagram_url && <img src={instagramIcon} alt={""} />}
          {socialMedia.twitter_url && <img src={twitterIcon} alt={""} />}
        </div>
      )}
    </div>
  )
}

export default memo(TemplatePreview)
