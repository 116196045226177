import React, { memo, useCallback } from "react"
import { Label } from "reactstrap"
import Input from "components/form/Input"

import { useTranslation } from "react-i18next"
import { numberToCurrency } from "helpers/string"
import { calcPayToday } from "components/bookings/helpers"

import { PAYMENT_FULL_CASH_ON_DAY } from "../constants"

const PaymentOptionList = ({
  paymentOptions,
  active,
  onChange: changeHandler,
  totalPrice,
  depositPercentage,
  disabled: allDisabled,
  canCharge
}) => {
  const { t } = useTranslation()

  const isDisabled = useCallback((po) => allDisabled || (!canCharge && po !== PAYMENT_FULL_CASH_ON_DAY), [allDisabled, canCharge])

  return paymentOptions.map((po) => {
    const currentPayToday = calcPayToday(po, totalPrice, depositPercentage)
    const currentPayLater = totalPrice - currentPayToday
    const disabled = isDisabled(po)
    return (
      <Label
        key={po}
        check
        className={["bg-white rounded shadow overflow-hidden", disabled ? "pointer-events-none opacity-50" : ""].join(" ")}
      >
        <div
          className={[
            "hstack gap-10 justify-content-between fs-5 px-15 py-10 rounded bg-opacity-5 border",
            active === po ? "bg-primary-second border-primary-second" : "bg-white border-gray-lightest"
          ].join(" ")}
        >
          <span className="fw-medium">
            {t(`booking.client_payment.labels.${po}`)}
            <span className={["fs-7 text-opacity-75 d-block", active === po ? "text-primary-second" : "text-dark"].join(" ")}>
              {t(`booking.client_payment.labels.${po}_subtext`, {
                payToday: numberToCurrency(currentPayToday),
                payLater: numberToCurrency(currentPayLater)
              })}
            </span>
          </span>
          <Input
            type="radio"
            name="payment_option"
            value={po}
            checked={active === po}
            onChange={() => changeHandler(po)}
            className="border-primary-second mt-0"
            style={{ width: 20, height: 20 }}
            disabled={disabled}
          />
        </div>
      </Label>
    )
  })
}

export default memo(PaymentOptionList)
