import React from "react"
import { Badge } from "reactstrap"
import { NavLink } from "react-router-dom"
import Icon from "components/common/Icon"

export default function NavigationLink({ tag, isActive = false, to, iconName, label, badge, className, end = false, ...rest }) {
  const classes = ["btn btn-sm hstack gap-2 position-relative rounded-1 h5 fw-medium lh-1 text-nowrap"]
  if (className) classes.push(className)
  if (rest.disabled) classes.push("text-dark text-opacity-50")

  const Tag = tag || NavLink
  const isNavLink = !tag

  const classFunc = ({ isActive }) => [...classes, isActive ? "btn-primary-second active-link" : "btn-light"].join(" ")
  const props = isNavLink ? { to, end } : {}

  return (
    <Tag className={isNavLink ? classFunc : classFunc({ isActive })} {...rest} {...props}>
      <Icon iconName={iconName} className="my-n1" />
      {label}
      {badge && (
        <Badge
          color="white"
          className="d-block position-absolute top-auto bottom-100 start-0 mb-n2 text-primary-second text-center fw-normal lh-1 border border-primary-second"
          style={{ minWidth: 17 }}
        >
          {badge}
        </Badge>
      )}
    </Tag>
  )
}
