import React from "react"
import RegisterForm from "../../components/devise/RegisterForm"
import { Link } from "react-router-dom"
import { routes } from "router"

import { useTitle } from "hooks/useTitle"

export default function SignUp() {
  useTitle("Sign Up")
  return (
    <>
      <h1 className="h2 lh-1">Welcome!</h1>
      <p className="h3 lh-1 mt-15 mb-40">Create your account</p>

      <RegisterForm role="user" />

      <div className="mt-15 fs-6 fw-medium lh-1">
        <div className="hstack d-inline-flex gap-1 small link-target">
          Already have an account?{" "}
          <Link className="text-primary-hover text-decoration-none" to={routes.signInPath()}>
            Log In
          </Link>
        </div>
      </div>
    </>
  )
}
