import React from "react"
import PhotoSlider from "components/common/PhotoSlider"
import { useTranslation } from "react-i18next"

const Post = ({ boat, className, style, ...rest }) => {
  const { name, properties, custom_info, safety } = boat
  const { t } = useTranslation()

  const classes = ["vstack bg-white rounded overflow-hidden shadow-lg position-relative w-100"]
  if (className) classes.push(className)
  return (
    <div className={classes.join(" ")} style={style} {...rest}>
      <div className="flex-shrink-0 align-self-stretch position-relative" style={{ flexBasis: 200, flexGrow: 1 }}>
        <PhotoSlider photos={properties} preview className="w-100 h-100" />
      </div>
      <div className="p-3 h-100">
        <h1 className="fs-7 fw-mediumlh-sm">{name || ""}</h1>
        {custom_info.length > 0 && <h1 className="fs-7 fw-mediumlh-sm">{t("boat.custom")}</h1>}
        {custom_info.map((info, index) => (
          <div key={index + 1} className="fs-7">
            {"- " + info || "—"}
          </div>
        ))}
        {safety.length > 0 && <h1 className="pt-1 fs-7 fw-mediumlh-sm">{t("boat.safety_info")}</h1>}
        {safety.map((info, index) => (
          <div key={index + 1} className="fs-7">
            {"- " + info || "—"}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Post
