import React from "react"
import { routes } from "router"
import { Button } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Preview from "components/trips/Preview"
import ActionButtons from "components/wizards/ActionButtons"

import { useTranslation } from "react-i18next"
import { useTitle } from "hooks/useTitle"

import { updateTrip } from "store/trips"
import { useDispatch, useSelector } from "react-redux"
import { modelSelector } from "store/selectors"

export default function Step5() {
  useTitle("Trip Wizard — Step 5")
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const trip = useSelector(modelSelector("trip"))
  const isPublished = trip.status === "published"
  const navigate = useNavigate()

  const publishTripHandler = (event) => {
    if (event.persist) event.persist()
    dispatch(updateTrip(trip.id, { trip: { status: "published" } })).then(() => navigate(routes.guideTripsPath()))
  }

  return (
    <>
      <Preview isEdit />
      <ActionButtons
        action={updateTrip}
        name="trips.trip"
        submitText={t("trip.wizard.submit")}
        showSubmitAfterCompleted={false}
        className="mt-50"
        exitPath={routes.guideTripsPath()}
      >
        {trip.wizard_completed && (
          <Button tag={Link} to={routes.guideTripsPath()} color="primary-second">
            {t("global.save")}
          </Button>
        )}
        {!isPublished && (
          <Button color="primary" onClick={publishTripHandler}>
            {t("global.publish")}
          </Button>
        )}
      </ActionButtons>
    </>
  )
}
