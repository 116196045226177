import React, { useEffect } from "react"
import { useMatch, useSearchParams } from "react-router-dom"
import { routes } from "router"
import OffcanvasWindow from "modules/offcanvas/OffcanvasWindow"
import Form from "components/accounting/Form"

// Redux
import { useDispatch } from "react-redux"
import { cleanExpense, getExpense } from "store/accounting"

export default function ExpenseWindow() {
  const dispatch = useDispatch()
  const isPersisted = useMatch(routes.guideAccountingEditExpancePath())
  const expenseId = isPersisted?.params?.id
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (expenseId) dispatch(getExpense(expenseId))
    return () => dispatch(cleanExpense())
  }, [expenseId, dispatch])

  return (
    <OffcanvasWindow
      container={document.getElementById("accounting")}
      loadingName="accounting.expense"
      exitRedirect={routes.guideAccountingPath({}, searchParams)}
    >
      {({ setChanged, closeHandler }) => <Form onChanged={setChanged} closeHandler={closeHandler} />}
    </OffcanvasWindow>
  )
}
