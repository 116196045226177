import React from "react"
import { Container, Row, Col, Badge } from "reactstrap"

import blog_boatImage from "assets/images/landing/blog_boat.png"
import blog_reelImage from "assets/images/landing/blog_reel.png"
import blog_captainImage from "assets/images/landing/blog_captain.png"

export default function DSBlog() {
  return (
    <div className="position-relative overflow-hidden" style={{ background: "rgba(0, 82, 180, 0.04)" }}>
      <Container className="vstack align-items-center my-50 my-lg-80">
        <h1 className="fw-semibold lh-1 text-center m-20 mb-sm-40">DS Blog</h1>
        <Row className="justify-content-center w-100 gx-25 gx-lg-50 gy-20">
          <Col xs={12} md={4}>
            <a
              href="https://go.ds.live/blog/digital_sportsman_launch_icast2024"
              className="fw-semibold lh-sm fs-3 link-underline-opacity-75"
              style={{ color: "#141414", fontFamily: "Epilogue", textDecoration: "none" }}
            >
              <img src={blog_boatImage} className="w-100 mb-5" alt="Blog Post Image 1" />
              <Badge pill className="text-primary-second text-center fw-medium lh-1  rounded p-2 bg-opacity-10 fs-5 mb-3">
                News
              </Badge>
              <p>Digital Sportsman Launches at ICAST</p>
            </a>
          </Col>
          <Col xs={12} md={4}>
            <a
              href="https://go.ds.live/blog/selling_corporate_charters"
              className="fw-semibold lh-sm fs-3"
              style={{ color: "#141414", fontFamily: "Epilogue", textDecoration: "none" }}
            >
              <img src={blog_reelImage} className="w-100 mb-5" alt="Blog Post Image 2" />
              <Badge pill className="text-primary-second text-center fw-medium lh-1  rounded p-2 bg-opacity-10 fs-5 mb-3">
                Sales
              </Badge>
              <p>Reeling in the Lucrative Corporate Charter Market</p>
            </a>
          </Col>
          <Col xs={12} md={4}>
            <a
              href="https://go.ds.live/blog/using-ai-outdoor-trips-business"
              className="fw-semibold lh-sm fs-3"
              style={{ color: "#141414", fontFamily: "Epilogue", textDecoration: "none" }}
            >
              <img src={blog_captainImage} className="w-100 mb-5" alt="Blog Post Image 3" />
              <Badge pill className="text-primary-second text-center fw-medium lh-1  rounded p-2 bg-opacity-10 fs-5 mb-3">
                Business Management
              </Badge>
              <p>Using AI for Your Outdoor & Trips Business</p>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
