import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { showModal } from "../reducer"
import { confirmModalSelector } from "../selectors"

export const useConfirmModal = () => {
  const dispatch = useDispatch()
  const confirmModal = useSelector(confirmModalSelector)
  const { isOpen, submit } = confirmModal
  const savedHandler = useRef(null)
  const savedEvent = useRef(null)

  const confirm = (modalProps, cb) => (event) => {
    if (event && event.persist) event.persist()
    if (cb instanceof Function) {
      savedHandler.current = cb
      savedEvent.current = { ...event }
    }
    dispatch(showModal("confirmModal", modalProps))
  }

  useEffect(() => {
    if (submit)
      savedHandler.current instanceof Function ? savedHandler.current(savedEvent.current) : console.log("Confirm modal has no callback")
  }, [submit])

  useEffect(() => {
    if (isOpen) return
    savedHandler.current = null
    savedEvent.current = null
  }, [isOpen])

  return confirm
}
