import React from "react"
import { ToastContainer, toast } from "react-toastify"
import { Button } from "reactstrap"
import Icon from "./Icon"

import "react-toastify/dist/ReactToastify.css"

export const Msg = ({ content }) => {
  const { title, text } = content || {}
  return (
    <>
      {!title && !text && content && <p className="fs-6 m-0" dangerouslySetInnerHTML={{ __html: content }} />}
      {title && <p className="msg-title h6" dangerouslySetInnerHTML={{ __html: title }} />}
      {text && <p className="msg-description fs-6 mb-0" dangerouslySetInnerHTML={{ __html: text }} />}
    </>
  )
}

const TOASTS_COLOR_MAP = {
  error: "danger",
  info: "info",
  success: "success",
  warning: "warning"
}

const defaultOptions = {
  className: (props) =>
    [
      `toast bg-white bg-opacity-100 p-0 min-h-0 shadow-none show border-opacity-10 border-${TOASTS_COLOR_MAP[props.type]}`,
      props.defaultClassName
    ].join(" "),
  bodyClassName: (props) =>
    [
      `toast-body bg-opacity-10 w-100 pe-40 fs-6 text-bg-${TOASTS_COLOR_MAP[props.type]} text-${TOASTS_COLOR_MAP[props.type]}`,
      props.defaultClassName
    ].join(" "),
  progressClassName: (props) => [`bg-${TOASTS_COLOR_MAP[props.type]}`, props.defaultClassName].join(" "),
  closeButton: (props) => (
    <div className="p-2 position-absolute end-0 top-0">
      <Button
        color="ghost"
        className={`bg-opacity-10-hover p-1 text-${TOASTS_COLOR_MAP[props.type]} bg-${TOASTS_COLOR_MAP[props.type]}-hover`}
        onClick={props.closeToast}
      >
        <Icon iconName="Close" size={20} />
      </Button>
    </div>
  )
}

export const toaster = (content, options) => toast(<Msg content={content} />, { ...options, ...defaultOptions })
toaster.success = (content, options) => toast.success(<Msg content={content} />, { ...options, ...defaultOptions })
toaster.error = (content, options) => toast.error(<Msg content={content} />, { ...options, ...defaultOptions })
toaster.info = (content, options) => toast.info(<Msg content={content} />, { ...options, ...defaultOptions })
toaster.warn = (content, options) => toast.warn(<Msg content={content} />, { ...options, ...defaultOptions })
toaster.dark = (content, options) => toast.dark(<Msg content={content} />, { ...options, ...defaultOptions })

export default function Toast() {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      newestOnTop
      closeOnClick={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      stacked
    />
  )
}
