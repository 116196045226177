import React, { memo, useMemo } from "react"
import Day from "./Day"
import Spinner from "components/common/Spinner"

import moment from "moment"

import { useSelector } from "react-redux"

const CalendarMonth = ({ date, className }) => {
  const { loading } = useSelector((store) => store.dashboard)

  const today = moment()

  const monthStartDate = useMemo(() => moment(date).startOf("month"), [date])
  const monthEndDate = useMemo(() => moment(date).endOf("month"), [date])

  const calendarStartDate = useMemo(() => monthStartDate.clone().startOf("isoWeek"), [monthStartDate])
  const calendarEndDate = useMemo(() => monthEndDate.clone().endOf("isoWeek"), [monthEndDate])
  const calendarDaysCount = useMemo(() => calendarEndDate.diff(calendarStartDate, "days") + 1, [calendarStartDate, calendarEndDate])

  const calendarDays = useMemo(
    () => new Array(calendarDaysCount).fill().map((_, index) => calendarStartDate.clone().add(index, "days")),
    [calendarStartDate, calendarDaysCount]
  )
  const weekDayNames = useMemo(
    () => new Array(7).fill().map((_, index) => calendarStartDate.clone().add(index, "days").format("ddd")),
    [calendarStartDate]
  )

  const classes = ["vstack"]
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")}>
      <div
        className="grid grid-cols-7 py-1 border-bottom border-gray-lightest bg-white sticky-top"
        style={{ minWidth: '1000px' }}
      >
        {weekDayNames.map((dayName) => (
          <span key={dayName} className="fs-7 fw-medium text-center">
            {dayName}
          </span>
        ))}
      </div>
      <div
        className="grid grid-cols-7 gap-0 position-relative flex-fill"
        style={{ gridTemplateColumns: "repeat(var(--bs-columns, 12), minmax(0,1fr))", gridTemplateRows: "auto", minWidth: '1000px' }}
      >
        {calendarDays.map((day, index) => (
          <Day
            key={day.dayOfYear()}
            day={day}
            today={today}
            thisMonth={day.isBetween(monthStartDate, monthEndDate, "day", "[]")}
            lastCol={!((index + 1) % 7)}
            lastRow={index + 1 > calendarDays.length - 7}
          />
        ))}
        {loading && <Spinner className="bg-light bg-opacity-75 w-100 h-100" absolute />}
      </div>
    </div>
  )
}
export default memo(CalendarMonth)
