import React, { memo } from "react"
import Spinner from "components/common/Spinner"
import DynamicTag from "components/DynamicTag"

import { useSelector } from "react-redux"
import { loadingSelector } from "./selectors"

function Loading({
  name,
  loading: forceLoading = false,
  tag = null,
  spinnerProps: { className: spinnerClassName, position = "absolute", ...spinnerRest } = {},
  className = "",
  children,
  ...rest
}) {
  const loading = useSelector(name ? loadingSelector(name) : () => forceLoading)

  const classes = []
  if (className) classes.push(className)
  if (!className.match(/position-/g)?.length) classes.push("position-relative")

  const spinnerClasses = ["bg-white bg-opacity-10 backdrop-blur-3 transition z-2"]

  if (spinnerClassName) spinnerClasses.push(spinnerClassName)
  if (loading) spinnerClasses.push("opacity-100")
  else spinnerClasses.push("pointer-events-none opacity-0")

  return (
    <DynamicTag className={classes.join(" ")} {...rest} tag={tag}>
      {children}
      <Spinner className={spinnerClasses.join(" ")} absolute={position === "absolute"} fixed={position === "fixed"} {...spinnerRest} />
    </DynamicTag>
  )
}

export default memo(Loading)
