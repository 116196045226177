import React from "react"
import { createHashRouter, Navigate } from "react-router-dom"
import { routes } from "./routes.js"
import PrivateRoutes from "./PrivateRoutes"
import PublicRoutes from "./PublicRoutes"

import RouteChangeWatcher from "components/RouteChangeWatcher"
import RouteErrorBoundary from "modules/errors/RouteErrorBoundary"

import ScrollToTopLayout from "layouts/ScrollToTopLayout"
import LandingLayout from "layouts/LandingLayout"
import DeviseLayout from "layouts/DeviseLayout"
import BookingLayout from "layouts/BookingLayout"
import DashboardLayout from "layouts/DashboardLayout"
import GuideLayout from "layouts/public/GuideLayout"
import GuideWizardLayout from "layouts/guide/WizardLayout"
import GuideTripWizardLayout from "layouts/guide/TripWizardLayout"
import GuideSettingsLayout from "layouts/guide/SettingsLayout"
import ClientSettingsLayout from "layouts/client/SettingsLayout"

import Landing from "pages/LandingPage"
import GuideLanding from "pages/GuideLandingPage"
import Faq from "pages/FaqPage"
import TermsAndConditions from "pages/TermsAndConditionsPage"
import PrivacyPolicy from "pages/PrivacyPolicyPage"
import Trips from "pages/public/trips/TripsPage"
import Trip from "pages/public/trips/TripPage"
import Referral from "pages/public/referral/ReferralPage"
import Sales from "pages/public/sales/SalesPage"

import SignIn from "pages/devise/SignInPage"
import SignUp from "pages/devise/SignUpPage"
import SignUpRequestSent from "pages/devise/SignUpRequestSent"
import ForgotPassword from "pages/devise/ForgotPasswordPage"
import ResetPasswordRequestSent from "pages/devise/ResetPasswordRequestSentPage"
import ResetPassword from "pages/devise/ResetPasswordPage"
import ResetPasswordSuccess from "pages/devise/ResetPasswordSuccessPage"
import VerifyPhone from "components/old/Verification/VerifyPhone"
import VerifyCode from "components/old/Verification/VerifyCode"

import PublicGuide from "pages/public/guides/GuidePage"
import PublicGuideTrips from "pages/public/guides/TripsPage"
import PublicGuideFeeder from "pages/public/guides/FeederPage"
import PublicGuideReviews from "pages/public/guides/ReviewsPage"
import PublicGuideLocation from "pages/public/guides/LocationPage"
import PublicGuideBoats from "pages/public/guides/BoatsPage"

import ClientSettingsDetails from "pages/client/settings/MyDetailsPage"
import ClientPaymentDetails from "pages/client/settings/PaymentDetailsPage"
import ClientSettingsLoginAndSecurity from "pages/client/settings/LoginAndSecurityPage"
import ClientSettingsNotifications from "pages/client/settings/NotificationsPage"

import ClientBookings from "pages/client/bookings/BookingsPage"
import ClientBookingsBooked from "pages/client/bookings/BookedTripsPage"
import ClientBookingsPast from "pages/client/bookings/PastTripsPage"
import ClientBooking from "pages/client/bookings/BookingTripPage"
import ClientBookingWizardAccount from "pages/client/bookings/wizard/AccountPage"
import ClientBookingWizardStep1 from "pages/client/bookings/wizard/Step1Page"
import ClientBookingWizardStep2 from "pages/client/bookings/wizard/Step2Page"
import ClientBookingWizardStep3 from "pages/client/bookings/wizard/Step3Page"
import ClientBookingWizardStep4 from "pages/client/bookings/wizard/Step4Page"

import FavoritesPage from "pages/client/favorites/FavoritesPage"

import GuideDashboardPage from "pages/guide/dashboard/DashboardPage"
import GuideJournalWindow from "pages/guide/dashboard/JournalWindow"
import GuideUnavailableDayWindow from "pages/guide/dashboard/UnavailableDayWindow"
import GuideDashboardBookingWindow from "pages/guide/dashboard/BookingWindow"

import GuideBookingsPage from "pages/guide/bookings/BookingsPage"
import GuideBookingWindow from "pages/guide/bookings/BookingWindow"

import GuideClients from "pages/guide/clients/ClientsPage"
import GuideClientWindow from "pages/guide/clients/ClientWindow"
import GuideClientActionWindow from "pages/guide/clients/ActionWindow"

import GuideWizardStep1 from "pages/guide/wizard/Step1Page"
import GuideWizardStep2 from "pages/guide/wizard/Step2Page"
import GuideWizardStep3 from "pages/guide/wizard/Step3Page"
import GuideWizardStep4 from "pages/guide/wizard/Step4Page"

import GuideTrips from "pages/guide/trips/TripsPage"
import GuideTripWizardStep1 from "pages/guide/trips/wizard/Step1Page"
import GuideTripWizardStep2 from "pages/guide/trips/wizard/Step2Page"
import GuideTripWizardStep3 from "pages/guide/trips/wizard/Step3Page"
import GuideTripWizardStep4 from "pages/guide/trips/wizard/Step4Page"
import GuideTripWizardStep5 from "pages/guide/trips/wizard/Step5Page"

import Boats from "pages/guide/boats/BoatsPage"
import BoatWindow from "pages/guide/boats/BoatWindow"

import MessagesPage from "pages/private/messages/MessagesPage"

import GuideAccounting from "pages/guide/accouting/Accounting"
import GuideAccountingExpenseWindow from "pages/guide/accouting/ExpenseWindow"

import GuideMarketing from "pages/guide/marketing/MarketingPage"
import GuideFeeder from "pages/guide/marketing/FeederPage"
import GuidePostWindow from "pages/guide/marketing/PostWindow"
import GuideTemplates from "pages/guide/marketing/TemplatesPage"
import GuideTemplatesParents from "pages/guide/marketing/TemplatesParentsPage"
import GuideTemplate from "pages/guide/marketing/TemplatePage"

import GuideSettingsDetails from "pages/guide/settings/MyDetailsPage"
import GuideSettingsPhotos from "pages/guide/settings/PhotosPage"
import GuideSettingsExtras from "pages/guide/settings/ExtrasPage"
import GuideSettingsDocuments from "pages/guide/settings/DocumentsPage"
import GuideSettingsStripe from "pages/guide/settings/StripePage"
import GuideSettingsSocials from "pages/guide/settings/SocialsPage"
import GuideSettingsLoginAndSecurity from "pages/guide/settings/LoginAndSecurityPage"
import GuideSettingsNotifications from "pages/guide/settings/NotificationsPage"
import GuideContactUs from "components/settings/ContactUs"

import GuideWeather from "pages/guide/weather/WeatherPage"

export const router = createHashRouter([
  {
    element: (
      <RouteChangeWatcher>
        <ScrollToTopLayout />
      </RouteChangeWatcher>
    ),
    errorElement: (
      <DeviseLayout>
        <RouteErrorBoundary />
      </DeviseLayout>
    ),
    children: [
      {
        // Public only routes
        element: <PublicRoutes only />,
        children: [
          {
            element: <LandingLayout />,
            children: [
              { path: routes.rootPath(), element: <Landing /> },
              { path: routes.referralPath(), element: <Referral /> },
              { path: routes.salesPath(), element: <Sales /> }
            ]
          },
          {
            element: <DeviseLayout />,
            children: [
              { path: routes.signInPath(), element: <SignIn /> },
              { path: routes.signUpPath(), element: <SignUp /> },
              { path: routes.signUpRequestSentPath(), element: <SignUpRequestSent /> },
              { path: routes.forgotPasswordPath(), element: <ForgotPassword /> },
              { path: routes.resetPasswordRequestSentPath(), element: <ResetPasswordRequestSent /> },
              { path: routes.resetPasswordPath(), element: <ResetPassword /> },
              { path: routes.resetPasswordSuccessPath(), element: <ResetPasswordSuccess /> },
              { path: routes.verifyAccountPath(), element: <VerifyPhone /> },
              { path: routes.verifyCodePath(), element: <VerifyCode /> }
            ]
          },
          {
            element: <BookingLayout />,
            children: [{ path: routes.bookingWizardPath(), element: <ClientBookingWizardAccount /> }]
          },
          {
            element: <BookingLayout />,
            path: routes.guestBookingWizardRootPath(),
            children: [
              { path: routes.guestBookingWizardStep1Path(), element: <ClientBookingWizardStep1 /> },
              { path: routes.guestBookingWizardStep2Path(), element: <ClientBookingWizardStep2 /> },
              { path: routes.guestBookingWizardStep3Path(), element: <ClientBookingWizardStep3 /> },
              { path: routes.guestBookingWizardStep4Path(), element: <ClientBookingWizardStep4 /> }
            ]
          }
        ]
      },
      {
        // Public routes
        element: <PublicRoutes />,
        children: [
          {
            element: <LandingLayout />,
            children: [
              { path: routes.becomeAGuidePath(), element: <GuideLanding /> },
              { path: routes.faqPath(), element: <Faq /> },
              { path: routes.termsAndConditionsPath(), element: <TermsAndConditions /> },
              { path: routes.privacyPolicyPath(), element: <PrivacyPolicy /> },
              { path: routes.tripsPath(), element: <Trips /> },
              { path: routes.tripPath(), element: <Trip /> }
            ]
          },
          {
            element: <GuideLayout />,
            path: routes.publicGuidePath(),
            children: [
              { index: true, element: <PublicGuide /> },
              { path: routes.publicGuideTripsPath(), element: <PublicGuideTrips /> },
              { path: routes.publicGuidePostsPath(), element: <PublicGuideFeeder /> },
              { path: routes.publicGuideReviewsPath(), element: <PublicGuideReviews /> },
              { path: routes.publicGuideLocationPath(), element: <PublicGuideLocation /> },
              { path: routes.publicGuideBoatsPath(), element: <PublicGuideBoats /> }
            ]
          }
        ]
      },
      {
        // Client routes
        path: routes.clientRootPath(),
        element: <PrivateRoutes accessRole="user" />,
        children: [
          {
            element: <DashboardLayout />,
            children: [
              { index: true, element: <Navigate to={routes.clientBookingsPath()} /> },
              { path: routes.clientMessagesPath(), element: <MessagesPage /> },
              { path: routes.clientFavoritesPath(), element: <FavoritesPage /> },
              {
                path: routes.clientBookingsPath(),
                element: <ClientBookings />,
                children: [
                  { index: true, element: <ClientBookingsBooked /> },
                  { path: routes.clientBookingsPastTripsPath(), element: <ClientBookingsPast /> }
                ]
              },
              { path: routes.clientBookingPath(), element: <ClientBooking /> },
              {
                element: <ClientSettingsLayout />,
                children: [
                  { path: routes.clientSettingsRootPath(), element: <ClientSettingsDetails /> },
                  { path: routes.clientSettingsPaymentPath(), element: <ClientPaymentDetails /> },
                  { path: routes.clientSettingsPasswordPath(), element: <ClientSettingsLoginAndSecurity /> },
                  { path: routes.clientSettingsNotificationsPath(), element: <ClientSettingsNotifications /> }
                ]
              }
            ]
          },
          {
            element: <BookingLayout />,
            path: routes.clientBookingWizardRootPath(),
            children: [
              { path: routes.clientBookingWizardStep1Path(), element: <ClientBookingWizardStep1 /> },
              { path: routes.clientBookingWizardStep2Path(), element: <ClientBookingWizardStep2 /> },
              { path: routes.clientBookingWizardStep3Path(), element: <ClientBookingWizardStep3 /> },
              { path: routes.clientBookingWizardStep4Path(), element: <ClientBookingWizardStep4 /> }
            ]
          }
        ]
      },
      {
        // Guide routes
        path: routes.guideRootPath(),
        element: <PrivateRoutes accessRole="guide_user" />,
        children: [
          { index: true, element: <Navigate to={routes.guideDashboardPath()} /> },
          {
            element: <DashboardLayout />,
            children: [
              {
                path: routes.guideDashboardPath(),
                element: <GuideDashboardPage />,
                children: [
                  { path: routes.guideDashboardJournalNewPath(), element: <GuideJournalWindow /> },
                  { path: routes.guideDashboardJournalEditPath(), element: <GuideJournalWindow /> },
                  { path: routes.guideDashboardBookingNewPath(), element: <GuideDashboardBookingWindow /> },
                  { path: routes.guideDashboardBookingEditPath(), element: <GuideDashboardBookingWindow /> },
                  { path: routes.guideDashboardUnavailableDayManagePath(), element: <GuideUnavailableDayWindow /> }
                ]
              },
              {
                path: routes.guideBookingsPath(),
                element: <GuideBookingsPage />,
                children: [
                  { path: routes.guideBookingEditPath(), element: <GuideBookingWindow /> },
                  { path: routes.guideBookingNewPath(), element: <GuideBookingWindow /> }
                ]
              },
              {
                path: routes.guideClientsPath(),
                element: <GuideClients />,
                children: [
                  { path: routes.guideClientNewPath(), element: <GuideClientWindow /> },
                  { path: routes.guideClientPath(), element: <GuideClientWindow /> },
                  { path: routes.guideClientEditPath(), element: <GuideClientWindow /> },
                  { path: routes.guideClientEmailActionPath(), element: <GuideClientActionWindow type="email" /> },
                  { path: routes.guideClientTextActionPath(), element: <GuideClientActionWindow type="text" /> },
                  { path: routes.guideClientMessageActionPath(), element: <GuideClientActionWindow type="message" /> }
                ]
              },
              { path: routes.guideTripsPath(), element: <GuideTrips /> },
              { path: routes.guideTripPath(), element: <Trip guideView /> },
              {
                path: routes.guideTripWizardRootPath(),
                element: <GuideTripWizardLayout />,
                children: [
                  { path: routes.guideTripWizardStep1Path(), element: <GuideTripWizardStep1 /> },
                  { path: routes.guideTripWizardStep2Path(), element: <GuideTripWizardStep2 /> },
                  {
                    path: routes.guideTripWizardStep3Path(),
                    element: <GuideTripWizardStep3 />,
                    children: [{ path: routes.guideTripWizardStep3AddNewBoatPath(), element: <BoatWindow /> }]
                  },
                  { path: routes.guideTripWizardStep4Path(), element: <GuideTripWizardStep4 /> },
                  { path: routes.guideTripWizardStep5Path(), element: <GuideTripWizardStep5 /> }
                ]
              },
              {
                path: routes.guideBoatsPath(),
                element: <Boats />,
                children: [
                  { path: routes.guideAddNewBoatPath(), element: <BoatWindow /> },
                  { path: routes.guideEditBoatPath(), element: <BoatWindow /> }
                ]
              },
              { path: routes.guideMessagesPath(), element: <MessagesPage /> },
              {
                path: routes.guideAccountingPath(),
                element: <GuideAccounting />,
                children: [
                  { path: routes.guideAccountingAddExpancePath(), element: <GuideAccountingExpenseWindow /> },
                  { path: routes.guideAccountingEditExpancePath(), element: <GuideAccountingExpenseWindow /> }
                ]
              },
              {
                element: <GuideMarketing />,
                children: [
                  {
                    path: routes.guideMarketingPath(),
                    element: <GuideFeeder />,
                    children: [
                      { path: routes.guideMarketingPostNewPath(), element: <GuidePostWindow /> },
                      { path: routes.guideMarketingPostEditPath(), element: <GuidePostWindow /> }
                    ]
                  },
                  {
                    path: routes.guideMarketingTemplatesPath(),
                    element: <GuideTemplates />,
                    children: [
                      {
                        path: routes.guideMarketingTemplatesParentsPath(),
                        element: <GuideTemplatesParents />
                      },
                      { path: routes.guideMarketingTemplateNewPath(), element: <GuideTemplate /> },
                      { path: routes.guideMarketingTemplateEditPath(), element: <GuideTemplate /> }
                    ]
                  }
                ]
              },

              {
                element: <GuideSettingsLayout />,
                children: [
                  { path: routes.guideSettingsRootPath(), element: <GuideSettingsDetails /> },
                  { path: routes.guideSettingsPhotosPath(), element: <GuideSettingsPhotos /> },
                  { path: routes.guideSettingsExtrasPath(), element: <GuideSettingsExtras /> },
                  { path: routes.guideSettingsDocumentsPath(), element: <GuideSettingsDocuments /> },
                  { path: routes.guideSettingsStripePath(), element: <GuideSettingsStripe /> },
                  { path: routes.guideSettingsSocialsPath(), element: <GuideSettingsSocials /> },
                  { path: routes.guideSettingsPasswordPath(), element: <GuideSettingsLoginAndSecurity /> },
                  { path: routes.guideSettingsNotificationsPath(), element: <GuideSettingsNotifications /> }
                ]
              },
              { path: routes.guideWeatherPath(), element: <GuideWeather /> },
              { path: routes.guideContactUsPath(), element: <GuideContactUs /> }
            ]
          },
          {
            path: routes.guideWizardRootPath(),
            element: <GuideWizardLayout />,
            children: [
              { path: routes.guideWizardStep1Path(), element: <GuideWizardStep1 /> },
              { path: routes.guideWizardStep2Path(), element: <GuideWizardStep2 /> },
              { path: routes.guideWizardStep3Path(), element: <GuideWizardStep3 /> },
              { path: routes.guideWizardStep4Path(), element: <GuideWizardStep4 /> }
            ]
          }
        ]
      }
    ]
  }
])
