import React from "react"
import { Button } from "reactstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Icon from "./Icon"

import { useDispatch, useSelector } from "react-redux"
import { setActiveSlide } from "store/lightbox"

export default function PreviewMedia({
  preview,
  placeholder,
  ratio = "",
  type,
  index,
  className = "",
  mediaClassName = "",
  actions,
  onClose,
  rounded = true,
  fluid = false
}) {
  const dispatch = useDispatch()
  const slides = useSelector((store) => store.lightbox.slides)

  const clickHandler = () => slides?.length && dispatch(setActiveSlide(index))

  const classes = ["position-relative"]
  if (className) classes.push(className)

  const buttonClasses = ["p-0 rounded"]
  if (fluid || ratio) buttonClasses.push("w-100")
  else buttonClasses.push("w-auto")
  if (ratio) buttonClasses.push(`ratio ratio-${ratio}`)

  const imageClasses = ["object-fit-cover h-100 w-100"]
  if (rounded) imageClasses.push("rounded")
  if (!fluid && !ratio) imageClasses.push("w-auto")
  if (mediaClassName) imageClasses.push(mediaClassName)

  return (
    <div className={classes.join(" ")}>
      {actions && <div className={`position-absolute top-0 end-0 p-2 hstack gap-10 ${onClose ? "pe-15" : ""}`}>{actions}</div>}
      {onClose && (
        <Button
          color="ghost"
          size="icon"
          className="position-absolute top-0 end-0 z-1 m-n1 p-0 text-white shadow bg-transparent-hover"
          onClick={onClose}
        >
          <Icon iconName="CloseFill" width={20} height={20} />
        </Button>
      )}

      <Button type="button" color="ghost" className={buttonClasses.join(" ")} onClick={clickHandler}>
        <LazyLoadImage src={preview} className={imageClasses.join(" ")} alt="" placeholderSrc={placeholder} effect="blur" />
        {type === "video" && (
          <div>
            <Icon iconName="Play" className="position-absolute top-50 start-50 translate-middle text-white" width={30} height={30} />
          </div>
        )}
      </Button>
    </div>
  )
}
