import React from "react"
import ForgotPasswordForm from "components/devise/ForgotPasswordForm"
import { useTitle } from "hooks/useTitle"
import { Link, useSearchParams } from "react-router-dom"
import { routes } from "router"
import Icon from "components/common/Icon"

export default function ForgotPassword() {
  useTitle("Forgot Password")
  const [searchParams] = useSearchParams()
  const loginUrl = searchParams.get("login_url") || routes.signInPath()

  return (
    <>
      <div className="fs-6 fw-medium mb-25">
        <div className="hstack d-inline-flex gap-1 small link-target">
          <Icon iconName="ArrowLeft" width={18} height={10} />
          Back to{" "}
          <Link to={loginUrl} className="text-primary-hover text-decoration-none">
            Login
          </Link>
        </div>
      </div>
      <h1 className="h2 lh-1">Forgot Password?</h1>
      <p className="h3 fw-normal lh-sm mt-15 mb-40">Enter the email associated with your account</p>

      <ForgotPasswordForm loginUrl={loginUrl} />

      {/* <div className="mt-20 fs-6 fw-medium lh-1">
        <div className="small">
          Don’t have an account?{" "}
          <Link className="text-primary-hover text-decoration-none" to={routes.signUpPath()}>
            Sign Up
          </Link>
        </div>
      </div> */}
    </>
  )
}
