import React, { useEffect, useState } from "react"
import { CloseButton, Offcanvas } from "react-bootstrap"
import { useNavigate, useSearchParams } from "react-router-dom"
import { routes } from "router"
import Form from "components/unavailables/Form"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { useTranslation } from "react-i18next"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"
// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanUnavailableDay } from "store/unavailable-days"

export default function UnavailableDay() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const confirmModal = useConfirmModal()
  const [searchParams] = useSearchParams()
  const headerHeight = useSelector((state) => state.window.headerHeight)
  const [isOpen, setOpen] = useState(false)
  const [isChanged, setChanged] = useState(false)
  const redirect = routes.guideDashboardPath({}, searchParams.delete("unavailable_id") || searchParams)

  const closeHandler = isChanged
    ? confirmModal(
        {
          title: "Are you sure you want to discard all changes?",
          color: "danger",
          submitText: t("global.discard"),
          cancelText: t("global.cancel")
        },
        () => setOpen(false)
      )
    : () => setOpen(false)

  const exitHandler = () => navigate(redirect)

  useEffect(() => {
    setOpen(true)
    return () => dispatch(cleanUnavailableDay())
    //eslint-disable-next-line
  }, [])

  return (
    <Offcanvas
      placement="end"
      scroll
      show={isOpen}
      onHide={closeHandler}
      onExited={exitHandler}
      container={document.getElementById("dashboard")}
      className="border-0 shadow"
      backdropClassName="bg-transparent"
      style={{
        top: headerHeight,
        width: 850
      }}
    >
      <CloseButton onClick={closeHandler} className="d-block d-md-none position-absolute" style={{ zIndex: 2000, top: "10px", right: "10px" }} />
      <Offcanvas.Body className="vstack bg-light p-20">
        <DefaultErrorBoundary>
          <Form onChanged={setChanged} redirect={redirect} />
        </DefaultErrorBoundary>
      </Offcanvas.Body>
    </Offcanvas>
  )
}
