import React, { memo, useEffect } from "react"
import { Label, Table } from "reactstrap"
import { Link } from "react-router-dom"
import { routes } from "router"
import FirstLoading from "modules/loader-watchers/FirstLoading"
import WithEmptyState from "components/common/WithEmptyState"
import { numberToCurrency } from "helpers/string"

import { useTranslation } from "react-i18next"
import { getMomentValue } from "modules/datepicker/helpers"

import { useDispatch, useSelector } from "react-redux"
import { getGuidePastBookingsForClient } from "store/bookings"
import { collectionAnySelector, collectionSelector } from "store/selectors"
import { firstLoadingSelector } from "modules/loader-watchers/selectors"
import { colorClasses } from "helpers/color"

import { STATUS_COLOR } from "components/bookings/constants"
import { DATE_SHORT_TEXT_FORMAT } from "modules/datepicker/constants"

const PastTrips = ({ clientId, className }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const pastTrips = useSelector(collectionSelector("bookings.past_trips"))
  const hasPastTrips = useSelector(collectionAnySelector("bookings.past_trips"))
  const firstLoading = useSelector(firstLoadingSelector("bookings.past_trips"))

  useEffect(() => {
    if (firstLoading && clientId) dispatch(getGuidePastBookingsForClient(clientId))
  }, [firstLoading]) //eslint-disable-line

  const classes = ["bg-white rounded p-20"]
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")}>
      <Label className="fs-6">{t("booking.labels.bookings")}</Label>
      <FirstLoading name="bookings.past_trips">
        <WithEmptyState
          name="past trip"
          hasItems={hasPastTrips}
          className="vstack gap-20"
          emptyProps={{ className: "p-0" }}
          fallbackText=""
        >
          <Table hover>
            <thead>
              <tr className="border-bottom border-gray-lightest">
                <th className="text-dark text-opacity-50 fw-normal ps-0" width="1">
                  Date
                </th>
                <th className="text-dark text-opacity-50 fw-normal">Trip</th>
                <th className="text-dark text-opacity-50 fw-normal"></th>
                <th className="text-dark text-opacity-50 fw-normal text-end">Total Amount</th>
                <th className="text-dark text-opacity-50 fw-normal pe-0 text-end">Paid Amount</th>
              </tr>
            </thead>
            <tbody>
              {pastTrips.map((booking) => (
                <tr key={booking.id} className="border-bottom border-gray-lightest fs-5 fw-normal">
                  <td className="text-dark text-opacity-75 ps-0 text-nowrap">
                    {getMomentValue(booking.booked_date).format(DATE_SHORT_TEXT_FORMAT) || "—"}
                  </td>
                  <td className="text-dark text-opacity-75">
                    {booking.trip_id && (
                      <Link to={routes.guideTripPath({ id: booking.trip_id })} className="link link-dark">
                        {booking.title || "—"}
                      </Link>
                    )}
                  </td>
                  <td className="text-dark text-opacity-75 pe-0">
                    <span
                      className={[
                        "d-inline-block fs-7 fw-semibold text-center bg-opacity-25 rounded-pill py-1 px-3",
                        colorClasses(booking.status, STATUS_COLOR)
                      ].join(" ")}
                      style={{ minWidth: 100 }}
                    >
                      {t(`booking.card.${booking.status}`) || "—"}
                    </span>
                  </td>
                  <td className="text-dark text-opacity-75 pe-0 text-end">{numberToCurrency(booking.current_amount || 0)}</td>
                  <td className="text-dark text-opacity-75 pe-0 text-end">{numberToCurrency(booking.paid_amount || 0)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </WithEmptyState>
      </FirstLoading>
    </div>
  )
}

export default memo(PastTrips)
