import React, { useRef } from "react"
import { Button, Col, FormGroup, Label, Row } from "reactstrap"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { routes } from "router"
import Input from "components/form/Input"
import InputError from "components/form/InputError"
import ReCAPTCHA from "react-google-recaptcha"
import Spinner from "components/common/Spinner"

import useForm from "hooks/useForm"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"
import { signUp } from "store/auth"

const RegisterForm = ({ role, loginUrl = routes.signInPath() }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading } = useSelector((state) => state.auth)
  const [searchParams] = useSearchParams()
  const captchaRef = useRef(null)
  const [user, changeHandler, submitHandler, submitCallback] = useForm({
    is_sportsman: true,
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    agreeCheck: false
  })

  submitCallback(() => {
    if (searchParams.get("invitation_token")) user.invitation_token = searchParams.get("invitation_token")
    if (!["user", "guide_user"].includes(role)) return console.error('Invalid user role attribute! Role has been "user" or "guide_user"')
    user.role = role
    user.redirect_url = loginUrl

    const token = captchaRef.current.getValue()
    captchaRef.current.reset()
    user.captcha_token = token

    dispatch(signUp(user)).then(() => navigate(routes.signUpRequestSentPath({}, { login_url: loginUrl })))
  })

  return (
    <>
      <form onSubmit={submitHandler}>
        <Row className="gap-y-20 gx-10">
          <Col xs={12} sm={12} md={12} xl={12}>
            <FormGroup switch className="hstack mb-0 p-0 ms-auto">
              <Input
                id="is_sportsman"
                name="is_sportsman"
                type="checkbox"
                checked={user.is_sportsman}
                onChange={changeHandler}
                className="m-0 me-10 theme-toggle"
              />
              <Label for="is_sportsman" className="mb-0">
                {user.is_sportsman ? t("sign_up.digital_sportsman") : t("sign_up.digital_sportswoman")}
              </Label>
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={12} xl={6}>
            <Label for="first_name">{t("sign_up.first_name")}</Label>
            <Input
              id="first_name"
              type="text"
              name="first_name"
              placeholder={t("sign_up.first_name")}
              value={user.first_name}
              onChange={changeHandler}
              withError
            />
          </Col>
          <Col xs={12} sm={6} md={12} xl={6}>
            <Label for="last_name">{t("sign_up.last_name")}</Label>
            <Input
              id="last_name"
              type="text"
              name="last_name"
              placeholder={t("sign_up.last_name")}
              value={user.last_name}
              onChange={changeHandler}
              withError
            />
          </Col>
          <Col xs={12}>
            <Label for="email">{t("sign_up.email")}</Label>
            <Input
              id="email"
              type="email"
              name="email"
              placeholder={t("sign_up.email")}
              value={user.email}
              onChange={changeHandler}
              withError
            />
          </Col>
          <Col xs={12} sm={6} md={12} xl={6}>
            <Label for="password">{t("sign_up.password")}</Label>
            <Input
              id="password"
              type="password"
              name="password"
              placeholder={t("sign_up.password")}
              value={user.password}
              onChange={changeHandler}
              withError
            />
          </Col>
          <Col xs={12} sm={6} md={12} xl={6}>
            <Label for="password_confirmation">{t("sign_up.confirm_password")}</Label>
            <Input
              id="password_confirmation"
              type="password"
              name="password_confirmation"
              placeholder={t("sign_up.confirm_password")}
              value={user.password_confirmation}
              onChange={changeHandler}
              withError
            />
          </Col>
        </Row>
        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} className="mt-30" />
        <InputError field="recaptcha" />
        <FormGroup className="fs-6 fw-medium mt-30 mb-0" check>
          <Input id="agreeCheck" name="agreeCheck" type="checkbox" checked={user.agreeCheck} onChange={changeHandler} />
          <Label check for="agreeCheck" className="small">
            {t("sign_up.agree_1")}
            <Link className="text-primary-hover text-decoration-none" to="/terms-and-conditions">
              Terms of Service
            </Link>
            {" & "}
            <Link className="text-primary-hover text-decoration-none" to="/privacy-policy">
              Privacy Policy
            </Link>
          </Label>
        </FormGroup>
        <Button color="primary" type="submit" className="mt-30 w-100" disabled={!user.agreeCheck}>
          {t("sign_up.register")}
        </Button>
        {loading && <Spinner className="bg-white bg-opacity-0 backdrop-blur-3 w-100 h-100 z-2" fixed />}
      </form>
    </>
  )
}

export default RegisterForm
