import { createAction, createReducer } from "@reduxjs/toolkit"
import { api } from "./helpers/api"
import { find, remove } from "lodash"
import { formatPaginationParams } from "helpers/pagination"

import { LOADING_INITIAL_STATE, setFailed, setReceived, setRequested } from "modules/loader-watchers/helpers/loading"

// ACTIONS
const requested = createAction("templates/requested")
const received = createAction("templates/received")
const failed = createAction("templates/failed")
const cleaned = createAction("templates/cleaned")

const requestedTemplate = createAction("templates/requestedTemplate")
const receivedTemplate = createAction("templates/receivedTemplate")
const failedTemplate = createAction("templates/failedTemplate")

const createdTemplate = createAction("templates/createdTemplate")
const updatedTemplate = createAction("templates/updatedTemplate")
const deletedTemplate = createAction("templates/deletedTemplate")
const cleanedTemplate = createAction("templates/cleanedTemplate")

// REDUCER
const initialState = {
  ...LOADING_INITIAL_STATE,
  items: [],
  metadata: {
    page: null,
    total_pages: null,
    total_results: null,
    next_page: null,
    prev_page: null,
    parents: [],
    social_media: {}
  },
  template: {
    ...LOADING_INITIAL_STATE,
    template_type: "email"
  }
}

const templatesReducer = createReducer(initialState, {
  [requested.type]: (state) => {
    setRequested(state)
  },
  [received.type]: (state, action) => {
    setReceived(state)
    state.items = action.payload.data
    Object.assign(state.metadata, action.payload.metadata)
  },
  [failed.type]: (state) => {
    setFailed(state)
  },
  [cleaned.type]: () => initialState,
  [requestedTemplate.type]: (state) => {
    setRequested(state.template)
  },
  [receivedTemplate.type]: (state, action) => {
    setReceived(state.template)
    Object.assign(state.template, action.payload)
  },
  [createdTemplate.type]: (state, action) => {
    setReceived(state.template)
    state.items = [...state.items, action.payload]
    Object.assign(state.template, action.payload)
  },
  [updatedTemplate.type]: (state, action) => {
    setReceived(state.template)
    const post = find(state.items, ["id", action.payload.id]) || {}
    Object.assign(post, action.payload)
    Object.assign(state.template, action.payload)
  },
  [deletedTemplate.type]: (state, action) => {
    setReceived(state.template)
    remove(state.items, ["id", action.payload.id])
  },
  [failedTemplate.type]: (state) => {
    setFailed(state.template)
  },
  [cleanedTemplate.type]: (state) => {
    state.template = initialState.template
  }
})
export default templatesReducer

const getItems = (url, nextPage, params = {}, dispatch, getState) => {
  const state = getState()
  const paginationParams = formatPaginationParams(state.templates.metadata, nextPage)

  return dispatch(
    api({
      url,
      onStart: requested.type,
      onSuccess: received.type,
      onError: failed.type,
      params: Object.assign({}, paginationParams, params),
      props: { reset: !nextPage }
    })
  )
}

const getItem = (url, id, dispatch, getState) => {
  const state = getState()
  const template = find(state.templates.items, ["id", +id])

  if (template) {
    dispatch(receivedTemplate(template))
    return new Promise((resolve) => resolve(template))
  }
  return dispatch(
    api({
      url,
      onStart: requestedTemplate.type,
      onSuccess: receivedTemplate.type,
      onError: failedTemplate.type
    })
  )
}

export const getGuideTemplates = (nextPage, params) => (dispatch, getState) =>
  getItems("/guide/email_templates", nextPage, params, dispatch, getState)

export const getGuideTemplate = (id) => (dispatch, getState) => getItem(`/guide/email_templates/${id}`, id, dispatch, getState)

export const setGuideTemplateFromParent = (id) => (dispatch, getState) => {
  const state = getState()
  const parentTemplate = find(state.templates.metadata?.parents || [], ["id", +id])

  if (parentTemplate) {
    dispatch(receivedTemplate(parentTemplate))
    return new Promise((resolve) => resolve(parentTemplate))
  }
  dispatch(failedTemplate())
  console.log(`Parent template not found: id=${id}`)
}

export const createTemplate = (data) =>
  api({
    url: "/guide/email_templates",
    method: "post",
    onStart: requestedTemplate.type,
    onSuccess: createdTemplate.type,
    onError: failedTemplate.type,
    data
  })

export const updateTemplate = (id, data) =>
  api({
    url: `/guide/email_templates/${id}`,
    method: "put",
    data: data,
    onStart: requestedTemplate.type,
    onSuccess: updatedTemplate.type,
    onError: failedTemplate.type
  })

export const saveTemplate = (id, data) => (id ? updateTemplate(id, data) : createTemplate(data))

export const removeTemplate = (id) =>
  api({
    url: `/guide/email_templates/${id}`,
    method: "delete",
    onStart: requestedTemplate.type,
    onSuccess: deletedTemplate.type,
    onError: failedTemplate.type
  })

export const cleanTemplates = () => (dispatch) => dispatch(cleaned())
export const cleanTemplate = () => (dispatch) => dispatch(cleanedTemplate())
