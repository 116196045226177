import React, { memo, useMemo } from "react"

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip } from "chart.js"
import { Bar } from "react-chartjs-2"

import { useSelector } from "react-redux"

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip)

const chartData = ({ labels, data_incomes, data_expenses }) => ({
  labels,
  datasets: [
    {
      label: "Incomes",
      data: data_incomes,
      backgroundColor: "#64BC1C",
      datalabels: { display: false }
    },
    {
      label: "Expenses",
      data: data_expenses,
      backgroundColor: "#0052B4",
      datalabels: { display: false }
    }
  ]
})

export const options = {
  devicePixelRatio: 2,
  responsive: true,
  maintainAspectRatio: false,
  layout: { padding: 0 },
  plugins: {
    legend: {
      position: "top",
      align: "end",
      fullSize: false,
      labels: {
        color: "#141414",
        font: { size: 15 },
        boxWidth: 10,
        boxHeight: 10,
        pointStyle: "circle",
        usePointStyle: true,
        padding: 25
      }
    }
  },
  interaction: {
    mode: "index",
    intersect: false
  },
  scales: {
    x: {
      grid: { display: false },
      time: { parset: "D MMM" },
      ticks: {
        align: "center",
        maxRotation: 0,
        color: "#141414",
        font: { size: 10 },
        padding: -1
      }
    },
    yAxes: {
      beginAtZero: true,
      ticks: {
        display: false,
        maxTicksLimit: 6
      },
      grid: {
        color: ["#B7B7B7", ...new Array(10).fill("#F2F2F2")],
        lineWidth: 1,
        drawBorder: false
      }
    }
  },
  datasets: {
    bar: {
      borderRadius: 3,
      barPercentage: 0.9
    }
  }
}

function TotalYearBarChart({ className }) {
  const { metadata } = useSelector((store) => store.accounting)
  const { stats_per_year } = metadata

  const rawData = useMemo(
    () =>
      Object.entries(stats_per_year).reduce(
        (data, [month, values]) => {
          data.labels.push(month)
          data.data_incomes.push(+values.incomes)
          data.data_expenses.push(+values.expenses)
          return data
        },
        { labels: [], data_incomes: [], data_expenses: [], backgroundColor: [], borderColor: [] }
      ),
    [stats_per_year]
  )
  const data = useMemo(() => chartData(rawData), [rawData])

  const classes = ["chart-container position-relative"]
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")} style={{ height: 240 }}>
      <Bar data={data} options={options} title="Chart" />
    </div>
  )
}

export default memo(TotalYearBarChart)
