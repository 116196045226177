import React, { memo } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { routes } from "router"

import moment from "moment"
import { useTranslation } from "react-i18next"

import { DATETIME_FORMAT, TIME_FORMAT } from "modules/datepicker/constants"

const UnavailableDay = ({
  unavailableDay,
  className,
  wholeDay,
  startDateIsCurrent,
  endDateIsCurrent,
  inTimeline = false,
  showOnHover = false,
  badge = false,
  top = 30
}) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const classes = []
  if (inTimeline)
    classes.push(
      "h-100 z-0",
      "vstack me-auto h-100 rounded-1 bg-dark border border-dark badge bg-opacity-25 bg-opacity-50-hover",
      "fs-7 fw-semibold text-dark text-start text-wrap text-decoration-none lh-1"
    )
  else
    classes.push(
      "hstack gap-1 badge rounded bg-dark bg-opacity-25 bg-opacity-100-hover",
      "fs-7 fw-semibold text-dark text-white-hover text-start text-wrap text-decoration-none lh-1 p-1"
    )
  if (showOnHover) classes.push("show-on-hover-target")
  if (className) classes.push(className)

  const titleClasses = []
  if (inTimeline) titleClasses.push("sticky-top sticky-bottom p-1 my-auto")
  const titleStyles = inTimeline ? { "--sticky-top": `${top}px`, "--sticky-bottom": "10px" } : {}

  searchParams.set("unavailable_id", unavailableDay.id)

  return (
    <Link to={routes.guideDashboardUnavailableDayManagePath({}, searchParams)} className={classes.join(" ")}>
      {badge && <div className="rounded p-1 bg-dark" />}
      <small className={titleClasses.join(" ")} style={titleStyles}>
        {t(`unavailable_day.types.${unavailableDay.unavailable_type}`)}
        {" | "}
        {((wholeDay || unavailableDay.all_day || unavailableDay.unavailable_type === "date") && "Whole day") ||
          (startDateIsCurrent && moment(unavailableDay.start_datetime, DATETIME_FORMAT).format(`${TIME_FORMAT}[ - ...]`)) ||
          (endDateIsCurrent && moment(unavailableDay.end_datetime, DATETIME_FORMAT).format(`[... - ]${TIME_FORMAT}`))}
      </small>
    </Link>
  )
}
export default memo(UnavailableDay)
