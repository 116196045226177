import React, { useEffect } from "react"
import { CloseButton, Offcanvas } from "react-bootstrap"
import Loading from "modules/loader-watchers/Loading"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { useOffcanvasHandlers } from "./hooks"

// Redux
import { useSelector } from "react-redux"

export default function OffcanvasWindow({ container, width = 850, top, exitRedirect, loadingName, children, ...rest }) {
  const headerHeight = useSelector((state) => state.window.headerHeight)
  const { isOpen, openHandler, closeHandler, exitHandler, setChanged } = useOffcanvasHandlers({ exitRedirect })

  useEffect(() => openHandler(), []) // eslint-disable-line

  return (
    <Offcanvas
      placement="end"
      className="border-0 shadow"
      backdropClassName="bg-transparent"
      {...rest}
      style={{
        ...rest.style,
        top: top?.(headerHeight) || top || headerHeight,
        width
      }}
      scroll
      show={isOpen}
      onHide={closeHandler}
      onExited={exitHandler}
      container={container}
    >
      <CloseButton onClick={closeHandler} className="d-block d-md-none position-absolute" style={{ zIndex: 2000, top: "10px", right: "10px" }} />
      <Loading
        name={loadingName}
        tag={Offcanvas.Body}
        className="vstack bg-light p-20 h-100 overflow-y-scroll"
        spinnerProps={{ className: "z-index-overlay" }}
      >
        <DefaultErrorBoundary>{children?.({ closeHandler, setChanged }) || children}</DefaultErrorBoundary>
      </Loading>
    </Offcanvas>
  )
}
