import { useState, useEffect, useCallback, useMemo, useRef } from "react"
import { toaster } from "components/common/Toast"

import { get, pick, has, set, unset, cloneDeep, isEqual, without } from "lodash"
import { hasEmptyFields } from "helpers/form"
import { parse } from "helpers/string"

import { useDispatch } from "react-redux"
import { resetErrors } from "modules/errors/reducer"

const NOT_EDITABLE_FIELDS = ["id", "_id", "_destroy"]

const getValue = (target) => {
  if (target.type === "checkbox") return target.checked
  if (target.type === "file") return target.files?.at(0)
  return parse(target.value, true)
}

const useForm = (object, accessFields = [], { force = false } = {}) => {
  const dispatch = useDispatch()
  const initialState = useMemo(() => (accessFields.length ? pick(object, accessFields) : object), [object, accessFields])
  const prevInputs = useRef(initialState)
  const [inputs, setInputs] = useState(prevInputs.current)
  const isChanged = useMemo(() => force || !isEqual(inputs, prevInputs.current), [force, inputs, prevInputs.current]) //eslint-disable-line

  let submitCallback
  const setCallback = (cb) => {
    if (typeof cb === "function") {
      submitCallback = cb
    }
  }

  const changeHandler = useCallback((event) => {
    if (event.persist) event.persist()

    setInputs((state) => {
      const newInputs = cloneDeep(state)
      const target = typeof event === "function" ? event(newInputs)?.target : event.target
      if (!target) return newInputs

      const value = getValue(target)
      const name = target.name

      if (value === null) unset(newInputs, name)
      else set(newInputs, name, value)

      return newInputs
    })
  }, [])

  const submitHandler = async (event) => {
    event && event.preventDefault()
    if (isChanged || force) {
      if (submitCallback) submitCallback(event, inputs)
    } else toaster.error("Form data is not changed!")
  }

  const reset = useCallback(() => {
    prevInputs.current = initialState
    setInputs(initialState)
    dispatch(resetErrors())
  }, [initialState]) // eslint-disable-line

  useEffect(() => {
    if (isEqual(initialState, prevInputs.current)) return
    prevInputs.current = initialState
    setInputs(initialState)
  }, [initialState]) // eslint-disable-line

  return [inputs, changeHandler, submitHandler, setCallback, reset, isChanged]
}

const getItemFromList = ({ id, _id }, list = []) => {
  const searchId = id || _id
  if (!searchId) return
  if (!Array.isArray(list)) return

  const itemIndex = list.findIndex((i) => [i.id, i._id].includes(searchId))
  if (itemIndex < 0) return

  return [list[itemIndex], itemIndex]
}

export const useNestedFields = (parent = {}, attribute = "", requiredFields = [], formChangeHandler = () => {}) => {
  const [item, setItem] = useState({})
  const nestedItems = get(parent, attribute)
  const isPersisted = item.id || item._id

  const changeHandler = (event) => {
    if (event.persist) event.persist()
    if (NOT_EDITABLE_FIELDS.includes(event.target.name)) return

    const value = getValue(event.target)
    setItem({ ...item, [event.target.name]: value })
  }

  const addHandler = (event, forceItem = null) => {
    if (event?.persist) event.persist()
    if (!Array.isArray(nestedItems)) return

    const addedItem = forceItem || item
    if (has(addedItem, NOT_EDITABLE_FIELDS)) return
    if (hasEmptyFields(addedItem, ...requiredFields)) return

    addedItem._id = new Date().getTime()

    formChangeHandler((form) => ({
      target: {
        name: `${attribute}[${get(form, attribute).length}]`,
        value: addedItem
      }
    }))
    if (!forceItem) setItem({})
  }

  const removeHandler = (item) => {
    const tempItem = !!item._id

    formChangeHandler((form) => {
      const list = get(form, attribute)
      const [itemInList, itemIndex] = getItemFromList(item, list)

      if (!itemInList) return
      return tempItem
        ? {
            target: {
              name: attribute,
              value: without(list, itemInList)
            }
          }
        : {
            target: {
              name: `${attribute}[${itemIndex}]`,
              value: { ...itemInList, _destroy: 1 }
            }
          }
    })
  }

  const editHandler = (item) => {
    const [editItem] = getItemFromList(item, nestedItems)
    if (editItem) setItem(editItem)
  }

  const updateHandler = (event, forceItem = null) => {
    if (event?.persist) event.persist()
    if (forceItem === null && !isPersisted) return

    const updatedItem = forceItem || item

    formChangeHandler((form) => {
      const [itemInList, itemIndex] = getItemFromList(updatedItem, get(form, attribute))
      if (!itemInList) return
      if (hasEmptyFields(updatedItem, ...requiredFields)) return
      return {
        target: {
          name: `${attribute}[${itemIndex}]`,
          value: updatedItem
        }
      }
    })
    if (!forceItem) setItem({})
  }

  return [item, changeHandler, addHandler, removeHandler, editHandler, updateHandler, isPersisted]
}

export const cleanNestedAttributes = (attributes = [], acceptedFields = []) =>
  attributes.map((fields = {}) => ({
    ...(fields.id && { id: fields.id }),
    ...pick(fields, acceptedFields),
    ...(fields._destroy && { _destroy: fields._destroy })
  }))

export default useForm
