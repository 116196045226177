import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import ErrorBoundaryFallback from "./ErrorBoundaryFallback"

const DefaultErrorBoundary = (props) => (
  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} {...props}>
    {props.children}
  </ErrorBoundary>
)

export default DefaultErrorBoundary
