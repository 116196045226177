import React, { memo } from "react"
import { Label } from "reactstrap"
import Input from "components/form/Input"

import { useTranslation } from "react-i18next"

const Client = ({ form, changeHandler }) => {
  const { t } = useTranslation()

  return (
    <div className="grid grid-cols-2 bg-white rounded p-20">
      <div>
        <Label for="first_name" className="fs-6">
          {t("client.first_name")}
        </Label>
        <Input
          id="first_name"
          type="text"
          name="client.first_name"
          value={form.client.first_name || ""}
          onChange={changeHandler}
          className="fs-6"
          withError
        />
      </div>
      <div>
        <Label for="last_name" className="fs-6">
          {t("client.last_name")}
        </Label>
        <Input
          id="last_name"
          type="text"
          name="client.last_name"
          value={form.client.last_name || ""}
          onChange={changeHandler}
          className="fs-6"
          withError
        />
      </div>
      <div>
        <Label for="email" className="fs-6">
          {t("client.email")}
        </Label>
        <Input
          id="email"
          type="email"
          name="client.email"
          value={form.client.email || ""}
          onChange={changeHandler}
          className="fs-6"
          withError
        />
      </div>
      <div>
        <Label for="phone_number" className="fs-6">
          {t("client.phone_number")}
        </Label>
        <Input
          id="phone_number"
          type="tel"
          name="client.phone_number"
          value={form.client.phone_number || ""}
          onChange={changeHandler}
          className="fs-6"
          withError
        />
      </div>
    </div>
  )
}

export default memo(Client)
