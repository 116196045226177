import React, { memo } from "react"
import { routes } from "router"
import { Link } from "react-router-dom"

import { bookingDateIsDisabled } from "components/bookings/helpers"

import { useSelector } from "react-redux"
import { collectionSelector } from "store/selectors"
import { isGuideSelector } from "store/user"

import { DATE_FORMAT } from "modules/datepicker/constants"

const CalendarDay = ({ day, today, thisMonth = true }) => {
  const isToday = today.format(DATE_FORMAT) === day.format(DATE_FORMAT)

  const isGuide = useSelector(isGuideSelector)
  const trips = useSelector(collectionSelector("trips"))

  const filteredTrips = trips.filter((trip) => !bookingDateIsDisabled(global.dateTime(day.toDate()), trip))

  const classes = ["vstack border-end border-bottom border-gray-lightest p-2"]
  if (!thisMonth) classes.push("bg-light")

  const numberClasses = ["rounded-circle px-1 m-n1 me-auto fs-7 fw-medium text-center mw-100"]
  if (isToday) numberClasses.push("bg-dark text-white")
  if (!thisMonth) numberClasses.push("opacity-50")

  return (
    <div className={classes.join(" ")} style={{ minHeight: 130 }}>
      <div className={numberClasses.join(" ")} style={{ width: 30, lineHeight: "30px" }}>
        {day.format("DD")}
      </div>
      {filteredTrips.length ? (
        <div className="vstack gap-1 justify-content-end mt-2">
          {filteredTrips.map((trip) => (
            <Link
              key={trip.id}
              to={isGuide ? routes.guideTripPath({ id: trip.id }) : routes.tripPath({ id: trip.id })}
              className="badge rounded bg-primary bg-opacity-25 bg-opacity-100-hover fs-7 fw-semibold text-primary text-white-hover text-start text-wrap text-decoration-none lh-1 py-1 px-3 me-auto"
              {...(!isGuide && { state: { date: day.format(DATE_FORMAT) } })}
            >
              {trip.title}
            </Link>
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default memo(CalendarDay)
