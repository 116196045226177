import React, { memo } from "react"
import Logo from "./Logo"
import { useSelector } from "react-redux"

function ImagePlaceholder({ label = "Image not found", className }) {
  const classes = ["vstack align-items-center justify-content-center bg-gray-lightest text-gray-light"]
  className && classes.push(className)
  const isSportsman = useSelector((state) => state.user.is_sportsman)

  return (
    <div className={classes.join(" ")}>
      {isSportsman ? (
        <Logo className="opacity-50" alt="Logo" width={80} height={80} style={{ filter: "grayscale(1)" }} />
      ) : (
        <Logo className="opacity-50" alt="Logo" width={80} height={80} style={{ filter: "grayscale(1)" }} is_sportswoman />
      )}

      <p className="fs-7 lh-1 mt-4 mb-0">{label}</p>
    </div>
  )
}

export default memo(ImagePlaceholder)
