import React from "react"
import Icon from "components/common/Icon"
import Avatar from "components/common/Avatar"

const Review = ({ review, className, ...rest }) => {
  const { body, rating, author } = review

  const classes = ["vstack bg-white border border-primary-second rounded overflow-hidden p-10 w-100"]
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")} {...rest}>
      <p className="fs-6">{body}</p>
      <div className="hstack gap-1 mt-auto">
        {Array(5)
          .fill(null)
          .map((_, index) => (
            <div key={index} className={+rating >= index + 1 ? "text-primary-second" : "text-gray-light"}>
              <Icon iconName="StarNew" size={14} block />
            </div>
          ))}
      </div>
      <hr className="my-10" />
      <div className="hstack gap-10 justify-content-between">
        <h3 className="fs-6">
          {author.first_name} {author.last_name}
        </h3>
        <Avatar user={author} size={26} />
      </div>
    </div>
  )
}

export default Review
