import React, { useEffect } from "react"
import { Button, Label } from "reactstrap"
import { useNavigate, useSearchParams } from "react-router-dom"
import { routes } from "router"
import Input from "components/form/Input"
import DatepickerInput from "components/form/DatepickerInput"
import Spinner from "components/common/Spinner"

import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"
import useForm from "hooks/useForm"

import { useDispatch, useSelector } from "react-redux"
import { deleteGuideJournal, saveJournal } from "store/dashboard"

const Form = ({ onChanged, edit = false }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const confirmModal = useConfirmModal()
  const [searchParams] = useSearchParams()

  const { journal_note, metadata } = useSelector((store) => store.dashboard)
  const { trip_locations } = metadata
  const isJournalPersisted = !!journal_note?.id
  const journalDate = journal_note.date || searchParams.get("journal_date") || ""

  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm({ ...journal_note, date: journalDate }, [
    "title",
    "body",
    "date",
    "location"
  ])

  const deleteHandler = confirmModal(
    { title: "Are you sure you want to delete this Journal?", color: "danger", submitText: t("global.delete") },
    ({ currentTarget }) => {
      dispatch(deleteGuideJournal(currentTarget.value)).then(() => {
        searchParams.delete("journal_date")
        navigate(routes.guideDashboardPath({}, searchParams))
      })
    }
  )

  submitCallback(() => {
    const formData = serialize({ journal_note: form })
    dispatch(saveJournal(journal_note.id, formData)).then(() => {
      searchParams.delete("journal_date")
      navigate(routes.guideDashboardPath({}, searchParams))
    })
  })

  useEffect(() => {
    if (typeof onChanged === "function") onChanged(isChanged)
  }, [isChanged, onChanged])

  return edit && journal_note.loading ? (
    <Spinner className="flex-fill" />
  ) : (
    <form onSubmit={submitHandler}>
      <div className="vstack gap-20">
        <div className="hstack align-items-center gap-20 bg-white rounded p-20 sticky-top">
          <h1 className="h3 fw-medium lh-1">{t(`journal_note.${isJournalPersisted ? "edit" : "add"}`)}</h1>
          <div className="hstack gap-10 my-n1 ms-auto">
            <Button color="primary" className="fs-7" type={"submit"} disabled={!isChanged}>
              {t("global.save")}
            </Button>
          </div>
        </div>
        <div className="vstack gap-20 bg-white rounded p-20 ">
          <div className="hstack gap-20">
            <div className="flex-fill">
              <Label for="title" className="fs-6">
                {t("journal_note.labels.title")}
              </Label>
              <Input
                id="title"
                type="text"
                name="title"
                placeholder={t("journal_note.placeholders.title")}
                value={form.title || ""}
                onChange={changeHandler}
                className="fs-6"
                withError
              />
            </div>
            <div>
              <DatepickerInput
                type="date"
                name="date"
                label={t("journal_note.labels.date")}
                placeholder={t("journal_note.labels.date")}
                value={form.date}
                onChange={changeHandler}
                labelClassName="fs-6"
                inputClassName="fs-6"
                disabled={!!journalDate}
                noIcon
              />
            </div>
          </div>
          <div>
            <Label for="body" className="fs-6">
              {t("journal_note.labels.body")}
            </Label>
            <Input
              id="body"
              type="textarea"
              name="body"
              value={form.body || ""}
              onChange={changeHandler}
              className="fs-6 lh-base"
              withError
              rows={16}
            />
          </div>
          <div>
            <Label for="location" className="fs-6">
              {t("journal_note.labels.location")}
            </Label>
            <Input
              id="location"
              type="select"
              name="location"
              placeholder={t("journal_note.placeholders.location")}
              value={form.location || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            >
              <option>{t("journal_note.placeholders.location")}</option>
              {trip_locations.map((location) => (
                <option key={location} value={location}>
                  {location}
                </option>
              ))}
            </Input>
          </div>
        </div>
        {journal_note.id && (
          <div className="vstack bg-white rounded p-20">
            <button type="button" className="link link-danger fs-6 fw-medium ms-auto" value={journal_note.id} onClick={deleteHandler}>
              {t("global.delete")}
            </button>
          </div>
        )}
      </div>
    </form>
  )
}

export default Form
