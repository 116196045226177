import React, { memo, useMemo } from "react"
import Times from "./Times"
import DayWithTime from "./DayWithTime"
import Spinner from "components/common/Spinner"

import moment from "moment"

import { useSelector } from "react-redux"

const CalendarDay = ({ date, className }) => {
  const { loading } = useSelector((store) => store.dashboard)

  const today = moment()
  const day = useMemo(() => moment(date), [date])

  const classes = ["grid grid-cols-1 gap-0 position-relative"]
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")} style={{ gridTemplateColumns: "auto repeat(var(--bs-columns, 12), minmax(0,1fr))" }}>
      <Times day={day} />
      <DayWithTime key={day.dayOfYear()} day={day} today={today} first last />
      {loading && <Spinner className="bg-light bg-opacity-75 w-100 h-100" absolute />}
    </div>
  )
}
export default memo(CalendarDay)
