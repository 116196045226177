import React, { memo } from "react"
import Spinner from "components/common/Spinner"

import { useSelector } from "react-redux"
import { firstLoadingSelector } from "./selectors"

function FirstLoading({ loading, name = "user", new: newRecord, children, ...rest }) {
  const firstLoading = useSelector(firstLoadingSelector(name))
  // console.log(`firstLoading ${name}`, firstLoading)

  return !newRecord && (loading || firstLoading) ? <Spinner {...rest} /> : children
}

export default memo(FirstLoading)
