import React, { memo } from "react"
import { routes } from "router"
import SidebarStepLink from "components/wizards/StepLink"

import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useMedia } from "modules/window/hooks/useMedia"

function Navigation({ name, stepsKey, showAccountLink = true }) {
  const { t } = useTranslation()
  const toXs = useMedia({ to: "xs" })
  const toXsm = useMedia({ to: "xsm" })
  const toSm = useMedia({ to: "sm" })
  const toMd = useMedia({ to: "md" })
  const toLg = useMedia({ to: "lg" })
  const { loggedIn } = useSelector((store) => store.auth)
  const gap = loggedIn
    ? (toXs && 50) || (toXsm && 70) || (toSm && 100) || (toMd && 160) || (toLg && 90) || 150
    : (toXs && 50) || (toXsm && 70) || (toSm && 70) || (toMd && 110) || (toLg && 60) || 100

  return (
    <div className={["flex-shrink-0 d-flex min-h-100 px-10"].join(" ")} style={{ "--gap": `${gap}px`, gap: "var(--gap)" }}>
      {!loggedIn && showAccountLink && (
        <SidebarStepLink
          to={routes.bookingWizardPath()}
          stepNumber={"●"}
          label={t("booking.wizard.account.label")}
          horizontal
          disabled={false}
          end
        />
      )}
      <SidebarStepLink stepNumber={1} name={name} stepsKey={stepsKey} label={t("booking.wizard.step_1.label")} horizontal />
      <SidebarStepLink stepNumber={2} name={name} stepsKey={stepsKey} label={t("booking.wizard.step_2.label")} horizontal />
      <SidebarStepLink stepNumber={3} name={name} stepsKey={stepsKey} label={t("booking.wizard.step_3.label")} horizontal />
      <SidebarStepLink stepNumber={4} name={name} stepsKey={stepsKey} label={t("booking.wizard.step_4.label")} horizontal />
    </div>
  )
}

export default memo(Navigation)
