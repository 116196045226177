import React, { useState } from "react"
import { Navigate, useSearchParams } from "react-router-dom"
import { Button, Label } from "reactstrap"
import Input from "components/form/Input"

import { useTranslation } from "react-i18next"
import useForm from "hooks/useForm"

import { useDispatch, useSelector } from "react-redux"
import { resetPassword } from "store/password"
import { routes } from "router"
import Spinner from "components/common/Spinner"

export default function ResetPassword() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const { loading, success } = useSelector((state) => state.password)
  const [formSent, setFormSent] = useState(false)
  const [data, changeHandler, submitHandler, submitCallback] = useForm({
    password: "",
    password_confirmation: ""
  })
  const resetToken = searchParams.get("reset_password_token")

  submitCallback(() => {
    data.reset_password_token = resetToken
    setFormSent(true)
    dispatch(resetPassword(data))
  })

  if (!resetToken) return <Navigate to={routes.rootPath()} replace />
  if (formSent && success) return <Navigate to={routes.resetPasswordSuccessPath()} replace />

  return (
    <form onSubmit={submitHandler}>
      <div>
        <Label for="password">{t("reset_password.new_password")}</Label>
        <Input
          id="password"
          type="password"
          name="password"
          placeholder={t("reset_password.new_password")}
          value={data.password}
          onChange={changeHandler}
          withError
        />
      </div>
      <div className="mt-20">
        <Label for="password_confirmation">{t("reset_password.confirm_new_password")}</Label>
        <Input
          id="password_confirmation"
          type="password"
          name="password_confirmation"
          placeholder={t("reset_password.confirm_new_password")}
          value={data.password_confirmation}
          onChange={changeHandler}
          withError
        />
      </div>
      <Button color="primary" type="submit" className="mt-30 w-100">
        {t("reset_password.button")}
      </Button>
      {loading && <Spinner className="bg-white bg-opacity-0 backdrop-blur-3 w-100 h-100 z-2" fixed />}
    </form>
  )
}
