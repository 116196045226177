import React, { useRef } from "react"
import { Container, Button } from "reactstrap"
import { NavLink, useMatch, Link } from "react-router-dom"
import { routes } from "router"
import UserMenu from "components/common/UserMenu"
import GuideNavigation from "./GuideNavigation"
import TripFiltersNavigation from "./TripFiltersNavigation"
import SearchTrips from "./SearchTrips"
import withDefaultErrorBoundary from "modules/errors/HOCs/withDefaultErrorBoundary"
import Logo from "components/common/Logo"
import { FormGroup } from "reactstrap"
import Input from "components/form/Input"
import { updateUser } from "store/user"
 
import useEvent from "hooks/useEvent"
// Redux
import { useDispatch, useSelector } from "react-redux"
import { updateWindowSize } from "modules/window/reducer"
import { isGuideSelector, isUserSelector } from "store/user"
 
function Header({ noPadding = false, fluid = false, withNavigation = false, withShadow: shadow = false, className }) {
  const dispatch = useDispatch()
  const headerRef = useRef(null)
  const headerHeight = useSelector((store) => store.window)
  const isGuide = useSelector(isGuideSelector)
  const isUser = useSelector(isUserSelector)
  const isSportsman = useSelector((state) => state.user.is_sportsman)
  const { loggedIn } = useSelector((store) => store.auth)
 
  const isTripsPage = !!useMatch(routes.tripsPath())
  const rootPath = (isGuide && routes.guideRootPath()) || (isUser && routes.clientRootPath()) || routes.rootPath()
 
  const showGuideNavigation = withNavigation && isGuide
  const showTripFiltersNavigation = isTripsPage && !isGuide
  const withShadow = shadow || showGuideNavigation || showTripFiltersNavigation
  const title = isGuide && "Guide Portal"
 
  const setHeight = () => {
    if (!headerRef.current) return
    const height = headerRef.current.clientHeight
    const isHeightChanged = headerHeight !== height
    if (!isHeightChanged) return
 
    document.body.style.setProperty("--header-height", `${height}px`)
    dispatch(updateWindowSize({ headerHeight: height }))
  }
 
  useEvent("load", setHeight, true, window, true, true)
  useEvent("resize", setHeight, true, window)
 
  const classes = ["bg-white py-10 py-sm-15 position-relative z-1"]
  if (withShadow) classes.push("shadow")
  if (className) classes.push(className)
 
  const handleThemeChange = () => {
    dispatch(updateUser({ is_sportsman: !isSportsman }))
  }
 
  const scrollToRequestDemo = () => {
    const demoRequestEl = document.querySelector("#demo-request")
    if (demoRequestEl) demoRequestEl.scrollIntoView({ behavior: "smooth" })
  }
 
  return (
    <div className="sticky-top" ref={headerRef} style={{ ...(isTripsPage && { maxHeight: 140 }) }}>
      <header className={classes.join(" ")}>
        <Container className={noPadding ? "p-0" : ""} fluid={fluid || isTripsPage}>
          <div className="hstack gap-10 justify-content-between flex-wrap">
            <div className="d-flex gap-50 align-items-center">
              <NavLink
                className="d-block rounded-1 bg-gray-light-hover bg-opacity-25-hover text-dark p-2 m-n2"
                to={rootPath}
                state={{ scrollToTop: true }}
                title="Home"
                size={32}
              >
                {isSportsman ? (
                  <Logo alt="Logo" width={35} height={35} wide />
                ) : (
                  <Logo alt="Logo" width={35} height={35} is_sportswoman wide />
                )}
              </NavLink>
              {!loggedIn && (
                <>
                  <NavLink
                    className="text-dark text-decoration-none opacity-50-hover fw-medium d-none d-md-block"
                    style={{ fontFamily: "Epilogue" }}
                    to={routes.becomeAGuidePath()}
                  >
                    Become a Guide
                  </NavLink>
                  <NavLink
                    className="text-dark text-decoration-none opacity-50-hover fw-medium d-none d-md-block"
                    style={{ fontFamily: "Epilogue" }}
                    onClick={scrollToRequestDemo}
                  >
                    See a Demo
                  </NavLink>
                </>
              )}
            </div>
            {!loggedIn && (
              <Button tag={Link} to={routes.signInPath()} color="primary-second d-none d-md-block" style={{ padding: "10px 38px" }}>
                Log In
              </Button>
            )}
            {title && <p className="h4 d-none d-md-block">{title}</p>}
            {!loggedIn && <UserMenu className="d-block d-md-none" />}
            
            {loggedIn && !isGuide && <SearchTrips className="order-2 order-md-1 flex-grow-1 flex-md-grow-0" />}
            {loggedIn && (
              <div className="order-1 ">
                <div className="d-flex align-items-center">
                  <FormGroup switch className="mb-0 p-0 theme-switch min-h-100">
                    <Input
                      id="is_sportsman"
                      name="is_sportsman"
                      type="checkbox"
                      checked={isSportsman}
                      onChange={handleThemeChange}
                      className="m-0 me-10 theme-toggle"
                    />
                  </FormGroup>
                  <UserMenu className="order-1" />
                </div>
              </div>
            )}
          </div>
        </Container>
      </header>
      {showGuideNavigation ? <GuideNavigation /> : null}
      {showTripFiltersNavigation ? <TripFiltersNavigation /> : null}
    </div>
  )
}
 
export default withDefaultErrorBoundary(Header)