import React, { useEffect, useState } from "react"
import { Container, Row } from "reactstrap"
import Loading from "modules/loader-watchers/Loading"
 
 
export default function DemoRequest() {
  const [HBLoading, setHBLoading] = useState(false)
 
  const mountHubSpotForm = () => {
    window.hbspt.forms.create({
      region: "na1",
      portalId: "44114474",
      formId: "ecac8fa3-9815-440b-a545-bca7055746af",
      target: ".hbspt-demo-request-form",
      onFormReady: () => setHBLoading(false)
    })
  }
 
  useEffect(() => {
    setHBLoading(true)
    const script = document.createElement("script")
 
    script.src = "//js.hsforms.net/forms/embed/v2.js"
    script.addEventListener("load", mountHubSpotForm)
    document.body.appendChild(script)
 
    return () => {
      script.removeEventListener("load", mountHubSpotForm)
      document.body.removeChild(script)
    }
  }, []) //eslint-disable-line
 
  return (
    <div id="demo-request" style={{background: "black"}}>
      <Container>
        <Container className="vstack text-center my-100 align-items-center pt-3" width="100" id="demo-request" >
          <h1 className="lh-sm fw-medium mb-25" style={{color: "white"}}>Request a Demo Today!</h1>
          <p className="w-100 w-md-75 w-xl-50" style={{color: "white"}}>
            Discover how Digital Sportsman can transform your guiding business.
            <br /> Sign up for a personalized demo to explore our powerful features and learn how we can help you maximize revenue, streamline
            operations, and enhance client experiences.
          </p>
          <Loading loading={HBLoading} className="h-100 w-100 mx-5" style={{}}>
            <Row className="px-0 px-lg-100">
              <div className="hbspt-demo-request-form px-5 py-1 bg-white mb-5 d-flex justify-content-center align-items-center" style={{ minHeight: "128px", borderRadius: "20px"}} />
            </Row>
          </Loading>
        </Container>
      </Container>
    </div>
  )
}