import React, { memo } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Label } from "reactstrap"
import { routes } from "router"
import PhotoSelector from "components/form/PhotoSelector"
import Input from "components/form/Input"

import { useTranslation } from "react-i18next"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"

// Redux
import { useDispatch } from "react-redux"
import { removeTemplate } from "store/templates"
import { showModal } from "modules/modals/reducer"

function TemplateForm({ form, changeHandler, template, isEdit }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const confirmModal = useConfirmModal()

  const withPhoto = ["small_gallery", "large_gallery"].includes(template.category)
  const isEmail = form.template_type === "email"
  const isSmallGallery = template.category === "small_gallery"

  const openTextGenerator = () => dispatch(showModal("textGeneratorOffcanvas"))

  const removeTemplateHandler = confirmModal(
    {
      title: "Are you sure?",
      color: "danger",
      submitText: t("global.confirm"),
      cancelText: t("global.cancel")
    },
    () => dispatch(removeTemplate(template.id)).then(() => navigate(routes.guideMarketingTemplatesPath()))
  )

  return (
    <div className="vstack gap-30 bg-white p-20 rounded">
      <div className="hstack px-3">
        <Button
          color="white"
          className={[
            "px-30 py-2 fs-7 text-primary-second border-primary-second mx-n3",
            isEmail && "bg-primary-second text-white z-1"
          ].join(" ")}
          onClick={(value) => changeHandler(value)}
          value="email"
          name="template_type"
        >
          {t("marketing.labels.email")}
        </Button>
        <Button
          color="white"
          className={[
            "px-30 py-2 fs-7 text-primary-second border-primary-second mx-n3",
            !isEmail && "bg-primary-second text-white z-1"
          ].join(" ")}
          onClick={(value) => changeHandler(value)}
          value="text"
          name="template_type"
        >
          {t("marketing.labels.text")}
        </Button>
      </div>
      {isEmail && (
        <div>
          <Label for="subject" className="fs-6">
            {t("marketing.labels.subject")}
          </Label>
          <Input id="subject" type="text" name="subject" value={form.subject || ""} onChange={changeHandler} className="fs-6" withError />
        </div>
      )}
      {template?.category !== "first_basic" && (
        <div>
          <Label for="heading" className="fs-6">
            {t("marketing.labels.heading")}
          </Label>
          <Input id="heading" type="text" name="heading" value={form.heading || ""} onChange={changeHandler} className="fs-6" withError />
        </div>
      )}
      <div>
        <div className="hstack gap-10 justify-content-between mb-2">
          <Label for="paragraph_text" className="fs-6 m-0">
            {t("marketing.labels.paragraph_text")}
          </Label>
          <Button color="primary-second" className="px-15 py-1 fs-7 fw-medium flex-shrink-0" onClick={openTextGenerator}>
            {t("text_generator.generate")}
          </Button>
        </div>
        <Input
          id="paragraph_text"
          type="textarea"
          name="paragraph_text"
          rows={10}
          value={form.paragraph_text || ""}
          onChange={changeHandler}
          className="fs-6"
          withError
        />
      </div>
      {withPhoto && isEmail && (
        <div>
          <Label className="fs-6">{t("marketing.labels.image", { max: isSmallGallery ? 2 : 4 })}</Label>
          <PhotoSelector form={form} changeHandler={changeHandler} max={isSmallGallery ? 2 : 4} />
        </div>
      )}
      {template?.category !== "first_basic" && (
        <>
          {isEmail && (
            <div>
              <Label for="button_text" className="fs-6">
                {t("marketing.labels.button_text")}
              </Label>
              <Input
                id="button_text"
                type="text"
                name="button_text"
                value={form.button_text || ""}
                onChange={changeHandler}
                className="fs-6"
                withError
              />
            </div>
          )}
          <div>
            <Label for="button_url" className="fs-6">
              {isEmail ? t("marketing.labels.button_link") : t("marketing.labels.text_link")}
            </Label>
            <Input
              id="button_url"
              type="text"
              name="button_url"
              value={form.button_url || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
        </>
      )}
      <div>
        <Label for="signature" className="fs-6">
          {t("marketing.labels.signature_text")}
        </Label>
        <Input
          id="signature"
          type="text"
          name="signature"
          value={form.signature || ""}
          onChange={changeHandler}
          className="fs-6"
          withError
        />
      </div>
      {isEdit && (
        <Button color="danger" className="fs-7 bg-white text-danger me-auto" onClick={removeTemplateHandler}>
          {t("marketing.remove_template")}
        </Button>
      )}
    </div>
  )
}

export default memo(TemplateForm)
