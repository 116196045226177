import React, { useEffect } from "react"
import { Link, Outlet, useNavigate } from "react-router-dom"
import { routes } from "router"
import { Label } from "reactstrap"
import Input from "components/form/Input"
import ActionButtons from "components/wizards/ActionButtons"
import FirstLoading from "modules/loader-watchers/FirstLoading"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"
import Icon from "components/common/Icon"

import useForm from "hooks/useForm"
import { useWizardSteps } from "hooks/wizard"
import { serialize } from "object-to-formdata"

import { useDispatch, useSelector } from "react-redux"
import { updateTrip } from "store/trips"
import { getGuideBoats } from "store/boats"
import { collectionSelector, modelSelector } from "store/selectors"
import { firstLoadingSelector } from "modules/loader-watchers/selectors"

const Step3Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const updateStep = useWizardSteps({ name: "trips.trip" })
  const trip = useSelector(modelSelector("trip"))
  const boats = useSelector(collectionSelector("boats"))
  const firstLoading = useSelector(firstLoadingSelector("boats"))

  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm({ ...trip }, ["boat_id"])

  useEffect(() => {
    if (firstLoading) dispatch(getGuideBoats())
  }, [firstLoading]) //eslint-disable-line

  submitCallback(({ nativeEvent }) => {
    const nextPath = nativeEvent?.submitter?.value

    const tripAttributes = { ...updateStep(form) }
    const formData = serialize({ trip: tripAttributes })

    dispatch(updateTrip(trip.id, formData)).then(() => navigate(nextPath))
  })

  return (
    <>
      <form onSubmit={submitHandler} className="vstack gap-30">
        <FirstLoading name="boats">
          <div className="vstack gap-10">
            {boats.map((boat) => {
              const checked = +form.boat_id === +boat.id
              return (
                <Label
                  key={boat.id}
                  check
                  className={`hstack gap-10 fs-7 fw-normal rounded px-15 py-3 m-0 shadow bg-white ${
                    checked ? " border border-primary-second" : ""
                  }`}
                >
                  <Input type="radio" name="boat_id" value={boat.id} checked={checked} onChange={changeHandler} className="mt-0" />
                  <span className={`text-dark ${checked ? "" : "text-opacity-50"}`}>{boat.name}</span>
                </Label>
              )
            })}
            <Link
              to={routes.guideTripWizardStep3AddNewBoatPath({ id: trip.id })}
              className="hstack gap-3 link link-dark fw-medium mt-10 me-auto"
            >
              <div className="bg-white border border-gray-lightest align-self-center rounded-circle shadow-sm">
                <Icon iconName="Add" className="d-block" />
              </div>
              Add a new boat
            </Link>
          </div>
        </FirstLoading>
        <ActionButtons
          isChanged={isChanged}
          action={updateTrip}
          withSkip
          name="trips.trip"
          className="mt-20"
          showSaveAndExit={trip.wizard_completed}
          exitPath={routes.guideTripsPath()}
          nextText="Save & Next"
          saveText="Save & Exit"
        />
      </form>
      <DefaultErrorBoundary>
        <Outlet />
      </DefaultErrorBoundary>
    </>
  )
}

export default Step3Form
