import React from "react"
import { Col, Row } from "reactstrap"

import PhotoSlider from "components/common/PhotoSlider"
import { useTranslation } from "react-i18next"

export default function Preview({ boat, isOdd = false }) {
  const { name, description, custom_info, properties: photos, safety } = boat
  const { t } = useTranslation()

  return (
    <article className="flex-fill rounded py-20 my-15">
      <Row className={`d-flex ${isOdd ? "flex-row-reverse" : "flex-row"}`}>
        <Col xs={12} md={12} lg={6} className="order-2 order-lg-1 mt-sm-2 mt-md-3 mb-sm-1 mb-md-2">
          <h3 className="h3 fw-medium lh-sm text-start me-auto mt-3 mb-2">{name}</h3>
          <p className="text-prewrap">{description}</p>
          {custom_info.length > 0 && <div className="p1 fw-bold">{t("boat.custom")}</div>}
          <div className="grid grid-cols-2 gap-x-20 gap-y-10">
            {custom_info.map((item) => (
              <p key={item} className="m-0">
                - {item}
              </p>
            ))}
          </div>
          {safety.length > 0 && <div className="p-1 fw-bold">{t("boat.safety_info")}</div>}
          <div className="grid grid-cols-2 gap-x-20 gap-y-10">
            {safety.map((item) => (
              <p key={item} className="m-0">
                - {item}
              </p>
            ))}
          </div>
        </Col>
        <Col xs={12} md={12} lg={6} className="order-1 order-lg-2 mt-sm-2 mt-md-3 mb-sm-1 mb-md-2">
          <PhotoSlider photos={photos} preview className="w-100 h-100" />
        </Col>
      </Row>
    </article>
  )
}
