import React from "react"
import PaymentMethodList from "./PaymentMethodList"
import PaymentMethodNew from "./PaymentMethodNew"
import InputError from "components/form/InputError"

const PaymentMethodSelector = ({ active, create = false, onChange, saveCard, onSaveCardChange, client = {}, className }) => {
  let classes = "vstack gap-15"
  if (className) classes = classes.concat(" ", className)

  return (
    <div className={classes}>
      <PaymentMethodList active={active} onChange={onChange} client={client} className="vstack gap-15" />
      <PaymentMethodNew
        active={active}
        saveCard={saveCard}
        onChange={onChange}
        onSaveCard={onSaveCardChange}
        create={create}
        client={client}
      />
      <InputError field="card_error" />
    </div>
  )
}

export default PaymentMethodSelector
