import React from "react"

import { Elements } from "@stripe/react-stripe-js"

const options = { appearance: { theme: "flat" } }

export default function StripeWrapper({ children }) {
  const stripePromise = import("@stripe/stripe-js").then(({ loadStripe }) => loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY))

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  )
}
