import React, { memo, useEffect, useState } from "react"
import { FormText, Label } from "reactstrap"
import Input from "components/form/Input"
import DatepickerInput from "components/form/DatepickerInput"
import TotalPrice from "./TotalPrice"

import { useTranslation } from "react-i18next"
import { calculateChanges, generateGuestCountOptions, getBookingDisabledDates } from "components/bookings/helpers"

import { useSelector } from "react-redux"
import { metadataSelector, modelSelector } from "store/selectors"

const GuideCalculator = ({ form, changeHandler, current_booked_date = "", disabled: disabledProp = false, edit = false, className }) => {
  const { t } = useTranslation()
  const booking_trip_collection = useSelector(metadataSelector("bookings")).trip_collection
  const dashboard_trip_collection = useSelector(metadataSelector("dashboard")).trip_collection
  const trip_collection = booking_trip_collection || dashboard_trip_collection || []

  const booking = useSelector(modelSelector("booking"))
  const bookingTrip = booking.trip
  const emptyTrip = useSelector(modelSelector("trip"))
  const [selectedTrip, selectTrip] = useState(emptyTrip)
  const trip = (edit ? bookingTrip : selectedTrip) || emptyTrip
  const disabled = !trip?.id || disabledProp

  const calculation = calculateChanges(form, { ...booking, booked_amount: 0, current_amount: 0, trip })

  const tripSelectHandler = (e) => {
    changeHandler(e)
    selectTrip(trip_collection.find((trip) => trip.id === +e.target.value))
  }

  const minGuests = trip?.min_guests || 1
  const maxGuests = trip?.max_guests || 100

  useEffect(() => {
    if (!edit && !form.trip_id) tripSelectHandler({ target: { name: "trip_id", value: trip_collection.at(0)?.id || "" } })

    if (form.trip_id) {
      selectTrip(emptyTrip)
      tripSelectHandler({ target: { name: "trip_id", value: form.trip_id || "" } })
    }
  }, [trip_collection.length, form.trip_id]) //eslint-disable-line

  const restrictions = getBookingDisabledDates(trip, current_booked_date)

  const guestCountHint = []
  if (minGuests) guestCountHint.push(`min guests ${minGuests}`)
  if (maxGuests) guestCountHint.push(`max guests ${maxGuests}`)

  const classes = []
  if (className) classes.push(className)
  if (disabled) classes.push("pointer-event-none opacity-50")

  return (
    <div className="vstack gap-20 bg-white rounded p-20">
      <div className="hstack gap-20">
        <div className="flex-fill">
          <Label for="title" className="fs-6">
            {t("booking.labels.trip_name")}
          </Label>
          {edit ? (
            <Input id="title" type="text" name="title" value={trip?.title || ""} className="fs-6" withError readOnly disabled />
          ) : (
            <Input
              id="title"
              type="select"
              name="trip_id"
              value={form.trip_id}
              onChange={tripSelectHandler}
              className="fs-6"
              withError
              errorField="trip"
            >
              <option value="">Select trip</option>
              {trip_collection.map((trip) => (
                <option key={trip.id} value={trip.id}>
                  {trip.title}
                </option>
              ))}
            </Input>
          )}
        </div>
      </div>
      <div className="hstack gap-20 flex-wrap">
        <div className="flex-fill mb-auto">
          <DatepickerInput
            name="booked_date"
            label={t("booking.labels.booked_date")}
            type="date"
            value={form.booked_date}
            onChange={changeHandler}
            restrictions={restrictions}
            disabled={disabled}
            labelClassName="fs-6"
            inputClassName="fs-6"
          />
        </div>
        <div>
          <div className="hstack gap-20">
            <div>
              <Label for="adult_count" className="fs-6">
                {t("booking.labels.adult_count")}
              </Label>
              <Input
                id="adult_count"
                name="adult_count"
                type="select"
                value={form.adult_count}
                onChange={changeHandler}
                disabled={disabled}
                className="fs-6"
                withError
              >
                {generateGuestCountOptions(+form.child_count, maxGuests)}
              </Input>
            </div>
            <div>
              <Label for="child_count" className="fs-6">
                {t("booking.labels.child_count_12")}
              </Label>
              <Input
                id="child_count"
                name="child_count"
                type="select"
                value={form.child_count}
                onChange={changeHandler}
                disabled={disabled}
                className="fs-6"
                withError
              >
                {generateGuestCountOptions(+form.adult_count, maxGuests)}
              </Input>
            </div>
          </div>
          <FormText className="fs-7 g-col-xsm-2 mt-n1">
            <span className="text-dark text-opacity-50">{guestCountHint.join(", ").replace(/^\w/, (match) => match.toUpperCase())}</span>
          </FormText>
        </div>
      </div>
      {!(edit && calculation.isGuestChanged) && <TotalPrice calculation={calculation} />}
    </div>
  )
}

export default memo(GuideCalculator)
