import React, { memo, useMemo, useState } from "react"
import { Button, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { DropdownItem, DropdownMenu } from "components/common/Dropdown"

import useSearchFilter from "hooks/useSearchFilter"
import useForm from "hooks/useForm"
import { pick, omit, mapValues } from "lodash"
import { useTranslation } from "react-i18next"
import { isEmptyFields } from "helpers/form"

function FilterWrapper({ title = "Filter", fields, toggleButton, accessAttributes, exceptDependencies, children, end, ...rest }) {
  const { t } = useTranslation()
  const [params, updateSearchFilters] = useSearchFilter({
    accessAttributes,
    exceptDependencies
  })
  const [form, changeHandler, submitHandler, submitCallback, reset, isChanged] = useForm(params, fields)
  const [isOpen, setOpen] = useState(false)
  const currentFilters = useMemo(() => pick(params, fields), [fields, params])
  const currentIsFiltered = useMemo(() => !isEmptyFields(currentFilters), [currentFilters])
  const isFiltered = useMemo(() => !isEmptyFields(omit(params, exceptDependencies)), [params]) //eslint-disable-line

  const resetCurrentFilters = (event) => {
    if (event.persist) event.persist()
    const resetedCuurentParams = {
      ...params,
      ...mapValues(pick(params, fields), () => null)
    }
    reset()
    updateSearchFilters(resetedCuurentParams)
  }

  const resetAllFilters = (event) => {
    if (event.persist) event.persist()
    const resetedParams = {
      ...pick(params, exceptDependencies),
      ...mapValues(omit(params, exceptDependencies), () => null)
    }
    reset()
    updateSearchFilters(resetedParams)
  }

  submitCallback(() => {
    const fieldsWithDefaults = fields.reduce((acc, key) => ({ ...acc, [key]: form[key] || null }), {})
    updateSearchFilters(fieldsWithDefaults)
  })

  return (
    <UncontrolledDropdown direction="down" {...rest} onToggle={(_, isOpen) => setOpen(isOpen) || (!isOpen && reset())}>
      {toggleButton ? (
        typeof toggleButton === "function" ? (
          toggleButton(isOpen, currentIsFiltered, currentFilters)
        ) : (
          toggleButton
        )
      ) : (
        <DropdownToggle caret className={isOpen ? "bg-primary-second text-white" : "bg-gray-lightest-hover"}>
          {title}
        </DropdownToggle>
      )}
      <DropdownMenu container="body" tag="form" onSubmit={submitHandler} className="max-vw-100" end={end}>
        <DropdownItem header tag="p" className="fs-5 fw-bold text-dark py-0 mb-2">
          {title}
        </DropdownItem>

        <div className="vstack align-items-start dropdown-item-text">
          {React.Children.only(typeof children === "function" ? children(form, changeHandler, currentFilters) : children)}
        </div>

        <DropdownItem divider />

        <div className="hstack gap-10 py-0 dropdown-item-text">
          <div className="vstack align-items-start gap-2">
            <Button
              color="link"
              type="button"
              className="link-dark fs-6 text-primary-second-hover p-0"
              onClick={resetCurrentFilters}
              disabled={!currentIsFiltered}
            >
              {t("global.reset", { title })}
            </Button>
            <Button
              color="link"
              type="button"
              className="link-dark fs-6 text-primary-second-hover p-0 fw-bold"
              onClick={resetAllFilters}
              disabled={!isFiltered}
            >
              {t("global.reset", { title: t("global.all") })}
            </Button>
          </div>
          <Button color="primary-second" className="fs-6 fw-bold ms-auto py-10 px-25" disabled={!isChanged}>
            {t("global.confirm")}
          </Button>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default memo(FilterWrapper)
