import React, { useEffect } from "react"
import { Button, Label, FormGroup } from "reactstrap"
import Input from "components/form/Input"
import Icon from "components/common/Icon"
import Avatar from "components/common/Avatar"
import GroupSelector from "./GroupSelector"
import Notes from "components/bookings/form/Notes"
import FirstLoading from "modules/loader-watchers/FirstLoading"

import { generateCountryOptions } from "components/bookings/helpers"
import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { pick } from "lodash"
import useForm, { cleanNestedAttributes, useNestedFields } from "hooks/useForm"

import { useDispatch, useSelector } from "react-redux"
import { saveClient } from "store/clients"
import { modelSelector } from "store/selectors"

const Form = ({ onChanged, closeHandler, edit = false }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const client = useSelector(modelSelector("client"))

  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm({ ...client }, [
    "cash_payment",
    "user",
    "client_groups",
    "notes"
  ])

  const [, , addGroupHandler, removeGroupHandler] = useNestedFields(form, "client_groups", ["group_id"], changeHandler)

  submitCallback(() => {
    const user_attributes = pick(form.user, [
      "id",
      "nickname",
      "avatar",
      "gender",
      "first_name",
      "last_name",
      "email",
      "phone_number",
      "birthday",
      "address_line_1",
      "address_line_2",
      "city",
      "state",
      "country",
      "zip_code"
    ])
    const notes_attributes = cleanNestedAttributes(form.notes, ["content"])
    const guide_client = {
      cash_payment: form.cash_payment,
      group_clients_attributes: form.client_groups,
      notes_attributes,
      user_attributes
    }
    if (!(form.user.avatar instanceof File)) delete guide_client.user_attributes.avatar

    const formData = serialize({ guide_client }, { indices: true })
    dispatch(saveClient(client.id, formData)).then(closeHandler)
  })

  const avatarHandler = ({ target }) => {
    const [file] = target.files
    changeHandler({ target: { name: "user.avatar", value: file } })
  }

  useEffect(() => {
    if (typeof onChanged === "function") onChanged(isChanged)
  }, [isChanged, onChanged])

  return (
    <FirstLoading name="clients.client" new>
      <form onSubmit={submitHandler}>
        <div className="vstack gap-20">
          <div className="hstack align-items-center gap-20 bg-white rounded p-20 sticky-top">
            <h1 className="h3 fw-medium lh-1">{t(`client.${edit ? "edit" : "add"}`)}</h1>
            <div className="hstack gap-10 my-n1 ms-auto">
              <Button color="primary" className="fs-7" type="submit" disabled={!isChanged}>
                {t("global.save")}
              </Button>
            </div>
          </div>
          <div className="vstack gap-30 bg-white rounded p-20 ">
            <div className="hstack gap-30">
              <div className="link-target mw-100">
                <Avatar user={form.user} size={116} />
                <div className="hstack position-absolute top-0 start-0 w-100 h-100 z-2">
                  <Button tag="label" htmlFor="avatar" color="ghost" size="icon" className="ms-auto mt-auto">
                    <Icon iconName="Edit" className="d-block text-dark" />
                  </Button>
                  <input type="file" id="avatar" name="avatar" className="d-none" accept="image/png, image/jpeg" onChange={avatarHandler} />
                </div>
              </div>
              <GroupSelector
                end={false}
                value={form.client_groups.map((group) => group.group_id)}
                onAdd={(group_id) => addGroupHandler(null, { group_id })}
                onRemove={(id) => removeGroupHandler(form.client_groups.find(({ group_id }) => group_id === id))}
              />
            </div>
            <div className="grid grid-cols-1 grid-cols-xsm-2 gap-20">
              <div>
                <Label for="nickname" className="fs-6">
                  {t("client.nickname")}
                </Label>
                <Input
                  id="nickname"
                  type="text"
                  name="user.nickname"
                  placeholder={""}
                  value={form.user.nickname || ""}
                  onChange={changeHandler}
                  className="fs-6"
                  withError
                />
              </div>
              <div className="g-start-1">
                <Label for="first_name" className="fs-6">
                  {t("client.first_name")}
                </Label>
                <Input
                  id="first_name"
                  type="text"
                  name="user.first_name"
                  placeholder={""}
                  value={form.user.first_name || ""}
                  onChange={changeHandler}
                  className="fs-6"
                  withError
                />
              </div>
              <div>
                <Label for="last_name" className="fs-6">
                  {t("client.last_name")}
                </Label>
                <Input
                  id="last_name"
                  type="text"
                  name="user.last_name"
                  placeholder={""}
                  value={form.user.last_name || ""}
                  onChange={changeHandler}
                  className="fs-6"
                  withError
                />
              </div>

              <div>
                <Label for="gender" className="fs-6">
                  {t("settings.gender_label")}
                </Label>
                <Input
                  id="gender"
                  type="select"
                  name="user.gender"
                  placeholder={""}
                  value={form.user.gender || ""}
                  onChange={changeHandler}
                  className="fs-6"
                  withError
                >
                  <option value="male">{t("settings.gender.male")}</option>
                  <option value="female">{t("settings.gender.female")}</option>
                </Input>
              </div>
              <div>
                <Label for="birthday" className="fs-6">
                  {t("client.birthday")}
                </Label>
                <Input
                  id="birthday"
                  name="user.birthday"
                  label={t("client.birthday")}
                  type="date"
                  value={form.user.birthday}
                  onChange={changeHandler}
                  className="fs-6"
                />
              </div>
              <div>
                <Label for="email" className="fs-6">
                  {t("client.email")}
                </Label>
                <Input
                  id="email"
                  type="email"
                  name="user.email"
                  placeholder={""}
                  value={form.user.email || ""}
                  onChange={changeHandler}
                  className="fs-6"
                  withError
                />
              </div>
              <div>
                <Label for="phone_number" className="fs-6">
                  {t("client.phone_number")}
                </Label>
                <Input
                  id="phone_number"
                  type="tel"
                  name="user.phone_number"
                  placeholder={""}
                  value={form.user.phone_number || ""}
                  onChange={changeHandler}
                  className="fs-6"
                  withError
                />
              </div>
            </div>
            <div>
              <Label for="address" className="fs-6">
                {t("client.address")}
              </Label>
              <Input
                id="address_line_1"
                type="text"
                name="user.address_line_1"
                placeholder={t("client.address_line_1")}
                value={form.user.address_line_1 || ""}
                onChange={changeHandler}
                className="fs-6"
                withError
              />
            </div>
            <div>
              <Input
                id="address_line_2"
                type="text"
                name="user.address_line_2"
                placeholder={t("client.address_line_2")}
                value={form.user.address_line_2 || ""}
                onChange={changeHandler}
                className="fs-6"
                withError
              />
            </div>
            <div className="grid grid-cols-1 grid-cols-xsm-2 gap-20">
              <Input
                id="city"
                type="text"
                name="user.city"
                placeholder={t("client.city")}
                value={form.user.city || ""}
                onChange={changeHandler}
                className="fs-6"
                withError
              />
              <Input
                id="state"
                type="text"
                name="user.state"
                placeholder={t("client.state")}
                value={form.user.state || ""}
                onChange={changeHandler}
                className="fs-6"
                withError
              />
            </div>
            <div className="grid grid-cols-1 grid-cols-xsm-2 gap-20">
              <Input
                id="country"
                type="select"
                name="user.country"
                placeholder={t("client.country")}
                value={form.user.country || ""}
                onChange={changeHandler}
                className={["fs-6", form.user.country ? "" : "text-gray-light"].join(" ")}
                withError
              >
                {generateCountryOptions()}
              </Input>
              <Input
                id="zip_code"
                type="text"
                name="user.zip_code"
                placeholder={t("client.zip_code")}
                value={form.user.zip_code || ""}
                onChange={changeHandler}
                className="fs-6"
                withError
              />
            </div>
            <FormGroup switch className="hstack gap-10 m-0 p-0">
              <Label className="m-0" for="cash_payment">
                <div className="fs-5 fw-normal">{t("client.allow_cash_payment")}</div>
              </Label>
              <Input
                id="cash_payment"
                name="cash_payment"
                type="checkbox"
                checked={form.cash_payment || false}
                onChange={changeHandler}
                className="bg-primary-checked border-primary-checked border-primary-focus focus-ring-primary m-0"
              />
            </FormGroup>
          </div>
          {(!edit || client.id) && <Notes form={form} changeHandler={changeHandler} isChanged={isChanged} formActive={true} edit={edit} />}
        </div>
      </form>
    </FirstLoading>
  )
}

export default Form
