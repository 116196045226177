import React, { useState } from "react"
import { Button, Label } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { routes } from "router"
import Input from "components/form/Input"
import Spinner from "components/common/Spinner"

import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"
import { requestForgotPassword } from "store/password"

export default function ForgotPassword({ loginUrl = routes.signInPath() }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading } = useSelector((state) => state.password)
  const [email, setEmail] = useState("")

  const submitHandler = (event) => {
    event.preventDefault()
    const data = {
      email,
      redirect_url: loginUrl
    }
    dispatch(requestForgotPassword(data)).then(() => navigate(routes.resetPasswordRequestSentPath({}, { login_url: loginUrl })))
  }

  return (
    <form onSubmit={submitHandler}>
      <Label for="email">{t("sign_in.email")}</Label>
      <Input
        id="email"
        type="email"
        name="email"
        placeholder={t("sign_in.email")}
        value={email}
        onChange={({ target }) => setEmail(target.value)}
        withError
      />
      <Button color="primary" type="submit" className="mt-30 w-100">
        {t("forgot_password.button")}
      </Button>
      {loading && <Spinner className="bg-white bg-opacity-0 backdrop-blur-3 w-100 h-100 z-2" fixed />}
    </form>
  )
}
