import React, { useEffect } from "react"
import { FormGroup, FormText, Label, Alert } from "reactstrap"
import Input from "components/form/Input"
import DatepickerInput from "components/form/DatepickerInput"
import InputError from "components/form/InputError"
import TotalPrice from "./TotalPrice"

import { useTranslation } from "react-i18next"
import { calculateChanges, generateGuestCountOptions, getBookingDisabledDates, bookingDateStrIsDisabled } from "components/bookings/helpers"
import { timeTo12 } from "helpers/time"

const Calculator = ({ form, changeHandler, current_booked_date = "", trip = {}, disabled = false, className }) => {
  const { t } = useTranslation()
  const minGuests = trip.min_guests || 1
  const maxGuests = trip.max_guests || 100
  const restrictions = getBookingDisabledDates(trip, current_booked_date)
  const calculation = calculateChanges(form, { ...form, booked_amount: 0, current_amount: 0, trip })
  const alreadyBooked = form.booked_date && trip.unavailable_dates.includes(form.booked_date)

  useEffect(() => {
    if (!form.adult_count) changeHandler({ target: { name: "adult_count", value: 1 } })
  }, []) //eslint-disable-line

  const guestCountHint = []
  if (minGuests) guestCountHint.push(`min guests ${minGuests}`)
  if (maxGuests) guestCountHint.push(`max guests ${maxGuests}`)

  const classes = []
  if (className) classes.push(className)
  if (disabled) classes.push("pointer-event-none opacity-50")

  return (
    <div className={classes.join(" ")}>
      {alreadyBooked && (
          <Alert
            color="danger"
            className="bg-danger bg-opacity-5 text-danger border-danger border border-opacity-5 p-10"
          >
            {t("global.date_no_longer_available")}
          </Alert>
        )}
      <div className="grid gap-20 mb-auto" style={{ gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr))" }}>
        <DatepickerInput
          name="booked_date"
          label="Booked Date"
          type="date"
          value={alreadyBooked ? null : form.booked_date}
          onChange={changeHandler}
          restrictions={restrictions}
          disabled={disabled}
          floating
          labelClassName="fs-5 fw-normal text-uppercase"
          inputClassName="fs-4 fw-medium"
        />
        <div className="grid grid-cols-1 grid-cols-xsm-1 gap-2">
          <FormGroup noMargin floating>
            <Input
              type="text"
              value={timeTo12(trip.start_time) + " - " + timeTo12(trip.end_time)}
              disabled={true}
              className="fs-4 fw-medium"
              withError
            />
            <Label for="booked_time" className="fs-5 fw-normal text-uppercase">
              {t("booking.labels.booked_time")}
            </Label>
          </FormGroup>
        </div>
        <div className="grid grid-cols-1 grid-cols-xsm-2 gap-2">
          <FormGroup noMargin floating>
            <Input
              id="adult_count"
              type="select"
              name="adult_count"
              placeholder={t("booking.labels.adult_count")}
              value={form.adult_count}
              onChange={changeHandler}
              disabled={disabled}
              className="fs-4 fw-medium"
              withError
            >
              {generateGuestCountOptions(+form.child_count, maxGuests)}
            </Input>
            <Label for="booked_time" className="fs-5 fw-normal text-uppercase">
              {t("booking.labels.adult_count")}
            </Label>
          </FormGroup>
          <FormGroup noMargin floating>
            <Input
              id="child_count"
              type="select"
              name="child_count"
              placeholder={t("booking.labels.child_count_12")}
              value={form.child_count}
              onChange={changeHandler}
              disabled={disabled}
              className="fs-4 fw-medium"
              withError
            >
              {generateGuestCountOptions(+form.adult_count, maxGuests)}
            </Input>
            <Label for="booked_time" className="fs-5 fw-normal text-uppercase">
              {t("booking.labels.child_count_12")}
            </Label>
          </FormGroup>
          <FormText className="fs-7 g-col-xsm-2 mt-n1">
            <span className="text-dark text-opacity-50">{guestCountHint.join(", ").replace(/^\w/, (match) => match.toUpperCase())}</span>
          </FormText>
        </div>
      </div>
      <TotalPrice calculation={calculation} className="mt-25 pb-2 border-bottom border-gray-lightest" />
      <InputError field="client_id" className="mt-1 mb-n3" />
    </div>
  )
}

export default Calculator
