import React, { useEffect } from "react"
import { Label, FormGroup, DropdownToggle, Input } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { routes } from "router"
import { Dropdown, DropdownItem } from "components/common/Dropdown"
import Icon from "components/common/Icon"
import Avatar from "components/common/Avatar"
import GroupSelector from "./GroupSelector"
import Notes from "components/bookings/form/Notes"
import PastTrips from "components/bookings/form/PastTrips"
import FirstLoading from "modules/loader-watchers/FirstLoading"

import useForm from "hooks/useForm"
import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"

import { useDispatch, useSelector } from "react-redux"
import { updateClient, saveClient } from "store/clients"
import { createConversation } from "store/conversations"
import { cleanPastBooking } from "store/bookings"

import { TYPE_OPTIONS_ENUM } from "components/bookings/constants"

const Details = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { client } = useSelector((store) => store.clients)
  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm({ ...client }, ["notes", "client_groups"])

  submitCallback(() => {
    const guide_client = {
      notes_attributes: form.notes
    }
    const formData = serialize({ guide_client }, { indices: true })
    dispatch(saveClient(client.id, formData))
  })

  useEffect(() => {
    return () => dispatch(cleanPastBooking())
  }, []) //eslint-disable-line

  function cashPaymentHandler(value) {
    dispatch(updateClient(client.id, { cash_payment: value }))
  }

  const contactHandler = (type) => {
    if (type === "message")
      dispatch(createConversation({ participant_id: client.user_id })).then((conversation) =>
        navigate(routes.guideMessagesPath({}, { conversation_id: conversation.id }))
      )
    else if (type === "email") navigate(routes.guideClientEmailActionPath({}, { "user_ids[]": [client.user_id] }))
    else if (type === "text") navigate(routes.guideClientTextActionPath({}, { "user_ids[]": [client.user_id] }))
  }

  const addGroupHandler = (client) => (group_id) => {
    const group_clients_attributes = [...client.client_groups, { group_id }]
    const data = serialize({ guide_client: { group_clients_attributes } }, { indices: true })
    dispatch(updateClient(client.id, data))
  }

  const removeGroupHandler = (client) => (group_id) => {
    const group_clients_attributes = client.client_groups.map((group) => ({
      ...group,
      ...(group_id === group.group_id && { _destroy: true })
    }))
    const data = serialize({ guide_client: { group_clients_attributes } }, { indices: true })
    dispatch(updateClient(client.id, data))
  }

  return (
    <FirstLoading name="clients.client" new>
      <form onSubmit={submitHandler}>
        <div className="vstack gap-20">
          <div className="vstack gap-30 bg-white rounded p-20 ">
            <div className="hstack gap-20 align-items-center">
              <Avatar user={client.user} size={64} />
              <div className="vstack gap-2 justify-content-center">
                <Label className="m-0">{client.user.first_name + " " + client.user.last_name}</Label>
                {client.user.last_trip_date && <div className="opacity-50">{client.user.last_trip_date}</div>}
                <GroupSelector
                  value={form.client_groups.map((group) => group.group_id)}
                  onAdd={addGroupHandler(client)}
                  onRemove={removeGroupHandler(client)}
                  className="me-auto"
                />
              </div>
              <div className="hstack justify-content-end my-n1">
                {client.invited_by_guide && (
                  <Link className="link link-dark fw-medium ms-10 mx-10" to={routes.guideClientEditPath({ id: client.id })}>
                    <Icon iconName={"EditForm"} />
                  </Link>
                )}
                <Dropdown
                  className="my-n1"
                  toggleButton={
                    <DropdownToggle color="primary" className="fs-7">
                      {t("global.contact")}
                    </DropdownToggle>
                  }
                >
                  {Object.entries(TYPE_OPTIONS_ENUM).map(([type, label], index) => (
                    <DropdownItem key={index} onClick={() => contactHandler(type)}>
                      {label}
                    </DropdownItem>
                  ))}
                </Dropdown>
              </div>
            </div>
            <div className="grid grid-cols-1 grid-cols-xsm-2 gap-20">
              <div>
                <Label className="fs-6">{"Contact details"}</Label>
                <div className="opacity-50">{client.user.email}</div>
                <div className="opacity-50">{client.user.phone_number}</div>
              </div>
              {(client.user.address_line_1 || client.user.city || client.user.state || client.user.zip_cod) && (
                <div>
                  <Label className="fs-6">{"Address"}</Label>
                  {client.user.address_line_1 && <div className="opacity-50">{client.user.address_line_1 || "—"}</div>}
                  {client.user.address_line_2 && <div className="opacity-50">{client.user.address_line_2 || "—"}</div>}
                  {client.user.city && client.user.state && (
                    <div className="opacity-50">{(client.user.city || "—") + " " + (client.user.state || "—")}</div>
                  )}
                  {client.user.zip_code && <div className="opacity-50">{client.user.zip_code || "—"}</div>}
                </div>
              )}
              <div>
                <Label className="fs-6">{"Preferences"}</Label>
                {client?.booking_preferences?.total_guests_count && (
                  <div className="opacity-50">{"Usual group size: " + client?.booking_preferences?.total_guests_count}</div>
                )}
                {client.booking_preferences?.target_species.length ? (
                  <div className="opacity-50">
                    {"Target species: "} {client.booking_preferences?.target_species.length.map((i) => i + ",")}
                  </div>
                ) : null}
                <div className="opacity-50">
                  <FormGroup switch tag="label" className="hstack gap-3 m-0 p-0">
                    <Label className="m-0" for="cash_payment">
                      <div className="fs-5 fw-normal">{t("client.allow_cash_payment")}</div>
                    </Label>
                    <Input
                      id="cash_payment"
                      name="cash_payment"
                      type="checkbox"
                      checked={client.cash_payment || false}
                      onChange={() => cashPaymentHandler(!client.cash_payment)}
                      className="bg-primary-checked border-primary-checked border-primary-focus focus-ring-primary m-0"
                    />
                  </FormGroup>
                </div>
              </div>
              {client.user.birthday && (
                <div>
                  <Label className="fs-6">{"Birthday"}</Label>
                  <div className="opacity-50">{client.user.birthday}</div>
                </div>
              )}
              <div>
                <Label className="fs-6">{"Total amount spent"}</Label>
                <div className="opacity-50">{client.total_booking_amount}</div>
              </div>
            </div>
          </div>
          {client.id && <Notes form={form} changeHandler={changeHandler} isChanged={isChanged} formActive={false} edit={true} />}
          {client.user_id && <PastTrips clientId={client.user_id} />}
        </div>
      </form>
    </FirstLoading>
  )
}

export default Details
