import React, { memo, useMemo } from "react"

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip } from "chart.js"
import { Line } from "react-chartjs-2"

import moment from "moment"
import { map } from "lodash"

import { useSelector } from "react-redux"

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip)

const chartData = ({ labels, data }) => ({
  labels,
  datasets: [
    {
      label: "$",
      data,
      borderColor: "#64BC1C",
      pointRadius: 0,
      pointRadiusHover: 5,
      datalabels: {
        display: false
      }
    }
  ]
})

export const chartOptions = ({ data }) => {
  const yLineCount = 6
  const yMaxValue = Math.max(...map(data, "y"))
  const stepSize = Math.ceil(yMaxValue / yLineCount / 50) * 50
  const yMax = stepSize * yLineCount

  return {
    devicePixelRatio: 2,
    responsive: true,
    maintainAspectRatio: false,
    layout: { padding: 0 },
    plugins: { legend: false },
    interaction: {
      mode: "index",
      intersect: false
    },
    scales: {
      x: {
        grid: { display: false },
        time: { parset: "D MMM" },
        ticks: {
          callback: function (value, index, values) {
            const label = moment(this.getLabelForValue(value), "DD-MM-YY").format("D MMM")
            const isShow = [0, values.length - 1].includes(index)
            return isShow ? label : ""
          },
          autoSkip: false,
          align: "end",
          maxRotation: 0,
          color: "#141414",
          font: { size: 10 },
          padding: -1
        }
      },
      yAxes: {
        type: "linear",
        max: yMax,
        beginAtZero: true,
        ticks: {
          callback: function (label, index) {
            const isShow = (index + 1) % 2
            return isShow ? label : ""
          },
          stepSize: stepSize,
          color: "#141414",
          font: { size: 10 }
        },
        grid: {
          color: ["#141414", ...new Array(yLineCount).fill("#F2F2F2")],
          drawBorder: false
        }
      }
    }
  }
}

function SalesLineChart({ range, className }) {
  const { metadata } = useSelector((store) => store.accounting)
  const { incomes_per_day_stats } = metadata
  const incomes_per_day = useMemo(() => incomes_per_day_stats?.[range] || {}, [incomes_per_day_stats, range])

  const rawData = useMemo(
    () =>
      Object.entries(incomes_per_day).reduce(
        (data, [date, value]) => {
          data.data.push({ x: date, y: +value })
          return data
        },
        { labels: [], data: [], backgroundColor: [], borderColor: [] }
      ),
    [incomes_per_day]
  )
  const data = useMemo(() => chartData(rawData), [rawData])
  const options = useMemo(() => chartOptions(rawData), [rawData])

  const classes = ["chart-container position-relative"]
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")} style={{ height: 140 }}>
      <Line data={data} options={options} />
    </div>
  )
}

export default memo(SalesLineChart)
