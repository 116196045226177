import React, { memo } from "react"

import { useTranslation } from "react-i18next"

const CanceledBanner = ({ status, className }) => {
  const { t } = useTranslation()

  const classes = ["h4 text-center text-danger bg-danger bg-opacity-5 rounded px-20 py-15"]
  if (className) classes.push(className)

  if (status !== "canceled") return null
  return <div className={classes.join(" ")}>{t("booking.canceled_banner")}</div>
}

export default memo(CanceledBanner)
