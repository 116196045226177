import React, { memo, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { routes } from "router"
import { Button, Modal, ModalBody } from "reactstrap"
import Input from "components/form/Input"

import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"
import { hideModal, resetModal, showModal } from "modules/modals/reducer"
import { modalSelector } from "modules/modals/selectors"

const TemplateNameModal = ({ form, changeHandler, isChanged, className }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const templateModal = useSelector(modalSelector("templateModal"))
  const openHandler = () => dispatch(showModal("templateModal"))
  const closeHandler = () => dispatch(hideModal("templateModal"))
  const onClosedHandler = () => dispatch(resetModal("templateModal")) && !form.name && navigate(routes.guideMarketingTemplatesParentsPath())

  useEffect(() => {
    openHandler()
  }, []) // eslint-disable-line

  const classes = ["vstack bg-white rounded overflow-hidden shadow-lg position-relative w-100"]
  if (className) classes.push(className)

  return (
    <Modal
      isOpen={templateModal.isOpen}
      size="md"
      centered
      scrollable
      backdropClassName="backdrop-blur-3"
      contentClassName="rounded-sm-3"
      toggle={closeHandler}
      onClosed={onClosedHandler}
    >
      <ModalBody className="vstack align-items-center gap-20 p-20 text-center">
        <h3>{t("marketing.labels.name")}</h3>
        <Input
          id="name"
          type="text"
          name="name"
          placeholder={t("marketing.placeholders.name")}
          value={form.name || ""}
          onChange={changeHandler}
          className="fs-6"
        />
        <Button color="primary" className="text-white" onClick={closeHandler} disabled={!isChanged}>
          {t("global.next")}
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default memo(TemplateNameModal)
