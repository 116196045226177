import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import { routes } from "router"
import { Button } from "reactstrap"
import { Dropdown, DropdownItem } from "components/common/Dropdown"
import Icon from "components/common/Icon"

import { LazyLoadImage } from "react-lazy-load-image-component"
import { useTranslation } from "react-i18next"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"
import useLoaderWatchers from "modules/loader-watchers/hooks/useLoaderWatchers"
import moment from "moment"

import { useDispatch, useSelector } from "react-redux"
import { deletePost, publishPostToggle } from "store/posts"


const Post = ({ post, edit = false }) => {
  useLoaderWatchers({ watchers: ["posts.post"] })
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const confirmModal = useConfirmModal()
  const { role } = useSelector((state) => state.user)
  const { trips_collection } = useSelector((state) => state.posts.metadata)
  const trip = useMemo(
    () => post?.trip || trips_collection?.find(({ id }) => id === +post.trip_id),
    [post.trip, post.trip_id, trips_collection]
  )
  const photos = useMemo(() => post?.photos?.filter(({ _destroy }) => !_destroy) || [], [post?.photos])
  const postStringTags = useMemo(
    () =>
      post.tags
        ?.filter(({ _destroy }) => !_destroy)
        .map(({ title }) => title)
        ?.join(" ") || "",
    [post.tags]
  )
  const isPiblished = post.id && post.status === "published"
  const isGuide = role === "guide_user"

  const publishToggler = ({ currentTarget }) => dispatch(publishPostToggle(post.id, JSON.parse(currentTarget.value)))
  const deleteHandler = confirmModal(
    {
      title: "Are you sure you want to delete this post?",
      color: "danger",
      submitText: t("global.delete"),
      cancelText: t("global.cancel")
    },
    () => dispatch(deletePost(post.id))
  )

  return (
    <article className="flex-fill bg-white rounded p-20">
      <div className="hstack gap-10 mb-3">
        {isPiblished || edit ? (
          <p className="text-gray-light mb-0 me-auto">{moment(post.published_at).fromNow()}</p>
        ) : (
          isGuide && (
            <Button color="primary" className="me-auto px-3 py-2 fs-7" onClick={publishToggler} value={true}>
              {t("global.publish")}
            </Button>
          )
        )}
        {post.id && !edit && isGuide && (
          <Dropdown>
            <DropdownItem onClick={publishToggler} value={!isPiblished}>
              {t(`global.${isPiblished ? "unpublish" : "publish"}`)}
            </DropdownItem>
            <DropdownItem tag={Link} to={routes.guideMarketingPostEditPath({ id: post.id })}>
              {t("posts.edit")}
            </DropdownItem>
            <DropdownItem className="text-danger" onClick={deleteHandler}>
              {t("posts.delete")}
            </DropdownItem>
          </Dropdown>
        )}
      </div>

      <div className="hstack gap-10 flex-column flex-lg-row">
        <h2 className="h3 fw-medium lh-sm text-start me-auto">{post.title}</h2>
        <div className="hstack gap-2 fw-medium">
          <Icon iconName="Location" className="flex-shrink-0 mb-auto" size={28} block />
          {trip?.address || "—"}
        </div>
      </div>
      <hr className="my-15" />
      <p className="mb-15 text-prewrap">{post?.content}</p>
      {photos.length > 0 && (
        <div className="grid gap-2 mb-20">
          {photos.length === 1 && (
            <div className="g-col-12 g-col-lg-12" style={{ maxHeight: 550 }}>
              <LazyLoadImage
                src={photos.at(0).url || photos.at(0).file_preview}
                alt=""
                className="object-fit-cover w-100 h-100 rounded-1"
                wrapperClassName="w-100 h-100"
                effect="blur"
                placeholderSrc={photos.at(0).placeholder_url || null}
              />
            </div>
          )}
 
          {photos.length === 2 && (
            <>
              <div className="g-col-12 g-col-md-12 g-col-lg-6" style={{ maxHeight: 440 }}>
                <LazyLoadImage
                  src={photos.at(0).url || photos.at(0).file_preview}
                  alt=""
                  className="object-fit-cover w-100 h-100 rounded-1"
                  wrapperClassName="w-100 h-100"
                  effect="blur"
                  placeholderSrc={photos.at(0).placeholder_url || null}
                />
              </div>
              <div className="g-col-12 g-col-md-12 g-col-lg-6" style={{ maxHeight: 440 }}>
                <LazyLoadImage
                  src={photos.at(1).url || photos.at(1).file_preview}
                  alt=""
                  className="object-fit-cover w-100 h-100 rounded-1"
                  wrapperClassName="w-100 h-100"
                  effect="blur"
                  placeholderSrc={photos.at(1).placeholder_url || null}
                />
              </div>
            </>
          )}
 
          {photos.length === 3 && (
            <>
              <div className="g-col-12 g-col-lg-6" style={{ maxHeight: 560 }}>
                <LazyLoadImage
                  src={photos.at(0).url || photos.at(0).file_preview}
                  alt=""
                  className="object-fit-cover w-100 h-100 rounded-1"
                  wrapperClassName="w-100 h-100"
                  effect="blur"
                  placeholderSrc={photos.at(0).placeholder_url || null}
                />
              </div>
              <div className="g-col-12 g-col-lg-6 grid grid-cols-6 gap-2" >
                <div className="g-col-6 grid grid-cols-1 grid-rows-8 gap-2" style={{ maxHeight: 560 }}>
                  <div className="g-row-4">
                    <LazyLoadImage
                      src={photos.at(1).url || photos.at(1).file_preview}
                      alt=""
                      className="object-fit-cover w-100 h-100 rounded-1"
                      wrapperClassName="w-100 h-100"
                      effect="blur"
                      placeholderSrc={photos.at(1).placeholder_url || null}
                    />
                  </div>
                  <div className="g-row-4">
                    <LazyLoadImage
                      src={photos.at(2).url || photos.at(2).file_preview}
                      alt=""
                      className="object-fit-cover w-100 h-100 rounded-1"
                      wrapperClassName="w-100 h-100"
                      effect="blur"
                      placeholderSrc={photos.at(2).placeholder_url || null}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
 
          {photos.length === 4 && (
            <>
              <div className="g-col-12 g-col-lg-6" style={{ maxHeight: 420 }}>
                <LazyLoadImage
                  src={photos.at(0).url || photos.at(0).file_preview}
                  alt=""
                  className="object-fit-cover w-100 h-100 rounded-1"
                  wrapperClassName="w-100 h-100"
                  effect="blur"
                  placeholderSrc={photos.at(0).placeholder_url || null}
                />
              </div>
              <div className="g-col-12 g-col-lg-6" style={{ maxHeight: 420 }}>
                <LazyLoadImage
                  src={photos.at(1).url || photos.at(1).file_preview}
                  alt=""
                  className="object-fit-cover w-100 h-100 rounded-1"
                  wrapperClassName="w-100 h-100"
                  effect="blur"
                  placeholderSrc={photos.at(1).placeholder_url || null}
                />
              </div>
              <div className="g-col-12 g-col-lg-6" style={{ maxHeight: 420 }}>
                <LazyLoadImage
                  src={photos.at(2).url || photos.at(2).file_preview}
                  alt=""
                  className="object-fit-cover w-100 h-100 rounded-1"
                  wrapperClassName="w-100 h-100"
                  effect="blur"
                  placeholderSrc={photos.at(2).placeholder_url || null}
                />
              </div>
              <div className="g-col-12 g-col-lg-6" style={{ maxHeight: 420 }}>
                <LazyLoadImage
                  src={photos.at(3).url || photos.at(3).file_preview}
                  alt=""
                  className="object-fit-cover w-100 h-100 rounded-1"
                  wrapperClassName="w-100 h-100"
                  effect="blur"
                  placeholderSrc={photos.at(3).placeholder_url || null}
                />
              </div>
            </>
          )}
        </div>
      )}
      <div className="text-gray-light">{postStringTags}</div>
    </article>
  )
}

export default Post