import React, { useCallback, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { TabContent, TabPane } from "reactstrap"
import Header from "components/accounting/Header"
import Dashboard from "components/accounting/Dashboard"
import Details from "components/accounting/Details"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"
import useLoaderWatchers from "modules/loader-watchers/hooks/useLoaderWatchers"
import FirstLoading from "modules/loader-watchers/FirstLoading"

import useSearchFilter from "hooks/useSearchFilter"
import { useTitle } from "hooks/useTitle"
import { isEqual } from "lodash"

import { ACCESS_ATTRIBUTE } from "constants/accounting"

export default function Accounting() {
  useTitle("Accounting")
  useLoaderWatchers({ watchers: ["accounting"] })
  const [params, onChangeFilters] = useSearchFilter({ accessAttributes: ACCESS_ATTRIBUTE })
  const [paramsState, setParamsState] = useState(params)

  const changeHandler = useCallback(
    ({ target }) =>
      setParamsState((prevParamsState) => ({
        ...prevParamsState,
        [target.name]: target.value
      })),
    []
  )

  useEffect(() => {
    if (isEqual(paramsState, params)) return
    onChangeFilters(paramsState)
  }, [paramsState]) //eslint-disable-line

  const showDateSwitcher = params.type !== "business_overview"
  const activeTab = showDateSwitcher ? "details" : "dashboard"

  return (
    <>
      <div className="flex-fill z-0 position-relative" id="accounting">
        <Header params={params} onChangeFilters={changeHandler} />
        <FirstLoading name="accounting">
          <TabContent activeTab={activeTab} className="w-100">
            <TabPane tabId="dashboard">
              <Dashboard params={params} onChangeFilters={changeHandler} />
            </TabPane>
            <TabPane tabId="details">
              <Details params={params} onChangeFilters={changeHandler} />
            </TabPane>
          </TabContent>

          <DefaultErrorBoundary>
            <Outlet />
          </DefaultErrorBoundary>
        </FirstLoading>
      </div>
    </>
  )
}
