import React from "react"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"
import { routes } from "router"
import PhotoSlider from "../common/PhotoSlider"
import DynamicTag from "components/DynamicTag"
import Icon from "components/common/Icon"

import { useTranslation } from "react-i18next"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"
import { timeTo12 } from "helpers/time"

import { useDispatch } from "react-redux"
import { deleteClientDraftBooking } from "store/bookings"

const Booking = ({ booking, className, style, ...rest }) => {
  const {
    uuid,
    status,
    booked_datetime,
    trip: { id: tripId, title, photos, address, status: tripStatus }
  } = booking
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const confirmModal = useConfirmModal()
  const isDraft = status === "draft"
  const isTripDraft = tripStatus === "draft"

  const removeDraftBookingHandler = confirmModal(
    {
      title: "Are you sure you want to delete this booking?",
      color: "danger",
      submitText: t("global.delete"),
      cancelText: t("global.cancel")
    },
    (e) => dispatch(deleteClientDraftBooking(e.currentTarget.id))
  )

  const classes = ["vstack bg-white rounded overflow-hidden shadow-lg position-relative w-100 h-100"]
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")} style={style} {...rest}>
      <div className="flex-shrink-0 align-self-stretch position-relative" style={{ flexBasis: 200, flexGrow: 1 }}>
        <div className="position-absolute top-0 start-0 w-100 p-3 z-2">
          <div className="hstack justify-content-end fs-6 fw-semibold text-white">
            {(status === "booked" && (
              <div className="hstack bg-primary rounded-pill p-1 pe-2">
                <Icon iconName="Checked" className="me-1" />
                {t("booking.card.booked")}
              </div>
            )) ||
              (status === "completed" && (
                <div className="hstack bg-primary-second rounded-pill py-1 px-3">{t("booking.card.completed")}</div>
              )) ||
              (status === "requested" && (
                <div className="hstack bg-warning rounded-pill py-1 px-3">{t("booking.card.requested")}</div>
              )) ||
              (status === "canceled" && <div className="hstack bg-danger rounded-pill p-1 pe-2">{t("booking.card.canceled")}</div>) || (
                <div className="hstack bg-gray-light rounded-pill py-1 px-25">{t("booking.card.draft")}</div>
              )}
          </div>
        </div>
        <PhotoSlider photos={photos} preview className="w-100" />
      </div>
      <div className="vstack flex-fill p-3">
        <p className="fs-5 fw-semibold lh-sm">
          {booked_datetime || "—"}
          <br />
          {timeTo12(booking.trip.start_time)} - {timeTo12(booking.trip.end_time)}
        </p>
        <DynamicTag
          tag={isTripDraft ? "div" : Link}
          className={`h5 fw-medium lh-sm ${isTripDraft ? "text-dark" : "link link-dark"}`}
          {...(!isTripDraft && { to: routes.tripPath({ id: tripId }) })}
        >
          {title}
        </DynamicTag>
        <p className="hstack gap-2 mt-10 mb-25 fs-7 lh-sm text-dark text-opacity-50">
          <Icon iconName="Location" className="flex-shrink-0" size={14} />
          <span className="fs-7">{address || "—"}</span>
        </p>
        <div className="hstack gap-10 mt-auto">
          <Button
            tag={Link}
            color={isDraft ? "primary-second" : "primary"}
            className="px-5 py-3 fs-6 w-100"
            to={isDraft ? routes.clientBookingWizardRootPath({ uuid }) : routes.clientBookingPath({ uuid })}
          >
            {isDraft ? t("global.continue") : t("global.see_details")}
          </Button>
          {isDraft && (
            <Button
              color="link"
              className="link-danger link-underline-opacity-0 link-underline-opacity-75-hover p-0 ms-auto fs-7 px-10"
              id={uuid}
              onClick={removeDraftBookingHandler}
            >
              <small>{t("global.delete")}</small>
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Booking
