import { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"

import { useTranslation } from "react-i18next"

export const useOffcanvasHandlers = ({ exitRedirect }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const confirmModal = useConfirmModal()
  const [isOpen, setOpen] = useState(false)
  const isChanged = useRef(false)

  const setChanged = (val) => (isChanged.current = val)

  const openHandler = () => setOpen(true)

  const closeHandler = () =>
    setTimeout(
      () =>
        isChanged.current
          ? confirmModal(
              {
                title: "Are you sure you want to discard all changes?",
                color: "danger",
                submitText: t("global.discard"),
                cancelText: t("global.cancel")
              },
              () => setOpen(false)
            )()
          : setOpen(false),
      0
    )

  const exitHandler = () => exitRedirect && navigate(exitRedirect)

  return { isOpen, openHandler, closeHandler, exitHandler, setChanged }
}
