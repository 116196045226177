import React from "react"
import { Badge, DropdownToggle, Label } from "reactstrap"
import FilterWrapper from "components/common/FilterWrapper"
import Input from "components/form/Input"
import Icon from "components/common/Icon"

import { useTranslation } from "react-i18next"

import { TRIP_FILTERS, TRIP_FILTERS_HELP_ATTRIBUTES } from "constants/trip"

export default function Sort() {
  const { t } = useTranslation()

  return (
    <FilterWrapper
      title={t("trip.filters.sorting")}
      fields={["price_per_person_sort", "skill_level_sort"]}
      accessAttributes={TRIP_FILTERS}
      exceptDependencies={TRIP_FILTERS_HELP_ATTRIBUTES}
      toggleButton={(isOpen, isActive, filters) => {
        const badge = Object.keys(filters)?.length || null
        return (
          <DropdownToggle
            className={["btn-icon", isOpen || isActive ? "bg-primary-second text-white" : "bg-gray-lightest-hover"].join(" ")}
            color="icon"
          >
            <Icon iconName="Sort" />
            {badge && (
              <Badge
                color="white"
                className="d-block position-absolute top-auto bottom-100 start-0 mb-n2 text-primary-second text-center fw-normal lh-1 border border-primary-second"
                style={{ minWidth: 17 }}
              >
                {badge}
              </Badge>
            )}
          </DropdownToggle>
        )
      }}
    >
      {(form, changeHandler) => (
        <>
          <Label className="fs-5 mb-3">{t("trip.filters.price")}</Label>
          <div className="vstack gap-2">
            <Label
              check
              className={`hstack gap-10 p-10 pe-15 bg-white border ${
                form.price_per_person_sort === "asc" ? "border-gray" : "border-gray-lightest"
              } rounded fs-7 fw-normal`}
            >
              <Input
                type="radio"
                name="price_per_person_sort"
                value="asc"
                checked={form.price_per_person_sort === "asc"}
                onChange={changeHandler}
                className="mt-0"
              />
              <span className={`text-dark ${form.price_per_person_sort === "asc" ? "" : "text-opacity-50"}`}>
                {t("trip.filters.price_asc")}
              </span>
            </Label>
            <Label
              check
              className={`hstack gap-10 p-10 pe-15 bg-white border ${
                form.price_per_person_sort === "desc" ? "border-gray" : "border-gray-lightest"
              } rounded fs-7 fw-normal`}
            >
              <Input
                type="radio"
                name="price_per_person_sort"
                value="desc"
                checked={form.price_per_person_sort === "desc"}
                onChange={changeHandler}
                className="mt-0"
              />
              <span className={`text-dark ${form.price_per_person_sort === "desc" ? "" : "text-opacity-50"}`}>
                {t("trip.filters.price_desc")}
              </span>
            </Label>
          </div>

          <Label className="fs-5 mb-3 mt-3">{t("trip.filters.skill")}</Label>
          <div className="vstack gap-2">
            <Label
              check
              className={`hstack gap-10 p-10 pe-15 bg-white border ${
                form.skill_level_sort === "asc" ? "border-gray" : "border-gray-lightest"
              } rounded fs-7 fw-normal`}
            >
              <Input
                type="radio"
                name="skill_level_sort"
                value="asc"
                checked={form.skill_level_sort === "asc"}
                onChange={changeHandler}
                className="mt-0"
              />
              <span className={`text-dark ${form.skill_level_sort === "asc" ? "" : "text-opacity-50"}`}>{t("trip.filters.skill_asc")}</span>
            </Label>
            <Label
              check
              className={`hstack gap-10 p-10 pe-15 bg-white border ${
                form.skill_level_sort === "desc" ? "border-gray" : "border-gray-lightest"
              } rounded fs-7 fw-normal`}
            >
              <Input
                type="radio"
                name="skill_level_sort"
                value="desc"
                checked={form.skill_level_sort === "desc"}
                onChange={changeHandler}
                className="mt-0"
              />
              <span className={`text-dark ${form.skill_level_sort === "desc" ? "" : "text-opacity-50"}`}>
                {t("trip.filters.skill_desc")}
              </span>
            </Label>
          </div>
        </>
      )}
    </FilterWrapper>
  )
}
