import React, { memo, useEffect } from "react"
import { Button, Label } from "reactstrap"
import Input from "components/form/Input"
import FirstLoading from "modules/loader-watchers/FirstLoading"
import Loading from "modules/loader-watchers/Loading"

import { useTranslation } from "react-i18next"
import { brandImage } from "components/bookings/helpers"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"

import { useDispatch, useSelector } from "react-redux"
import { detachPaymentMethod, getPaymentMethods, paymentMethodsSelector, updated } from "store/user"
import { firstLoadingSelector } from "modules/loader-watchers/selectors"

const PaymentMethodList = ({ active, onChange: changeHandler, client = {}, className }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const confirmModal = useConfirmModal()

  const defaultPaymentMethod = client?.default_payment_method_id
  const firstLoading = useSelector(firstLoadingSelector("user.payment_methods"))
  const paymentMethods = useSelector(paymentMethodsSelector)
  const hasPaymentMethods = !!paymentMethods.length

  const selectFirstPaymentMethod = (paymentMethods) => {
    const existingPaymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.id === defaultPaymentMethod)
    if (existingPaymentMethod) return
    dispatch(updated({ default_payment_method_id: !!paymentMethods.length ? null : {} }))
  }

  const detachPaymentMethodHandler = confirmModal(
    { title: "Are you sure you want to delete this payment method?", color: "danger", submitText: t("global.yes") },
    ({ currentTarget }) => dispatch(detachPaymentMethod(currentTarget.value)).then(selectFirstPaymentMethod)
  )

  useEffect(() => {
    if ((firstLoading, client.id)) dispatch(getPaymentMethods(client.id))
  }, [firstLoading, client.id]) //eslint-disable-line

  return (
    <FirstLoading name="user.payment_methods">
      {hasPaymentMethods && (
        <Loading tag="div" name="user.payment_methods" className={className} spinnerProps={{ className: "m-n2" }}>
          {paymentMethods.map((card) => (
            <div key={card.id} className="bg-white rounded shadow overflow-hidden link-target">
              <div
                className={[
                  "hstack gap-10 fs-7 p-20 rounded bg-opacity-5 border",
                  active === card.id ? "bg-primary-second border-primary-second" : "bg-white border-gray-lightest"
                ].join(" ")}
              >
                <img src={brandImage(card.brand)} alt={card.brand} className="d-block" />
                <div>
                  <div>
                    <div className="fw-medium">
                      {t("booking.wizard.step_3_checkout.labels.last4", { brand: card.brand.toUpperCase(), last4: card.last4 })}
                    </div>
                    <div className="text-dark text-opacity-50 fw-normal">
                      {t("booking.wizard.step_3_checkout.labels.expiry", { exp_month: card.exp_month, exp_year: card.exp_year })}
                    </div>
                  </div>
                  <Button
                    color="link"
                    className="link-danger link-underline-opacity-0 link-underline-opacity-75-hover p-0 ms-auto fs-7 position-relative z-2"
                    value={card.id}
                    onClick={detachPaymentMethodHandler}
                  >
                    <small>{t("global.delete")}</small>
                  </Button>
                </div>
                <Label check className="ms-auto">
                  <Input
                    type="radio"
                    name="payment_method_id"
                    value={card.id}
                    checked={active === card.id}
                    onChange={() => changeHandler(card.id)}
                    className="border-primary-second mt-0"
                    style={{ width: 20, height: 20 }}
                  />
                </Label>
              </div>
            </div>
          ))}
        </Loading>
      )}
    </FirstLoading>
  )
}

export default memo(PaymentMethodList)
