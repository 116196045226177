import React, { useState } from "react"
import { Button } from "reactstrap"
import PaymentMethodList from "components/stripe/payment/PaymentMethodList"
import PaymentMethodNew from "components/stripe/payment/PaymentMethodNew"
import InputError from "components/form/InputError"
import Loading from "modules/loader-watchers/Loading"
import { toaster } from "components/common/Toast"

import { numberToCurrency } from "helpers/string"
import { useStripe, useElements } from "@stripe/react-stripe-js"

import { useDispatch, useSelector } from "react-redux"
import { requestedIncomes, createdIncome } from "store/bookings"
import { modelSelector } from "store/selectors"
import { receiveErrors, resetErrors } from "modules/errors/reducer"

const PaymentConfirmation = ({
  createAction,
  captureAction,
  amount,
  submitText,
  showTotal,
  showPaymentMethods,
  onConfirm,
  className,
  client = {}
}) => {
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()

  const booking = useSelector(modelSelector("booking"))
  const defaultPaymentMethod = client?.default_payment_method_id

  const [paymentMethod, setPaymentMethod] = useState(showPaymentMethods ? defaultPaymentMethod : {})
  const [saveCard, setSaveCard] = useState(false)

  const isPaymentMethodBlank = !paymentMethod || (paymentMethod instanceof Object && !Object.keys(paymentMethod).length)
  const disabledSubmit = !stripe || isPaymentMethodBlank

  const createRecord = () => dispatch(resetErrors()) && dispatch(createAction(booking.id, { amount, stripe: true }))

  const confirmPayment = async (clientSecret) => {
    if (!stripe || !elements || isPaymentMethodBlank) return false
    dispatch(requestedIncomes())
    dispatch(resetErrors())
    try {
      const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod,
        ...(saveCard && { setup_future_usage: "on_session" })
      })
      dispatch(createdIncome())

      if (error) {
        if (error.type === "card_error")
          dispatch(
            receiveErrors({
              data: { message: { card_error: error.message } },
              showToast: false
            })
          )
        else toaster.error(error.message)
        return false
      }

      return paymentIntent.status === "requires_capture"
    } catch (error) {
      toaster.error({ title: error.name, text: error.message })
      dispatch(createdIncome())
      return false
    }
  }

  const captureRecord = (incomeId) => dispatch(captureAction(booking.id, incomeId))

  const submit = async (event) => {
    event && event.preventDefault()
    const { client_secret, status, income } = await createRecord()
    const isConfirmed = status === "requires_capture" || (await confirmPayment(client_secret))
    if (!isConfirmed) return

    const { booking } = await captureRecord(income.id)
    if (onConfirm instanceof Function) onConfirm(booking)
  }

  return (
    <Loading tag="div" name="bookings.incomes" className={className} spinnerProps={{ className: "m-n2" }}>
      {client.id && (
        <div className="vstack gap-15 mb-30">
          {showPaymentMethods && (
            <PaymentMethodList active={paymentMethod} onChange={setPaymentMethod} client={client} className="vstack gap-15" />
          )}
          <PaymentMethodNew
            active={paymentMethod}
            saveCard={saveCard}
            onChange={(paymentMethod) => setPaymentMethod(paymentMethod)}
            client={client}
            onSaveCard={setSaveCard}
            showPaymentMethods={showPaymentMethods}
          />
          <InputError field="card_error" />
        </div>
      )}

      {showTotal && (
        <div className="mt-25">
          <h3 className="fw-bold hstack justify-content-between pb-15 border-bottom border-gray-lightest">
            Total Amount <span>{numberToCurrency(amount)}</span>
          </h3>
        </div>
      )}

      <Button type="button" color="primary" className="w-100 mt-20 text-uppercase" disabled={disabledSubmit} onClick={submit}>
        {submitText}
      </Button>
    </Loading>
  )
}

export default PaymentConfirmation
