import React from "react"
import { useSearchParams } from "react-router-dom"
import { Button, DropdownToggle, Label } from "reactstrap"
import FilterWrapper from "components/common/FilterWrapper"
import Input from "components/form/Input"
import NavigationLink from "components/layout/NavigationLink"

import { useTranslation } from "react-i18next"

import { ECOSYSTEMS, TRIP_FILTERS, TRIP_FILTERS_HELP_ATTRIBUTES } from "constants/trip"

const TempNavigationLink = ({ ...props }) => <NavigationLink tag={Button} {...props} />

export default function Ecosystem({ onToggleClick }) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const activity = searchParams.get("activity")

  return (
    <FilterWrapper
      title={t("trips.filters.ecosystem")}
      fields={["fishing_ecosystem_eq", "hunting_ecosystem_eq"]}
      accessAttributes={TRIP_FILTERS}
      exceptDependencies={TRIP_FILTERS_HELP_ATTRIBUTES}
      toggleButton={(isOpen, isActive, filters) => (
        <DropdownToggle
          tag={TempNavigationLink}
          iconName="Ecosystem"
          label={t("trips.filters.ecosystem")}
          onClick={onToggleClick}
          isActive={isOpen || isActive}
          badge={filters[`${activity}_ecosystem_eq`] ? t(`trip.ecosystems.${filters[`${activity}_ecosystem_eq`]}`) : ""}
          disabled={!activity}
        />
      )}
    >
      {(form, changeHandler) => (
        <div className="grid grid-cols-2 gap-2 justify-content-start w-100">
          {activity
            ? ECOSYSTEMS[activity].map((ecosystem) => (
                <Label
                  key={ecosystem}
                  check
                  className={`hstack gap-10 p-10 pe-15 bg-white border ${
                    form[`${activity}_ecosystem_eq`] === ecosystem ? "border-gray" : "border-gray-lightest"
                  } rounded fs-7 fw-normal`}
                >
                  <Input
                    type="radio"
                    name={`${activity}_ecosystem_eq`}
                    value={ecosystem}
                    checked={form[`${activity}_ecosystem_eq`] === ecosystem}
                    onChange={changeHandler}
                    className="mt-0"
                  />
                  <span className={`text-dark ${form[`${activity}_ecosystem_eq`] === ecosystem ? "" : "text-opacity-50"}`}>
                    {t(`trip.ecosystems.${ecosystem}`)}
                  </span>
                </Label>
              ))
            : null}
        </div>
      )}
    </FilterWrapper>
  )
}
