import React from "react"
import PreviewMedia from "components/common/PreviewMedia"
import { SelectionState, Modifier, EditorState } from "draft-js"

export const mediaBlockRenderer = (block) => {
  if (block.getType() !== "atomic") return null

  return {
    component: Media,
    editable: false
  }
}

export const trimEditorState = (currentEditorState) => {
  let resultState = removeSpaces(currentEditorState)
  resultState = removeNewLines(resultState)

  return resultState
}

// Private functions

const Media = (props) => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0))
  const { src } = entity.getData()
  const type = entity.getType()
  if (type !== "image") return

  return <PreviewMedia type="image" preview={src} className="" mediaClassName="message__img-preview" />
}

const removeNewLines = (editorState) => {
  const content = editorState.getCurrentContent()

  Object.assign(
    content.blockMap,
    content.getBlockMap().filter((b) => b.getText() !== "" && b.getType())
  )

  return EditorState.createWithContent(content)
}

const removeSpaces = (currentEditorState) => {
  const currentContent = currentEditorState.getCurrentContent()

  const newContent = currentContent
    .getBlockMap()
    .filter((block) => block.getType() !== "atomic")
    .reduce((accumulator, block) => {
      const key = block.getKey()
      const text = block.getText()
      const trimmedLeft = text.trimStart()
      const trimmedRight = text.trimEnd()
      const offset = text.length - trimmedLeft.length

      const textToReplaceLeft = new SelectionState({
        anchorKey: key,
        focusKey: key,
        anchorOffset: 0,
        focusOffset: offset
      })

      const leftTrimmedContent = Modifier.replaceText(accumulator, textToReplaceLeft, "")

      const textToReplacRight = new SelectionState({
        anchorKey: key,
        focusKey: key,
        anchorOffset: trimmedRight.length - offset,
        focusOffset: text.length - offset
      })

      return Modifier.replaceText(leftTrimmedContent, textToReplacRight, "")
    }, currentContent)

  return EditorState.push(currentEditorState, newContent, "remove-range")
}
