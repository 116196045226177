const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE = 10

export function formatPaginationParams(metadata, nextPage = null) {
  return nextPage === null
    ? {}
    : {
        page: nextPage ? metadata.next_page : DEFAULT_PAGE,
        per_page: metadata.per_page || DEFAULT_PER_PAGE
      }
}

export function isLastPage(metadata) {
  return metadata?.total_pages === metadata?.page
}
