import React, { useEffect, useRef } from "react"
import { Button, DropdownToggle, InputGroup, UncontrolledDropdown } from "reactstrap"
import { DropdownItem, DropdownMenu } from "components/common/Dropdown"
import { useMatch, useNavigate } from "react-router-dom"
import { routes } from "router"
import GooglePlacesInput from "modules/google/GooglePlacesInput"

import { useTranslation } from "react-i18next"
import useForm from "hooks/useForm"
import useSearchFilter, { toAccessSearchString } from "hooks/useSearchFilter"

import { ACTIVITIES, GUESTS, TRIP_FILTERS } from "constants/trip"

export default function SearchTrips({ className }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const placeInputRef = useRef()
  const isTripsPage = useMatch(routes.tripsPath())
  const [params] = useSearchFilter({ accessAttributes: TRIP_FILTERS })
  const [form, changeHandler, submitHandler, submitCallback, reset, isChanged] = useForm(params)

  const placeChangedHandler = ({
    target: {
      value,
      coordinates: { lat, lng }
    }
  }) => {
    changeHandler({ target: { name: "address", value } })
    changeHandler({ target: { name: "lat", value: lat } })
    changeHandler({ target: { name: "lng", value: lng } })
  }

  submitCallback(() => {
    const search = toAccessSearchString(form, TRIP_FILTERS)
    navigate({ pathname: routes.tripsPath(), search })
  })

  useEffect(() => {
    if (placeInputRef.current) placeInputRef.current.value = form.address ? form.address : ""
  }, [form.address])

  useEffect(() => {
    if (!isTripsPage) {
      reset()
      if (placeInputRef.current) placeInputRef.current.value = ""
    }
    //eslint-disable-next-line
  }, [isTripsPage])

  const classes = ["hstack gap-2 flex-wrap"]
  if (className) classes.push(className)

  return (
    <form className={classes.join(" ")} onSubmit={submitHandler}>
      <InputGroup className="w-auto border border-dark rounded-pill flex-fill flex-nowrap" type="dropdown">
        <GooglePlacesInput
          placeholder="Search Location"
          className="border-dark rounded-pill rounded-end-0 border-top-0 border-bottom-0 border-start-0 flex-grow-0 w-100 ps-4 fs-6 fw-medium"
          defaultValue={form.address}
          onChange={placeChangedHandler}
          wrapperProps={{ className: "h-100 flex-fill" }}
          ref={placeInputRef}
        />
        { false &&
          <UncontrolledDropdown direction="down">
            <DropdownToggle
              className={[
                "px-3 py-2 h-100 fs-6 fw-medium rounded-0 border-top-0 border-bottom-0 border-gray-lightest bg-light-hover text-dark-hover",
                form.activity ? "text-dark" : "text-gray-light"
              ].join(" ")}
              caret
              outline
              color="dark"
            >
              {form.activity ? t(`guide.activity_types.${form.activity}`) : "Activity"}
            </DropdownToggle>
            <DropdownMenu>
              {ACTIVITIES.map((activity) => (
                <DropdownItem key={activity} name="activity" value={activity} onClick={changeHandler}>
                  {t(`guide.activity_types.${activity}`)}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        }
        <UncontrolledDropdown direction="down">
          <DropdownToggle
            className={[
              "px-3 py-2 h-100 fs-6 fw-medium rounded-start-0 border-top-0 border-bottom-0 border-end-0 border-gray-lightest bg-light-hover text-dark-hover",
              form.guest_count ? "text-dark" : "text-gray-light"
            ].join(" ")}
            caret
            outline
            color="dark"
          >
            {form.guest_count || "Guests"}
          </DropdownToggle>
          <DropdownMenu>
            {GUESTS.map((guests) => (
              <DropdownItem key={guests} name="guest_count" value={guests} onClick={changeHandler}>
                {guests}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </InputGroup>
      <Button color="primary" className="px-4 py-2 fs-6 fw-bold align-self-stretch flex-fill" disabled={!isChanged}>
        Search Trips
      </Button>
    </form>
  )
}
