import React from "react"
import { Spinner as BsSpinner } from "reactstrap"
import { useTranslation } from "react-i18next"

const sizesMap = {
  small: "1rem",
  default: "2rem",
  big: "7rem"
}
export default function Spinner({ color = "primary-second", size = "default", className, style, absolute = false, fixed = false }) {
  const { t } = useTranslation()
  const classes = ["hstack"]
  if (className) classes.push(className)
  if (fixed || absolute) classes.push("top-0 start-0 bottom-0 end-0")
  if (fixed) classes.push("position-fixed")
  else if (absolute) classes.push("position-absolute")
  else classes.push("position-relative")

  return (
    <div className={classes.join(" ")} style={style}>
      {size === "big" && <div className="text-center text-dark position-absolute top-50 start-50 translate-middle">{t("loading")}...</div>}
      <BsSpinner className={["m-auto", `text-${color}`].join(" ")} style={{ height: sizesMap[size], width: sizesMap[size] }}>
        {t("loading")}
      </BsSpinner>
    </div>
  )
}
