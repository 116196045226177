import React, { useState } from "react"
import { Button, Label } from "reactstrap"
import MessageEditor from "./MessageEditor"
import InputError from "components/form/InputError"
import Icon from "components/common/Icon"

import { useTranslation } from "react-i18next"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import { trimEditorState } from "helpers/editor"
import { serialize } from "object-to-formdata"

import { useDispatch } from "react-redux"
import { sendMessage } from "store/conversations"

export default function MessageBox({ conversation, messageSent }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [media, setMedia] = useState(null)
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())

  const handleSubmit = (e) => {
    e.preventDefault()
    const cleanState = trimEditorState(editorState)
    const content = convertToRaw(cleanState.getCurrentContent())
    const text = content.blocks.map((el) => el.text)

    const message = {}
    message.body = text.toString()
    if (media) message.media = media

    dispatch(sendMessage(conversation.id, serialize({ message }))).then(messageSent)
    setEditorState(EditorState.push(editorState, ContentState.createFromText("")))
  }

  return (
    <div className="bottom p-20 bg-light border-top border-gray-lightest">
      <Label className="fs-6 lh-1">Message</Label>
      <InputError field="body" wrapperClassName="mb-1" />
      <form onSubmit={handleSubmit} className="position-relative">
        <MessageEditor
          editorState={editorState}
          setEditorState={setEditorState}
          media={media}
          placeholder={t("messenger.placeholder")}
          setMedia={setMedia}
          className="py-15 ps-15 pe-80 w-100 border border-dark rounded"
        />
        <div className="position-absolute bottom-0 end-0 p-10">
          <Button color="primary-second" type="submit" className="ms-auto py-2 px-15">
            <Icon iconName="Send" className="rotate-45" />
          </Button>
        </div>
      </form>
    </div>
  )
}
