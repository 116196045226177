import { createAction, createReducer } from "@reduxjs/toolkit"
import { api } from "./helpers/api"

import { LOADING_INITIAL_STATE, setFailed, setReceived, setRequested } from "modules/loader-watchers/helpers/loading"

// ACTIONS
const requested = createAction("password/requested")
const requestedForgot = createAction("password/requestedForgot")
const resetted = createAction("password/resetted")
const failed = createAction("password/failed")

// REDUCER
const initialState = {
  ...LOADING_INITIAL_STATE,
  success: false,
  data: {}
}

const userInfoReducer = createReducer(initialState, {
  [requested.type]: (state, action) => {
    setRequested(state)
    state.success = false
  },
  [requestedForgot.type]: (state, action) => {
    setReceived(state)
    state.data = action.payload
  },
  [resetted.type]: (state, action) => {
    setReceived(state)
    state.data = action.payload
    state.success = true
  },
  [failed.type]: (state) => {
    setFailed(state)
  }
})

export default userInfoReducer

// Public actions
export const requestForgotPassword = (params) =>
  api({
    url: "/users/password/new",
    params: params,
    onStart: requested.type,
    onSuccess: requestedForgot.type,
    onError: failed.type
  })

export const resetPassword = (data) =>
  api({
    url: "/users/password",
    method: "post",
    data: data,
    onStart: requested.type,
    onSuccess: resetted.type,
    onError: failed.type
  })
