import React from "react"
import { Link } from "react-router-dom"
import { routes } from "router"
import { Button, Col, Row } from "reactstrap"
import Icon from "components/common/Icon"
import Template from "components/marketing/Template"
import Spinner from "components/common/Spinner"

import { useTitle } from "hooks/useTitle"
import { useTranslation } from "react-i18next"

// Redux
import { useSelector } from "react-redux"

export default function TemplatesParents() {
  useTitle("Templates Parents")
  const { t } = useTranslation()
  const headerHeight = useSelector((state) => state.window.headerHeight)
  const { loading, metadata } = useSelector((state) => state.templates)

  return (
    <>
      <div className="sticky-top mt-n30 mb-30" style={{ top: headerHeight }}>
        <div className="full-window-block align-items-center bg-white py-10 py-sm-20 shadow">
          <div className="hstack gap-20 justify-content-end">
            <Button tag={Link} to={routes.guideMarketingTemplatesPath()} color="ghost" className="p-2 my-n1">
              <Icon iconName="ArrowLeft" size={18} block />
            </Button>
            <h1 className="h3 fw-medium lh-1">{t("marketing.choose_template")}</h1>
          </div>
        </div>
      </div>
      <Row className="gap-y-30 gx-30">
        {metadata.parents.map((template, index) => (
          <Col xs={12} sm={6} md={4} lg={3} key={index}>
            <Link className="link link-dark vstack h-100" to={routes.guideMarketingTemplateNewPath({}, { parent_id: template.id })}>
              <Template template={template} />
            </Link>
          </Col>
        ))}
        {loading && <Spinner className="flex-fill my-20" />}
      </Row>
    </>
  )
}
