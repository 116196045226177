import React, { memo } from "react"
import * as Icons from "icons"

function Icon({ iconName, className, block = false, size, ...rest }) {
  const CurrentIcon = Icons[`${iconName}Icon`]
  const sizeProps = size ? { width: size, height: size } : {}

  const classes = ["icon"]
  if (block) classes.push("d-block")
  if (className) classes.push(className)

  return CurrentIcon ? <CurrentIcon {...rest} {...sizeProps} className={classes.join(" ")} /> : null
}

export default memo(Icon)

Icon.propTypes = {
  iconName: function (props, propName, componentName) {
    if (!(propName in props)) return new Error(`Missing prop '${propName}' in '${componentName}' component!`)

    const validPropValues = Object.keys(Icons).map((iconName) => iconName.replace("Icon", ""))
    if (!validPropValues.includes(props[propName]))
      return new Error(
        `Invalid prop ${propName}='${props[propName]}' in '${componentName}' component!
        \nProp value must be one of list: \n${validPropValues.join("\n")}\n`
      )
  }
}
