import React, { useRef } from "react"
import { Button, Col, Label, Row } from "reactstrap"
import Input from "components/form/Input"
import InputError from "components/form/InputError"
import ReCAPTCHA from "react-google-recaptcha"
import Spinner from "components/common/Spinner"

import useForm from "hooks/useForm"
import { useTranslation } from "react-i18next"

import { useSelector } from "react-redux"

export default function GuestForm({ onSubmit }) {
  const { t } = useTranslation()
  const captchaRef = useRef(null)
  const { loading } = useSelector((state) => state.bookings.booking)
  const [form, changeHandler, submitHandler, submitCallback] = useForm({
    first_name: "",
    last_name: "",
    email: ""
  })

  submitCallback(() => {
    const user = { ...form }
    const token = captchaRef.current.getValue()
    captchaRef.current.reset()
    user.captcha_token = token
    if (typeof onSubmit === "function") onSubmit(user)
  })

  return (
    <form onSubmit={submitHandler}>
      <Row className="gap-y-20 gx-10">
        <Col xs={12} sm={6} md={12} xl={6}>
          <Label for="first_name">{t("sign_up.first_name")}</Label>
          <Input
            id="first_name"
            type="text"
            name="first_name"
            errorField="client.first_name"
            placeholder={t("sign_up.first_name")}
            value={form.first_name}
            onChange={changeHandler}
            withError
          />
        </Col>
        <Col xs={12} sm={6} md={12} xl={6}>
          <Label for="last_name">{t("sign_up.last_name")}</Label>
          <Input
            id="last_name"
            type="text"
            name="last_name"
            errorField="client.last_name"
            placeholder={t("sign_up.last_name")}
            value={form.last_name}
            onChange={changeHandler}
            withError
          />
        </Col>
        <Col xs={12}>
          <Label for="email">{t("sign_up.email")}</Label>
          <Input
            id="email"
            type="email"
            name="email"
            errorField="client.email"
            placeholder={t("sign_up.email")}
            value={form.email}
            onChange={changeHandler}
            withError
          />
        </Col>
      </Row>
      <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} className="mt-30" />
      <InputError field="client.recaptcha" />
      <Button color="primary" type="submit" className="mt-30 w-100">
        {t("global.next")}
      </Button>
      {loading && <Spinner className="bg-white bg-opacity-0 backdrop-blur-3 w-100 h-100 z-2" fixed />}
    </form>
  )
}
