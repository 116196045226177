import { createAction, createReducer } from "@reduxjs/toolkit"
import { api } from "./helpers/api"

import { LOADING_INITIAL_STATE, setFailed, setReceived, setRequested } from "modules/loader-watchers/helpers/loading"

// ACTIONS
const loggedIn = createAction("auth/loggedIn")
export const loggedOut = createAction("auth/loggedOut")
const signedUp = createAction("auth/signedUp")

const assumedIn = createAction("auth/assumedIn")
const assumedOut = createAction("auth/assumedOut")

const requested = createAction("auth/requested")
const failed = createAction("auth/failed")

const accessToken = localStorage.getItem("assume_access_token") || localStorage.getItem("access_token")
const initialState = {
  ...LOADING_INITIAL_STATE,
  loggedIn: accessToken ? true : false
}

// REDUCER

const authReducer = createReducer(initialState, {
  [requested.type]: (state) => {
    setRequested(state)
  },
  [loggedIn.type]: (state, { payload }) => {
    setReceived(state)
    state.loggedIn = true
    localStorage.setItem("access_token", payload.access_token)
    localStorage.setItem("refresh_token", payload.refresh_token)
  },
  [loggedOut.type]: (state) => {
    setReceived(state)
    state.loggedIn = false
    localStorage.removeItem("access_token")
    localStorage.removeItem("refresh_token")
  },
  [signedUp.type]: (state) => {
    setReceived(state)
  },
  [assumedIn.type]: (state, { payload }) => {
    setReceived(state)
    state.loggedIn = true
    localStorage.setItem("assume_access_token", payload.access_token)
    localStorage.setItem("assume_refresh_token", payload.refresh_token)
    localStorage.setItem("assume_redirect_url", payload.redirect_url)

    window.location.replace(`${window.location.origin}/#/`)
  },
  [assumedOut.type]: (state) => {
    setReceived(state)
    state.loggedIn = localStorage.getItem("access_token") || false

    const assumeRedirectUrl = localStorage.getItem("assume_redirect_url")
    if (assumeRedirectUrl) window.location.replace(assumeRedirectUrl)
    else window.pathname = "/"

    localStorage.removeItem("assume_access_token")
    localStorage.removeItem("assume_refresh_token")
    localStorage.removeItem("assume_redirect_url")
  },
  [failed.type]: (state) => {
    setFailed(state)
  }
})

export default authReducer

// PUBLIC ACTIONS
export const signIn = (user) =>
  api({
    url: "/users/sign_in",
    method: "post",
    data: user,
    onStart: requested.type,
    onSuccess: loggedIn.type,
    onError: failed.type
  })

export const signOut = () => (dispatch) =>
  localStorage.getItem("assume_access_token")
    ? dispatch(assumedOut())
    : dispatch(
        api({
          url: "/users/sign_out",
          method: "delete",
          onStart: requested.type,
          onSuccess: loggedOut.type,
          onError: failed.type
        })
      )

export const signUp = (user) =>
  api({
    url: "/users",
    method: "post",
    data: user,
    onStart: requested.type,
    onSuccess: signedUp.type,
    onError: failed.type
  })

export const assume = (access_token, refresh_token, redirect_url) => assumedIn({ access_token, refresh_token, redirect_url })
