import React, { useEffect, useRef, useState } from "react"
import { Button, FormGroup, Label, TabContent, TabPane } from "reactstrap"
import Input from "components/form/Input"
import PhotoSelector from "components/form/PhotoSelector"
import TagsInput from "components/form/TagsInput"
import Icon from "components/common/Icon"
import Post from "./Post"
import TextGeneratorWindow from "components/common/TextGeneratorWindow"
import FirstLoading from "modules/loader-watchers/FirstLoading"

import useForm, { cleanNestedAttributes, useNestedFields } from "hooks/useForm"
import { findScrollParent } from "helpers/DOM"
import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"

import { useDispatch, useSelector } from "react-redux"
import { savePost } from "store/posts"
import { showModal } from "modules/modals/reducer"
import { metadataSelector, modelSelector } from "store/selectors"
import { errorsShownSelector } from "modules/errors/selectors"

const Form = ({ closeHandler, onChanged }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const tabsRef = useRef(null)
  const [activeSection, setActiveSection] = useState("form")
  const post = useSelector(modelSelector("post"))
  const metadata = useSelector(metadataSelector("posts"))
  const hasErrorFields = useSelector(errorsShownSelector)
  const { trip } = post
  const isPersisted = !!post.id
  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm({ ...post, trip_id: trip?.id || post.trip_id }, [
    "title",
    "content",
    "photos",
    "tags",
    "trip_id",
    "send_trip_photos",
    "facebook_share",
    "instagram_share",
    "twitter_share"
  ])
  const [, , addTagHandler, removeTagHandler, , updateTagHandler] = useNestedFields(form, "tags", ["title"], changeHandler)

  const openTextGenerator = () => dispatch(showModal("textGeneratorOffcanvas"))

  const changeTab = (tab) => () => {
    const scrollWrapper = findScrollParent(tabsRef.current)
    if (scrollWrapper) scrollWrapper.scrollTo({ left: 0, top: 0, behavior: "smooth" })
    setActiveSection(tab)
  }

  const textGeneratorHandler = ({ target }) => {
    const tags = target.value.match(/#(\w|_)+/gm, "") || []
    const value = target.value.replace(/#(\w|_)+/gm, "").trim()

    changeHandler({ target: { name: target.name, value } })
    tags.forEach((tag) => addTagHandler(null, { title: tag }))
  }

  submitCallback(() => {
    const properties_attributes = cleanNestedAttributes(form.photos, ["file", "file_type"])
    const tags_attributes = cleanNestedAttributes(form.tags, ["title"])
    const postAttributes = { ...form, properties_attributes, tags_attributes }

    delete postAttributes.photos
    delete postAttributes.tags

    const formData = serialize({ post: postAttributes }, { indices: true })
    dispatch(savePost(post.id, formData)).then(closeHandler)
  })

  useEffect(() => {
    if (typeof onChanged === "function") onChanged(isChanged)
  }, [isChanged, onChanged])

  useEffect(() => {
    if (hasErrorFields) changeTab("form")()
  }, [hasErrorFields])

  return (
    <FirstLoading name="posts.post" new>
      <form onSubmit={submitHandler} ref={tabsRef}>
        <TabContent activeTab={activeSection}>
          <TabPane tabId="form">
            <div className="vstack gap-20">
              <div className="hstack align-items-center bg-white rounded p-20 sticky-top flex-wrap">
                <h1 className="h3 fw-medium lh-1">{isPersisted ? t("posts.edit") : t("posts.add_new")}</h1>
                <div className="hstack gap-10 mt-3 mb-1 my-md-n1 ms-auto w-100 w-sm-auto">
                  <Button color="light" className="fs-7" onClick={closeHandler}>
                    {t("global.cancel")}
                  </Button>
                  <Button color="primary" className="fs-7" onClick={changeTab("preview")} disabled={!isChanged}>
                    {t("global.preview")}
                  </Button>
                </div>
              </div>

              <div className="vstack gap-30 bg-white rounded p-20">
                <div>
                  <Label for="title" className="fs-6">
                    {t("post.labels.title")}*
                  </Label>
                  <Input
                    id="title"
                    type="text"
                    name="title"
                    placeholder={t("post.labels.title")}
                    value={form.title || ""}
                    onChange={changeHandler}
                    className="fs-6"
                    withError
                  />
                </div>
                <div>
                  <div className="hstack gap-10 justify-content-between mb-2">
                    <Label for="content" className="fs-6 m-0">
                      {t("post.labels.content")}*
                    </Label>
                    <Button color="primary-second" className="px-15 py-1 fs-7 fw-medium flex-shrink-0" onClick={openTextGenerator}>
                      {t("text_generator.generate")}
                    </Button>
                  </div>
                  <Input
                    id="content"
                    type="textarea"
                    name="content"
                    rows={10}
                    placeholder={t("post.placeholders.content")}
                    value={form.content || ""}
                    onChange={changeHandler}
                    className="fs-6"
                    withError
                  />
                </div>
                <div>
                  <Label className="fs-6">{t("post.labels.hashtags")}</Label>
                  <TagsInput
                    name="tags"
                    type="textarea"
                    placeholder={t("post.placeholders.hashtags")}
                    tags={form.tags}
                    addTagHandler={addTagHandler}
                    removeTagHandler={removeTagHandler}
                    updateTagHandler={updateTagHandler}
                  />
                </div>
                <div>
                  <Label className="fs-6">{t("post.labels.photos", { max: 4 })}</Label>
                  <PhotoSelector form={form} changeHandler={changeHandler} max={4} small start label={false} />
                </div>
              </div>
              <div className=" vstack gap-30 bg-white rounded p-20">
                <div>
                  <Label for="trip_id" className="fs-6">
                    {t("post.labels.trip")}
                  </Label>
                  <Input
                    id="trip_id"
                    type="select"
                    name="trip_id"
                    placeholder={t("post.placeholders.trip")}
                    value={form.trip_id || ""}
                    onChange={changeHandler}
                    className={["fs-6", !form.trip_id && "text-gray-light"].join(" ")}
                    withError
                    disabled={!metadata.trips_collection?.length}
                  >
                    <option value="">{t("post.placeholders.trip")}</option>
                    {metadata.trips_collection?.map(({ id, title }) => (
                      <option key={id} value={id}>
                        {title}
                      </option>
                    ))}
                  </Input>
                </div>
                <div className="vstack gap-3" style={{ maxWidth: 250 }}>
                  <Label for="trip_id" className="fs-6">
                    {t("user.email")}
                  </Label>
                  <FormGroup switch className="hstack justify-content-between m-0 p-0">
                    <Label check for="send_trip_photos">
                      <div className="fs-5 fw-normal">{t(`post.labels.send_photos`)}</div>
                    </Label>
                    <Input
                      id="send_trip_photos"
                      name="send_trip_photos"
                      type="checkbox"
                      checked={form.send_trip_photos}
                      onChange={changeHandler}
                      className="bg-primary-checked border-primary-checked border-primary-focus focus-ring-primary"
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="bg-white rounded p-20">
                <div className="vstack gap-3" style={{ maxWidth: 250 }}>
                  <Label for="trip_id" className="fs-6">
                    {t("post.labels.social")}
                  </Label>
                  {["facebook", "instagram", "twitter"].map((social) => (
                    <FormGroup switch key={social} className="hstack justify-content-between m-0 p-0">
                      <Label check for={social}>
                        <div className="fs-5 fw-normal">{t(`global.social.${social}`)}</div>
                      </Label>
                      <Input
                        id={social}
                        name={`${social}_share`}
                        type="checkbox"
                        checked={form[`${social}_share`]}
                        onChange={changeHandler}
                        className="bg-primary-checked border-primary-checked border-primary-focus focus-ring-primary"
                      />
                    </FormGroup>
                  ))}
                </div>
              </div>
            </div>
          </TabPane>

          <TabPane tabId="preview">
            <div className="vstack gap-20">
              <div className="bg-white rounded p-20 sticky-top">
                <div className="hstack align-items-center">
                  <h1 className="h3 fw-medium lh-1">{isPersisted ? t("posts.edit") : t("posts.add_new")}</h1>
                  <div className="hstack gap-10 ms-auto my-n1">
                    <Button color="ghost" className="px-3" onClick={changeTab("form")}>
                      <Icon iconName="ArrowLeft" width={18} height={14} block />
                    </Button>
                    <Button color="primary" className="fs-7" disabled={!isChanged}>
                      {isPersisted ? t("global.save") : t("global.post")}
                    </Button>
                  </div>
                </div>
              </div>
              <Post post={form} edit />
            </div>
          </TabPane>
        </TabContent>
      </form>
      <TextGeneratorWindow name="content" containerId="marketing" onSubmit={textGeneratorHandler} />
    </FirstLoading>
  )
}

export default Form
