import React, { memo } from "react"
import { TabPane } from "reactstrap"
import DateTabs from "components/weather/DateTabs"
import Tide from "components/weather/widgets/Tide"

import moment from "moment"

import { useSelector } from "react-redux"
import { dateForecastObjSelector, dateHourlyTideForecastSelector, forecastDateSelector } from "store/weather"

import { TIDE_TYPES } from "constants/weather"

function Tides({ className, params, onChangeFilters }) {
  const forecastDate = useSelector(forecastDateSelector)
  const hourlyTideForecast = useSelector(dateHourlyTideForecastSelector())
  const currentForecastTide = useSelector(dateForecastObjSelector("tide"))
  const times = Object.keys(hourlyTideForecast || {}).sort(([time1], [time2]) => (time1 < time2 ? -1 : 1))
  const errors = currentForecastTide?.errors || []

  if (errors.length)
    return (
      <TabPane tabId="tides">
        <div className="alert alert-warning rounded px-40 py-30 mt-20">{errors.join(" ")}</div>
      </TabPane>
    )
  if (!times.length) return null

  const today = moment()
  const dateMoment = moment(forecastDate || today)
  const isToday = today.isSame(dateMoment, "day")
  const isTomorrow = today.clone().add(1, "day").isSame(dateMoment, "day")
  const dateFormatted = dateMoment.format(`${(isToday && "[Today], ") || (isTomorrow && "[Tomorrow], ") || ""}MMM DD, YYYY`)

  const nextTideTime = times?.find((time) => today.isSameOrBefore(moment(time, "HH:mm"), "minutes"))
  const nextTide = hourlyTideForecast?.[nextTideTime] || null

  const classes = ["bg-white rounded px-40 py-30 position-relative overflow-hidden"]
  if (className) classes.push(className)

  return (
    <TabPane tabId="tides" className={classes.join(" ")}>
      <div className="vstack gap-30">
        <DateTabs params={params} onChangeFilters={onChangeFilters} />
        <div className="mt-10">
          <p className="text-dark text-opacity-50 mb-1">{dateFormatted}</p>
          <p className="fs-3 m-0">
            {nextTide && `${TIDE_TYPES[nextTide.tideType]} tide in ${moment(nextTideTime, "HH:mm").diff(today, "hour")} hours`}
          </p>
        </div>
        <Tide className="g-col-2" onlyBody params={params} />
      </div>
    </TabPane>
  )
}

export default memo(Tides)
