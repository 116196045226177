import { createSelector } from "@reduxjs/toolkit"

export const errorsShownSelector = createSelector(
  (state) => state.errors,
  (errors) => !!Object.keys(errors.fields).length || !!errors.message
)

export const errorFieldSelector = (field) =>
  createSelector(
    (store) => store.errors,
    (errors) => errors.fields[field] || ""
  )
