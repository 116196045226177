import React, { useEffect, memo } from "react"
import { useSearchParams } from "react-router-dom"
import { CloseButton, Offcanvas } from "react-bootstrap"
import Conversation from "./Conversation"
import FirstLoading from "modules/loader-watchers/FirstLoading"
import Loading from "modules/loader-watchers/Loading"
import WithEmptyState from "components/common/WithEmptyState"

import { useTranslation } from "react-i18next"
import { sumBy } from "lodash"

import { useSelector, useDispatch } from "react-redux"
import { getConversations, setViewedMessagesCount,  } from "store/conversations"
import { collectionAnySelector, collectionFirstSelector, collectionSelector } from "store/selectors"
import { hideModal, resetModal } from "modules/modals/reducer"
import { modalSelector } from "modules/modals/selectors"
import { firstLoadingSelector } from "modules/loader-watchers/selectors"

function Conversations() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const headerHeight = useSelector((state) => state.window.headerHeight)
  const firstLoading = useSelector(firstLoadingSelector("conversations"))
  const conversationsFirst = useSelector(collectionFirstSelector("conversations"))
  const hasConversations = useSelector(collectionAnySelector("conversations"))
  const conversationsFirstId = conversationsFirst?.id
  const activeConversationId = +searchParams.get("conversation_id") || null
  const conversations = useSelector(collectionSelector("conversations"))
  const filteredConversations = conversations.filter(convresation => convresation.id === activeConversationId)
  // TODO: old ver. of totalUnviewed
  // const totalUnviewed = sumBy(conversations, "unviewed")
  const unviewed = sumBy(conversations, "unviewed")
  let totalUnviewed =  null

  if(unviewed > 0  && filteredConversations.lenght > 0) totalUnviewed = unviewed  - Number(filteredConversations.at(0).unviewed)

  const conversationsModal = useSelector(modalSelector("conversations"))
  const closeConversationsHandler = () => dispatch(hideModal("conversations"))
  const closedConversationsHandler = () => dispatch(resetModal("conversations"))

  useEffect(() => {
    if (activeConversationId && conversationsModal.isOpen) closeConversationsHandler()
  }, [activeConversationId]) //eslint-disable-line

  useEffect(() => {
    if (!activeConversationId && conversationsFirstId)
      setSearchParams((searchParams.set("conversation_id", conversationsFirstId), searchParams))
  }, [activeConversationId, conversationsFirstId]) //eslint-disable-line

  useEffect(() => { 
    if (firstLoading) dispatch(getConversations(null))
  }, [firstLoading]) //eslint-disable-line

  useEffect(() => {
    dispatch(setViewedMessagesCount(totalUnviewed))
  }, [totalUnviewed]) //eslint-disable-line

  return (
    <div className="vstack bg-white w-lg-100 flex-grow-0" style={{ maxWidth: 380 }}>
      <div className="hstack p-20 justify-content-between border-bottom border-gray-lightest d-none d-lg-flex">
        <div className="hstack">
          <h4 className="fw-bold text-dark lh-1">{t("messenger.all_messages")}</h4>
          {/* <Button color="ghost" size="icon" className="shadow">
            <Icon iconName="SidebarToggle" />
          </Button> */}
        </div>
        {totalUnviewed > 0 && (
          <div
            className="bg-white border border-dark rounded-pill text-dark text-center fs-5 lh-sm flex-shrink-0 px-1 my-n1"
            style={{ minWidth: 22 }}
          >
            {totalUnviewed}
          </div>
        )}
      </div>
      <Offcanvas
        placement="start"
        scroll
        show={conversationsModal.isOpen}
        onHide={closeConversationsHandler}
        onExited={closedConversationsHandler}
        container={document.getElementById("messages")}
        responsive="lg"
        className="h-100 min-h-0 w-100"
        backdropClassName="backdrop-blur-3"
        renderStaticNode
        style={{
          top: headerHeight,
          maxWidth: 380
        }}
      >
        <Offcanvas.Header>
          <h4 className="fw-bold text-dark lh-1">{t("messenger.conversations")}</h4>
          <CloseButton onClick={closeConversationsHandler} />
        </Offcanvas.Header>
        <Loading name="conversations.conversation" tag={Offcanvas.Body} className="vstack bg-light p-0 h-100 overflow-y-scroll">
          <FirstLoading name="conversations" className="mt-20">
            <WithEmptyState name="conversation" hasItems={hasConversations} tag={false}>
              {conversations.map((conversation) => (
                <Conversation conversation={conversation} key={conversation.id} />
              ))}
            </WithEmptyState>
          </FirstLoading>
        </Loading>
      </Offcanvas>
    </div>
  )
}

export default memo(Conversations)
