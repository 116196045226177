import React, { memo } from "react"
import { Button, Label } from "reactstrap"
import Input from "components/form/Input"
import Icon from "components/common/Icon"

import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import useForm from "hooks/useForm"

import { useDispatch, useSelector } from "react-redux"
import { createReview } from "store/reviews"

const ReviewForm = ({ className }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { review } = useSelector((store) => store.reviews)
  const { trip } = useSelector((store) => store.trips)
  const { id } = trip
  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm(review, ["body", "rating"])

  submitCallback(() => {
    const formData = serialize({ review: form })
    dispatch(createReview(id, formData))
  })

  const classes = ["vstack bg-white rounded py-15 px-10"]
  if (className) classes.push(className)

  return (
    <form onSubmit={submitHandler} className={classes.join(" ")}>
      <Input
        id="body"
        type="textarea"
        name="body"
        placeholder={t("review.placeholders.body")}
        value={form.body}
        onChange={changeHandler}
        className="fs-5 fw-normal border-0 p-0 shadow-none flex-fill"
        rows={7}
        withError
      />
      <hr className="my-10" />
      <div className="hstack gap-x-10 gap-y-20 flex-wrap">
        <div className="flex-fill">
          <Label className="fs-5 fw-normal mb-3">{t("review.labels.rating")}</Label>
          <div className="hstack gap-2">
            {Array(5)
              .fill(null)
              .map((_, index) => (
                <Label
                  key={index}
                  className={[
                    "mb-0 cursor-pointer",
                    +form.rating >= index + 1 ? "text-primary-second text-opacity-50-hover" : "text-gray-light text-primary-second-hover",
                    +form.rating === index + 1 ? "pointer-events-none" : ""
                  ].join(" ")}
                >
                  <Icon iconName="StarNew" size={28} block />
                  <Input
                    type="radio"
                    name="rating"
                    value={index + 1}
                    checked={+form.rating === index + 1}
                    onChange={changeHandler}
                    className="btn-check"
                  />
                </Label>
              ))}
          </div>
        </div>
        <Button color="primary" className="flex-fill mt-auto" disabled={!isChanged}>
          {t("global.submit")}
        </Button>
      </div>
    </form>
  )
}

export default memo(ReviewForm)
