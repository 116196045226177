import React, { memo } from "react"
import Icon from "components/common/Icon"

import { useTranslation } from "react-i18next"

import { useSelector } from "react-redux"
import { numberToCurrency } from "helpers/string"

function Widget({ stats_name, isCurrency = false }) {
  const { t } = useTranslation()
  const { metadata } = useSelector((store) => store.accounting)
  const { current_month, monthly_diff = "", last_month, yearly } = metadata[`${stats_name}_stats`] || {}
  const monthlyDiffIsPositive = parseFloat(monthly_diff.replace(/[^0-9.-]+/g, "")) > 0

  return stats_name ? (
    <div className="bg-white rounded">
      <div className="p-20">
        <h4 className="mb-3">{t(`accounting.labels.${stats_name}`)}</h4>
        <div className="grid grid-cols-2 gap-3">
          <div>
            <p className="fs-7 text-dark text-opacity-50 fw-normal mb-1">{t("accounting.labels.this_month")}</p>
            <p className="h3 fw-normal lh-1">{isCurrency ? numberToCurrency(current_month) : current_month || "—"}</p>
          </div>
          <div
            className={`hstack gap-1 align-items-center rounded-1 p-1 mt-auto me-auto mb-1 fs-7 lh-1 bg-opacity-10 ${
              monthlyDiffIsPositive ? "bg-primary text-primary" : "bg-danger text-danger"
            }`}
          >
            <Icon iconName={"ArrowDown"} className={monthlyDiffIsPositive ? "rotate-180" : ""} size={10} block />
            {monthly_diff || "—"}
          </div>
          <div>
            <p className="fs-7 text-dark text-opacity-50 fw-normal mb-1">{t("accounting.labels.last_month")}</p>
            <p className="h4 fw-normal lh-1">{isCurrency ? numberToCurrency(last_month) : last_month || "—"}</p>
          </div>
          <div>
            <p className="fs-7 text-dark text-opacity-50 fw-normal mb-1">{t("accounting.labels.this_year")}</p>
            <p className="h4 fw-normal lh-1"> {isCurrency ? numberToCurrency(yearly) : yearly || "—"}</p>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default memo(Widget)
